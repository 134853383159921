<template>
  <template v-if="hasContextMenu">
    <template v-for="b in content.blocks">
      <context 
        v-if="isComponent(b.type)" 
        :bookmarks="bookmarks" 
        :type="b.type" 
        :hfurl="hfurl" 
        :id="b.id" 
        :anchor="b.node_id" 
        :meta-id="id"
        :notes="notes"
        @update="$emit('update')"
        @addBlockNote="addBlockNote"
        @addNoteInSummary="addNoteInSummary"
        @openMenu="$emit('openMenu')"
      >
        <component 
          :is="`x_${b.type}`" 
          :block="b"
          class="dynamic-content-block"
        ></component>
      </context>
      
      <div class="alert alert-danger my-3" v-else>
        Content component {{ b.type }} is not yet implemented
      </div>
    </template>
  </template>

  <template v-else>
    <template v-for="b in content.blocks">
        <component 
          v-if="isComponent(b.type)"
          class="dynamic-content-block"
          :is="`x_${b.type}`" 
          :block="b"
        ></component>

        <div class="alert alert-danger my-3" v-else>
        Content component {{ b.type }} is not yet implemented
      </div>
    </template>
  </template>
</template>

<script>
  import { resolveDynamicComponent } from 'vue/dist/vue.esm-bundler'
  import x_paragraph from './content/paragraph.vue'
  import x_heading from './content/heading.vue'
  import x_table from './content/table.vue'
  import x_list from './content/list.vue'
  import x_media from './content/media.vue'
  import context from '../components/content_block_context.vue'
  import ContentBookmarks from '../javascripts/content_bookmarks'
 
  export default {
    data() {
      return {
        bookmarks: new Set()
      }
    },
    expose: ['setNotes'],
    emits: ['update', 'addBlockNote', 'addNoteInSummary','openMenu'],
    props: {
      content: {
        type: Object,
        required: true
      },
      hfurl: {
        type: String,
      },
      id: {
        type: String,
      },
      notes: {
        type: Array,
      },
      hasContextMenu: {
        type: Boolean,
        default: true
      },
      scrollElId: {
        type: Object,
      },
    },
    components: {
      x_paragraph,
      x_heading,
      x_table,
      x_list,
      x_media, 
      context,
    },
    methods: {
      isComponent: (name) => typeof resolveDynamicComponent(`x_${name}`) !== 'string',
      setNotes(notes) {
        this.notes = notes
      },
      addBlockNote(id) {
        this.$emit('addBlockNote', id)
      },
      addNoteInSummary(id) {
        this.$emit('addNoteInSummary', id)
      },
      deleteNote(note_or_id) {
        const i = this.notes.findIndex((note) => note.id === note_or_id || note.id === note_or_id.id)
        if (i >= 0) {
          const deleted = this.notes.splice(i, 1)
        } else {
          console.warn("Unable to delete note " + (note_or_id.id ?? note_or_id))
        }
      }
    },
    mounted() {
      if (this.hasContextMenu) {
        ContentBookmarks
          .getBookmarks(this.id)
          .then((resp) => {
            this.bookmarks = new Set(resp.data)
          })
      }

      if (this.scrollElId?.id) {
        const items = Array.from(document.getElementsByClassName('me-content'))
        let scrollEl = null
        if(this.scrollElId.type === 'note') {
          let currentNote = this.notes.find(n => n.id === this.scrollElId.id)
          let node_id = currentNote.anchors[0].block_id
          scrollEl = items.find(n => n.id === node_id)
        } else {
          scrollEl = items.find(n => n.id === this.scrollElId.id)
        }
        scrollEl.scrollIntoView(
          {behavior: "smooth", block: "start"}
        )
      }
    }
  }

</script>
