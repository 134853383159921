<template>
  <div class="mb-2">
    <GradeBadge :grade="block.data.grade" v-if="block.data.grade"/>

    <div 
      :id="block.node_id" 
      :data-hash="block.hash" 
      :data-id="orderId"
      class="me-content"
    >
      <div class="dynamic-content-list-explanation" v-if="block.data.explanation && block.data.explanation !== ''">
        {{block.data.explanation}}
      </div>
      <component :is="listTag">
        <li v-for="i in block.data.items" :id="i.node_id">
          <GradeBadge :grade="i.grade" v-if="i.grade" />
          <template v-html="i.content ?? i" v-inline-html></template>
        </li>
      </component>
    </div>
  </div>
</template>

<script>
import GradeBadge from './grade_badge.vue'
import ContentNotes from '../../javascripts/content_notes'

export default {
  components: {
    GradeBadge
  },
  props: {
    block: {
      type: Object,
      required: true,
    }
  },
  computed: {
    listTag() {
      return this.block.data.style[0] + 'l'
    },
    orderId() {
      return this.block.node_id ? ContentNotes.orderId(this.block.node_id) : ''
    },
  }
}
</script>