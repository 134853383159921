import './index.css';
import { h, render } from 'vue/dist/vue.esm-bundler'
import { icon } from '@fortawesome/fontawesome-svg-core'
import Actions from './actions.vue'
import SlimSelect from 'slim-select';
import 'slim-select/styles'

/**
 * Tooltip for the Editor.js.
 * Add a tooltip inline in the Toolbar.
 * Requires no server-side uploader.
 *
 * @typedef {object} TooltipData
 * @description Tool's input and output data format
 * @property {string} tooltip — tooltip text
 */

export default class Reference {
  static get isInline() {
    return true;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    const { button } = this;
    const { inlineToolButtonActive } = this.api.styles;
    button.classList.toggle(inlineToolButtonActive, state);
  }

  /**
   * @param {object} api Editor.js api
   */
  constructor({ api, config = {} }) {
    this.api = api;
    this.button = null;
    this._state = false;
    this.spanReference = null;

    const { location = 'bottom' } = config;
    this.tooltipLocation = location;
    this.holder = config.holder

    this.tag = 'SPAN';

    this.modalElement = config.modal_element
    this._settingsModal = null

    this.inProgress = false

    this.nodes = {}
    this.toolbarIcon = icon(
      { prefix: 'fas', iconName: 'arrow-right-to-bracket' }, 
      {
        transform: {size: 10}, 
        //styles: {'color': this.evLevelColors[l] || 'black'}
      }
    ).html[0]

    this.CSS = {
      reference: 'me-reference',
    };
    this.observe();
  }

  observe() {
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === 'childList'
        && mutation.target.classList.contains('codex-editor__redactor')) {
          const spans = document.querySelectorAll(`.${this.CSS.reference}`)
          spans.forEach((span) => this.markupReference(span, span.dataset))
        }
      });
    });

    observer.observe(this.holder, { childList: true, subtree: true });
  }

  /**
   * render the button in the inline toolbar
   * @returns the button element created to the inline toolbar
   */

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML = this.toolbarIcon
    const { inlineToolButton } = this.api.styles;
    this.button.classList.add(inlineToolButton);

    this._settingsModal = h(Actions, {
      tool: this,
    })
    render(this._settingsModal, this.modalElement)

    return this.button;
  }
  /**
   * The method is called when the button rendered in render() is clicked
   * create a span to enclose the selected text.
   * @param {object} range is an object with info about the selected text
   * @returns
   */

  surround(range) {
    if (this.state) {
      this.showActions(range, this.state)
    } else {
      this.showActions(range)
    }
  }

  clear() {
  }

  markupReference(el, data) {
    el.dataset.meEntityClass = data.meEntityClass ?? data.entityClass
    el.dataset.meEntityRecordId = data.meEntityRecordId ?? data.entityRecordId
    el.dataset.meEntityRecordTooltip = data.meEntityRecordTooltip ?? data.entityRecordTooltip ?? el.dataset.meEntityRecordId
    el.classList.remove(...el.classList)
    el.classList.add(this.CSS.reference)
    el.classList.add(`${this.CSS.reference}-${el.dataset.meEntityClass}`)

    const { tooltipLocation } = this;
    this.api.tooltip.onHover(el, el.dataset.meEntityRecordTooltip, { placement: tooltipLocation });
  }

  /**
   * wrap creates the span element for the selected text
   * @param {object} range is an object with info about the selected text
   */

  wrap(range, data) {
    if (!this.spanReference) {
      const selectedText = range.extractContents();
      this.spanReference = document.createElement(this.tag);
      this.spanReference.appendChild(selectedText);
      range.insertNode(this.spanReference);
      this.api.selection.expandToTag(this.spanReference);
    }
    this.markupReference(this.spanReference, data)

    this.closeToolbar()
  }

  /**
   * unwrap delete the span if the tool is disabled
   * @param {object} range is an object with info about the selected text
   */
  unwrap(range) {
    const text = range.extractContents();

    this.spanReference.remove();
    this.spanReference = null

    range.insertNode(text);

    this.closeToolbar()
  }

  /**
   * Checkstate is called when the user select any text
   * check the state of the tool in the selected text
   */
  checkState() {
    this.spanReference = this.api.selection.findParentTag(this.tag, this.CSS.reference);
    if (this.spanReference) {
      this.state = {
        entityClass: this.spanReference.dataset.meEntityClass,
        entityRecordId: this.spanReference.dataset.meEntityRecordId
      }
    } else {
      this.state = null
    }
  }

  /**
   * Show the input and create the reference when the user presses Enter
   */
  showActions(range) {
    this._settingsModal.component.ctx.show(range, this.state)
  }

  /**
   * Hide the input if the user do not have tooltip in the selected text
   */
  hideActions() {
    this._settingsModal?.component.ctx.hide()
  }

  /**
   * close the toolbar when the user presses Enter
   */
  closeToolbar() {
    const toolbar = document.querySelector('.ce-inline-toolbar--showed');
    if (toolbar) {
      toolbar.classList.remove('ce-inline-toolbar--showed');
    }
  }

  /**
   * satanize the data output
  */
  static get sanitize() {
    return {
      span: { 
        'class': true,
        'data-me-entity-class': true, 
        'data-me-entity-record-id': true,
        'data-me-entity-record-tooltip': true,
      },
    };
  }
}