<template>
 <div class="attachment__container">
        <div v-if="note.note_html && !isForSummary" v-html="note.note_html" class="attachment__text"></div>
        <p v-if="isForSummary && note.attachments[0]" class="attachment__title">Файлы заметки:</p>
        <div v-if="note.attachments.length >= 1" class="attachment__icons">
            <div v-for="attach in note.attachments">
                <div class="attachment__item" v-if="type(attach.content_type) === 'application'">
                    <a class="attachment__item" :href="link(attach.signed_id, attach.filename)">
                        <img :src="imageUrl('doc.svg')"/>
                    </a>
                </div>
                        
                <div v-if="type(attach.content_type) === 'image'" class="attachment__item">
                    <img class="attachment__image" :src="link(attach.signed_id, attach.filename)" @click="openPopup(link(attach.signed_id, attach.filename), 'image')"/> 
                </div>

                <div v-if="type(attach.content_type) === 'audio'" class="attachment__item">
                    <div @click="openPopup(link(attach.signed_id, attach.filename), 'audio')">
                        <img :src="imageUrl('audio.svg')"/>
                    </div>
                
                </div>

                <div v-if="type(attach.content_type) === 'video'" class="attachment__item">
                    <div @click="openPopup(link(attach.signed_id, attach.filename), 'video')">
                        <img :src="imageUrl('video.svg')"/>
                    </div>
                </div>

                <p v-if="isForSummary" class="attachment__file-name">{{ attach.filename }}</p>
            </div>
        </div>
        
        <div v-if="!isForSummary && (note.note_html || note.attachments.length)" class="attachment__btns">
            <button class="attachment__btn" @click="requestNoteEdit">Редактировать заметку</button>
            <button class="attachment__btn" @click="openDeleteNotePopup()">Удалить заметку</button>
        </div>

        <div v-else class="attachment__btns">
            <button class="attachment__btn" @click="requestNoteEdit">Добавить заметку</button>
            <button class="attachment__btn" @click="openDeleteNotePopup()">Удалить из конспекта</button>
        </div> 

    </div>

    <Teleport to="body">
        <div v-if="isPopupOpen"class="attachment__popup">
            <div class="attachment__popup-content">
                <button type="button" class="info-popup__btn" @click="closePopup()">
                    <img :src="imageUrl('close.svg')"/>
                </button>

                <img v-if="typePopupContent === 'image'" class="attachment__popup-image" :src="currentSrc"/>

                <audio controls v-if="typePopupContent === 'audio'" class="attachment__audio" :src="currentSrc"></audio>

                <video controls v-if="typePopupContent === 'video'" class="attachment__video" :src="currentSrc"></video>

                <div v-if="isDeletingNote" class="attachment__delete-block">
                    <p>Подтвердить удаление заметки</p>
                    <div class="attachment__delete-btns">
                        <button class="attachment__delete-btn attachment__btn-yes" @click="deleteNote(note)">Да</button>
                        <button class="attachment__delete-btn attachment__btn-no" @click="closePopup()">Нет</button>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import { rails_service_blob_path } from '../../javascript/routes'
import ContentNotes from '../javascripts/content_notes'
import { getImageUrl } from '../javascripts/utils'

export default {
    data() {
        return {
            fileName: '',
            baseUrl: window.location.origin,
            isPopupOpen: false,
            currentSrc: '',
            currentAudio: '',
            currentVideo: '',
            typePopupContent: '',
            isDeletingNote: false
        }
    },
    props: {
        note: {
            type: Object,
            required: true
        },
        notes: {
            type: Array,
            required: true
        },
        isForSummary: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        popup() {
            const popup = document.querySelector('.popup-notes')
            return popup
        },
        color() {
            return this.isForSummary ? '#000' : '#fff'
        }
    },
    methods: {
        type(type) {
            const typeAttach = type.split('/')[0]
            return typeAttach
        },
        link(id, name) {
            return window.location.origin + rails_service_blob_path(id, name )
        },
        openPopup(link, type) {
            this.typePopupContent = type
            this.isPopupOpen = true
            this.currentSrc = link
        },
        closePopup() {
            this.isPopupOpen = false
            this.isDeletingNote = false
        },
        handleClickOutside(event) {
            if(event.target.classList[0] === 'attachment__popup') {
                this.isPopupOpen = false
                this.isDeletingNote = false
            }
            if(event.target.classList.contains('popup-notes')) {
                this.popup.style.display = 'none'
            }
        },
        requestNoteEdit() {
            this.eventBus.emit('edit-content-note', this.note.id)
        },
        closePopupNotes() {
            this.popup.style.display = 'none'
        },
        openDeleteNotePopup() {
            this.isPopupOpen = true
            this.isDeletingNote = true
        },
        deleteNote() {
            ContentNotes.deleteNote(this.note)
                .then(() => {
                    this.eventBus.emit("toast-show", {
                    severity: "success",
                    message: "Удалено из конспекта",
                    })
                    ContentNotes.unmarkNote(this.note, this.notes)
                    this.eventBus.emit('remove-content-note', this.note.id)
                    this.isPopupOpen = false
                    this.isDeletingNote = false
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        imageUrl(path) {
            return getImageUrl(path)
        }
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside)
        const popupCloseButton = document.querySelector('.popup__close-btn')
        popupCloseButton.addEventListener('click', this.closePopupNotes)
    }
}
</script>

<style lang="scss">
@import "../stylesheets/application.scss";

.attachment {
    &__title {
        margin-top: 20px;
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__image {
        width: 80px;
        cursor: pointer;
    }

    &__icons{
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 10px 20px;
        margin: 5px;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__text {
        margin: 5px;
    }

    &__file-name {
        margin-top: 10px;
    }

    &__popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(205, 214, 219, 0.8);
        z-index: 10;
    }

    &__popup-content {
        position: fixed;
        top: 30%;
        left: 50%;
        background: #fff;
        z-index: 2;
        padding: 40px;
        transform: translate(-50%, -25%);
    }

    &__popup-image {
        width: 100%;
    }

    &__btn {
        border: none;
        background-color: transparent;
        color: $secondary-color-1;
        width: 190px;
        height: 25px;
        font-size: 14px;
        font-weight: 700;

        &:hover {
            font-size: 13px;
        }
    }

    &__btns {
        display: flex;
    }

    &__delete-btns {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__delete-btn {
        border: none;
        border-radius: 8px;
        margin: 10px;
        width: 100px;
        height: 40px;
        background: $primary-light-color-5;
        color: #fff;

        &:hover {
            background: linear-gradient(270deg, $primary-light-color-5, $primary-light-color-3 104.62%); 
        }
    }

    &__btn-no {
        background: #fff;
        color: $secondary-color-1;
        border: 1px solid $primary-light-color-1;

        &:hover {
            color: $primary-light-color-3; 
            background: $primary-light-color-1; 
        }
    }

    &__delete-block{
        margin-top: 20px;
    }
}

.open {
    display: flex;
}

</style>