<template>
  <div class="progress" v-show="uploadProgress !== null">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated" 
      :class="{'bg-danger': error}"
      role="progressbar" 
      :style="`width: ${error ? 100 : uploadProgress}%;`"
    >{{ error ? `Ошибка: ${error}` : null }}</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        error: null,
        uploadProgress: null,
      }
    },
    methods: {
      setProgress(val) {
        this.uploadProgress = value
      },
      setError(error) {
        this.error = error
      },
      hide() {
        this.uploadProgress = null
      }
    }
  }
</script>