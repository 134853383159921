<template>
  <b-row class="justify-content-center">
    <b-form @submit="onSubmit" @reset="onReset">
      <b-input-group>
        <b-form-input v-model="search.query" placeholder="Введите поисковый запрос"></b-form-input>
        <b-button variant="primary" @click="onSubmit" :disabled="!validSearchParams">
          <fa-icon icon="fas fa-search"></fa-icon>
        </b-button>
      </b-input-group>
      <b-input-group class="mt-2">
        <b-row>
          <b-col><b-form-checkbox v-model="search.areas.denotations">В основных терминах</b-form-checkbox></b-col>
          <b-col><b-form-checkbox v-model="search.areas.denotation_abbreviations">В аббревиатурах основных терминов</b-form-checkbox></b-col>
          <b-col><b-form-checkbox v-model="search.areas.synonyms">В синонимах</b-form-checkbox></b-col>
          <b-col><b-form-checkbox v-model="search.areas.synonym_abbreviations">В аббревиатурах синонимов</b-form-checkbox></b-col>
        </b-row>
      </b-input-group>
      <b-input-group class="mt-2">
        <b-row>
          <b-col><b-form-checkbox v-model="search.settings.fuzzy">Нечеткий поиск</b-form-checkbox></b-col>
          <b-col><b-form-checkbox v-model="search.settings.entire_string">Искать строку целиком</b-form-checkbox></b-col>
        </b-row>
      </b-input-group>
    </b-form>
  </b-row>
  <b-row class="mt-3">
    <b-col>
      <div class="spinner-border" role="status" v-if="searching">
        <span class="visually-hidden">Loading...</span>
      </div>
    </b-col>
  </b-row>
  <b-row v-if="terms">
    <b-col>
      <template v-if="terms.length > 0">
        <slot :terms="terms">
          <Term v-for="el in terms" :term="el.term" :key="el.term" class="mt-3"/>
        </slot>
      </template>
      <b-alert variant="warning" :model-value="true" class="mt-3" v-else>
        Ничего не найдено
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
  import axios from 'axios'
  import { search_terms_path } from '../../../javascript/routes'
  import Term from './term.vue'
  
  export default {
    components: {
      Term
    },
    data() {
      const defaultSearch = {
        query: '',
        settings: {
          fuzzy: false,
          entire_string: false
        },
        areas: {
          denotations: true,
          denotation_abbreviations: true,
          synonyms: true,
          synonym_abbreviations: true
        }
      }
      return {
        defaultSearch,
        search: this.newSearchParams(defaultSearch),
        terms: null,
        searching: false
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        event.stopPropagation()
        
        if (!this.validSearchParams) {
          return false
        }
        
        this.terms = null
        this.searching = true
        const csrfToken = document.getElementsByName("csrf-token")[0].content
        
        axios.post(
          search_terms_path({ format: 'json' }), 
          {
            search: this.search
          }, 
          { 
            responseType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken,
              "Content-Type": "application/json",
              "Accept": "application/json"
            }
          }).then(resp => {
            this.terms = resp.data
          }).catch(error => {
            if (error.response.status == 429) {
              this.state.error = "Too many requests"
            } else {
              this.state.error = "Не удалось выполнить запрос: " + error.response.status
            }
          }).then(() => {
            this.searching = false
          })
      },
      newSearchParams(p = this.defaultSearch) {
        return JSON.parse(JSON.stringify(p))
      },
      onReset() {
        this.search = this.newSearchParams()
      }
    },
    computed: {
      validSearchParams() {
        return this.search.query && this.search.query !== '' && (
          this.search.areas.denotations || 
          this.search.areas.denotation_abbreviations ||
          this.search.areas.synonyms ||
          this.search.areas.synonym_abbreviations 
        )
      }
    }
  }
</script>