<template>
  <div class="row" v-if="error">
    <div class="col">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
  </div>

  <div class="row mb-3" v-if="allowSearch">
    <div class="col">
      <form @submit.prevent="doSearch" novalidate ref="search_form">
        <div class="pages__searchWrap">
          <button
            type="submit"
            class="pages__searchIcon"
            :disabled="searching"
          >
          </button>
          <input type="text" class="form-control pages__searchInput" v-model="search.criterias[0].query" placeholder="Поисковый запрос" :autofocus="true"/>
        </div>
      </form>
    </div>
  </div>

  <div v-if="loading || searching" class="row justify-content-center mb-2"> 
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div class="row">
    <div class="col">      
      <OntologyEntry 
        v-for="e in rootEntries" 
        :entry="e" 
        :ontology-id="id" 
        :key="e" 
        @openPopupChildren="openPopupChildren"
      >
        <template #entry="{ entry }">
          {{ entry.code ?? entry.code_range }}
        </template>
      </OntologyEntry>
    </div>
  </div>
</template>

<script>
  import { ontology_entry_path, ontology_entries_path } from '../../../javascript/routes'
  import axios from 'axios'
  import OntologyEntry from './entry.vue'
  import Popup from '../../components/popup.vue'
  import { getImageUrl } from '../../javascripts/utils'
  
  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      initialData: {
        type: Array
      },
      allowSearch: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        rootEntries: this.initialData ? JSON.parse(JSON.stringify(this.initialData)) : null,
        loading: false,
        searching: false,
        search: {
          criterias: [
            {query: '', match_logic: {case_sensitive: false, phrase: false}},
          ],
          origin: {
            type: [this.id]
          },
          perform: {
            fts: true,
            nlp: false
          }
        },
        error: null,
        isPopupOpen: false,
        currentEntry: {}
      }
    },
    components: {
      OntologyEntry,
      Popup
    },
    methods: {
      fetchSubtree(code = null) {
        this.loading = true
        const path = code ? 
          ontology_entry_path(this.id, code, {format: 'json' }) :
          ontology_entries_path(this.id, { format: 'json' })
        axios
          .get(path)
          .then((resp) => {
            this.rootEntries = resp.data.entry.children
          }).catch((error) => {
            console.error(error)
          }).finally(() => {
            this.loading = false
          })
      },
      fetchRoot() {
        this.fetchSubtree()
      },
      doSearch(event) {
        if (event) {
          event.target.classList.remove('was-validated')
          if (! event.target.checkValidity()) {
            event.target.classList.add('was-validated')
            event.stopPropagation()
            return
          }
        }

        if (this.search.criterias[0].query === '') {
          this.fetchRoot()
          return
        }

        this.searching = true
        this.error = null
        const csrfToken = document.getElementsByName("csrf-token")[0].content
        
        axios.post('/searches', {
          search: this.search
        }, { 
          responseType: 'json',
          headers: {
            "X-CSRF-Token": csrfToken
          }
        }).then(resp => {
          this.rootEntries = resp.data.fts[this.id]
        }).catch(error => {
          if (error.response.status === 429) {
            this.error = "Too many requests"
          } else if (error.response.status === 404) {
            this.error = "Ничего не найдено"
          } else {
            this.error = "Не удалось выполнить запрос: " + error.response.status
          }
        }).then(() => {
          this.searching = false
        })
      },
      openPopup(entry) {
        this.isPopupOpen = true
        this.currentEntry = entry
      },
      openPopupChildren(entry) {
        this.isPopupOpen = true
        this.currentEntry = entry
      },
      closePopup() {
        this.isPopupOpen = false
      },
      imageUrl(path) {
        return getImageUrl(path)
      }
    },
    mounted() {
      if (!this.rootEntries) {
        this.fetchSubtree()
      }

      document.addEventListener("click", this.handleClickOutside)
    }
  }
</script>

<style lang="scss">
.ontologies {
  .pages {
    &__searchIcon {
      left: -10px;
    }
  }
}
</style>

