<template>
  <div class="container-fluid g-0" ref="root">
    <a class="text-tools-reference" ref="tools_reference"></a>
    <div class="text-tools drop-shadow" ref="tools">
      <ul class="nav nav-wrap">
        <li v-show ="selection?.notable && selection?.intersectedNotes?.size === 1" class="nav-item">
            <a class="nav-link" :class="{disabled: !selection?.notable}" @click.stop @click="$event.preventDefault(); requestNoteEdit(selection.intersectedNotes)" data-tippy-content="Редактировать заметку" data-tippy-placement="bottom">
              <!-- <fa-icon :icon="['fas', 'pen-to-square']" size="2xl" :color="selection?.notable ? '#A8234C' : '#999'" /> -->
               <img v-if="selection?.notable" class="nav-link__image" :src="imageUrl('pencil.svg')"/>
               <img v-else class="nav-link__image" :src="imageUrl('pencil-disabled.svg')"/>
            </a>
        </li>

        <li v-show ="selection?.notable && selection?.intersectedNotes?.size === 0 && selection?.overlyingNotes?.size === 0" class="nav-item">
            <a class="nav-link" :class="{disabled: !selection?.notable}" @click.stop @click="$event.preventDefault(); openPopup('notes')" data-tippy-content="Сделать заметку к выделенному тексту" data-tippy-placement="bottom">
              <!-- <fa-icon :icon="['fas', 'pen-to-square']" size="2xl" :color="selection?.notable ? '#A8234C' : '#999'" /> -->
              <img v-if="selection?.notable" class="nav-link__image" :src="imageUrl('pencil.svg')"/>
              <img v-else class="nav-link__image" :src="imageUrl('pencil-disabled.svg')"/>
            </a>
        </li>

        <li v-show ="selection?.notable && selection?.overlyingNotes?.size > 0 " class="nav-item">
            <div v-show = "amountAnchor === 1">
              <a class="nav-link" :class="{disabled: !selection?.notable}" @click.stop @click="$event.preventDefault(); openPopup('notes')" data-tippy-content="Сделать заметку внутри заметки" data-tippy-placement="bottom">
                <!-- <fa-icon :icon="['fas', 'pen-to-square']" size="2xl" :color="selection?.notable ? '#A8234C' : '#999'" /> -->
                <img v-if="selection?.notable" class="nav-link__image" :src="imageUrl('pencil.svg')"/>
                <img v-else class="nav-link__image" :src="imageUrl('pencil-disabled.svg')"/>
              </a>
            </div>

            <div v-show = "amountAnchor === 2">
              <a class="nav-link" :class="{disabled: !selection?.notable}" @click.stop @click="$event.preventDefault(); requestNoteEdit(selection.intersectedNotes)" data-tippy-content="Редактировать заметку" data-tippy-placement="bottom">
                <!-- <fa-icon :icon="['fas', 'pen-to-square']" size="2xl" :color="selection?.notable ? '#A8234C' : '#999'" /> -->
                <img v-if="selection?.notable" class="nav-link__image" :src="imageUrl('pencil.svg')"/>
                <img v-else class="nav-link__image" :src="imageUrl('pencil-disabled.svg')"/>
              </a>
            </div>
        </li>

        <li class="nav-item" v-show="selection?.notable && selection?.intersectedNotes?.size === 0 && selection?.overlyingNotes?.size === 0" >
          <a 
            :class="{disabled: !selection?.notable || selection?.intersectedNotes?.size === 1}"
            class="nav-link" 
            @click="$event.preventDefault(); addNoteInSummaryTools()" 
            data-tippy-content="Добавить в конспект" 
            data-tippy-placement="bottom"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="32px" height="32px" fill="#A8234C">
              <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM72 272a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm104-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zM72 368a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm88 0c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16z"/>
            </svg> -->
            <img class="nav-link__image" :src="imageUrl('summary.svg')"/>
          </a>
        </li>
        

        <li class="nav-item" v-show ="selection?.notable && selection?.intersectedNotes?.size === 1 && selection?.overlyingNotes?.size === 0">
          <a 
            :class="{disabled: !selection?.notable}"
            class="nav-link" 
            data-tippy-content="Добавить в конспект" 
            data-tippy-placement="bottom"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="32px" height="32px" fill="#8d8d8d">
              <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM72 272a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm104-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zM72 368a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm88 0c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16z"/>
            </svg> -->
            <img class="nav-link__image" :src="imageUrl('summary-disabled.svg')"/>
          </a>
        </li>

        <li class="nav-item" v-show ="selection?.notable && selection?.overlyingNotes?.size === 1 && amountAnchor === 1">
          <a
            class="nav-link"
            :class="{disabled: !selection?.notable}"
            @click="$event.preventDefault(); addNoteInSummaryTools()" 
            data-tippy-content="Добавить в конспект" 
            data-tippy-placement="bottom"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="32px" height="32px" fill="#A8234C">
              <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM72 272a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm104-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zM72 368a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm88 0c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16z"/>
            </svg> -->
            <img class="nav-link__image" :src="imageUrl('summary.svg')"/>
          </a>
        </li>

        <li class="nav-item" v-show ="selection?.notable && selection?.overlyingNotes?.size === 1 && amountAnchor === 2">
          <a
            class="nav-link"
            :class="{disabled: !selection?.notable && amountAnchor === 2}"
            data-tippy-content="Добавить в конспект" 
            data-tippy-placement="bottom"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="32px" height="32px" fill="#8d8d8d">
              <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM72 272a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm104-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zM72 368a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm88 0c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16z"/>
            </svg> -->
            <img class="nav-link__image" :src="imageUrl('summary-disabled.svg')"/>
          </a>
        </li>

        
        <!--li class="nav-item">
          <a class="nav-link" @click="$event.preventDefault(); proceedWithNote()" data-tippy-content="Предложить исправление" data-tippy-placement="bottom">
            <fa-icon :icon="['fas', 'triangle-exclamation']" size="2xl" color="#A8234C" />
          </a>
        </li-->
      </ul>
    </div>
    <div class="content-viewer__nav-wrap" :class="{wideWrap: !isNarrowMenu}">   
      <div v-if="isNarrowMenu" class="d-flex flex-column content-viewer__nav">
        <button class="content-viewer__nav-toggle-btn" @click="toggleMenu()">
          <img :src="imageUrl('arrow-left.svg')"/>
        </button>
        <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
          <li class="content-viewer__nav-item">
            <button role="button" class="nav-link" data-tippy-content="Информация" @click.stop @click="openPopup('info')">
              <img :src="imageUrl('info.svg')" />
            </button>
          </li>
          <li class="content-viewer__nav-item">
            <button role="button" class="nav-link" data-tippy-content="Оглавление" @click.stop @click="openTableOfContents">
                <img :src="imageUrl('contents.svg')"/>
            </button>
          </li>
          <li>
            <a href="#" class="nav-link py-3 border-bottom rounded-0" data-tippy-content="Галерея изображений" @click="showGallery">
              <img v-if="!hasImages()" :src="imageUrl('gallery.svg')" />
              <img v-if="hasImages()" :src="imageUrl('gallery-active.svg')" />
            </a>
          </li>
          <li class="content-viewer__nav-item">
            <button role="button" class="nav-link" data-tippy-content="Таблицы" @click.stop @click="openPopup('tables')">
                <img :src="imageUrl('table.svg')" />
            </button>
          </li>
          <li class="content-viewer__nav-item content-viewer__nav-search" :class="{search: search_matches && search_matches.length > 0}">
            <p data-tippy-content="Инструменты поиска">
              <img v-if="!search_matches" :src="imageUrl('magnifying-glass.svg')" class="content-viewer__nav-icon-search"/>
              <img v-if="search_matches" :src="imageUrl('magnifying-glass-active.svg')" class="content-viewer__nav-icon-search"/>
            </p>
            <div class="content-viewer__nav-search-wrap" v-if="search_matches && search_matches.length > 0">
              <fa-icon class="content-viewer__nav-search-button" icon="fa-backward" @click="previousSearchMatch()"/>
              <fa-icon class="content-viewer__nav-search-button" icon="fa-forward" @click="nextSearchMatch()"/>
            </div>
            <div v-if="search_matches && search_matches.length > 0">
              <span v-if="!current_search_match">...</span>
              <span v-else>{{ current_search_match }}</span>
              <span>/</span>
              <span>{{ search_matches?.length }}</span>
            </div>
          </li>
          <li class="content-viewer__nav-item">
            <button role="button" class="nav-link" data-tippy-content="Заметки" @click.stop @click="openPopup('notes')">
              <img :src="imageUrl('note.svg')"/>
            </button>
          </li>
          <li>
            <div class="container-viewer__toggle">
                <label class="switch">
                  <input type="checkbox" id="checkbox" v-model="checked" @click="getVisibleItems()">
                  <span class="slider round"></span>
                </label>
                <p class="container-viewer__toggle-position">Режим конспекта</p>
            </div>
          </li>
        </ul>
      </div>

      <div v-if="!isNarrowMenu" class="d-flex flex-column content-viewer__nav-wide">
        <button class="content-viewer__nav-toggle-btn content-viewer__nav-toggle-btn-wide right" @click="toggleMenu()">
          <img :src="imageUrl('arrow-left.svg')"/>
        </button>
        <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
          <li class="content-viewer__nav-item-wide">
            <button role="button" class="nav-link-wide" @click.stop @click="openPopup('info')">
              <img :src="imageUrl('info.svg')" class="content-viewer__nav-img-info"/>
              <p class="content-viewer__nav-text">Информация</p>
            </button>
          </li>
          <li class="content-viewer__nav-item-wide">
            <button role="button" class="nav-link-wide" @click.stop @click="openTableOfContents">
                <img :src="imageUrl('contents.svg')" class="content-viewer__nav-img"/>
                <p class="content-viewer__nav-text">Оглавление</p>
            </button>
          </li>
          <li class="content-viewer__nav-item-wide">
            <a href="#" class="nav-link-wide" @click="showGallery">
              <img v-if="!hasImages()" :src="imageUrl('gallery.svg')" class="content-viewer__nav-img-gallery"/>
              <img v-if="hasImages()" :src="imageUrl('gallery-active.svg')" class="content-viewer__nav-img-gallery"/>
              <p class="content-viewer__nav-text">Галерея изображений</p>
            </a>
          </li>
          <li class="content-viewer__nav-item-wide">
            <button role="button" class="nav-link-wide" @click.stop @click="openPopup('tables')">
                <img :src="imageUrl('table.svg')" class="content-viewer__nav-img-table"/>
                <p class="content-viewer__nav-text">Таблицы</p>
            </button>
          </li>
          <li class="content-viewer__nav-item-wide content-viewer__nav-search" :class="{search: search_matches && search_matches.length > 0}">
            <img v-if="!search_matches" :src="imageUrl('magnifying-glass.svg')" class="content-viewer__nav-img-search"/>
            <img v-if="search_matches" :src="imageUrl('magnifying-glass-active.svg')" class="content-viewer__nav-img-search"/>
            <div class="content-viewer__nav-search-wrap" v-if="search_matches && search_matches.length > 0">
              <fa-icon class="content-viewer__nav-search-button" icon="fa-backward" @click="previousSearchMatch()"/>
              <fa-icon class="content-viewer__nav-search-button" icon="fa-forward" @click="nextSearchMatch()"/>
            </div>
            <div v-if="search_matches && search_matches.length > 0">
              <span v-if="!current_search_match">...</span>
              <span v-else>{{ current_search_match }}</span>
              <span>/</span>
              <span>{{ search_matches?.length }}</span>
            </div>
            <p class="content-viewer__nav-text">Инструменты поиска</p>
          </li>
          <li class="content-viewer__nav-item-wide underline">
            <button role="button" class="nav-link-wide" @click.stop @click="openPopup('notes')">
              <img :src="imageUrl('note.svg')" class="content-viewer__nav-img-note"/>
              <p class="content-viewer__nav-text">Заметки</p>
            </button>
          </li>
          <li class="nav-link-wide">
            <div class="container-viewer__toggle container-viewer__toggle-wide">
                <label class="switch">
                  <input type="checkbox" id="checkbox" v-model="checked" @click="getVisibleItems()">
                  <span class="slider round"></span>
                </label>
                <p class="container-viewer__toggle-position-wide">Режим конспекта</p>
              </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col">
        <div class="container-viewer__wrap" v-viewer.static="{inline: true}">
          <div class="row justify-content-center">
            <div class="col-auto">
              <h1 class="container-viewer__title">{{ meta.title }}</h1>
            </div>
          </div>
          <div class="row content-viewer__content">
            <div class="col-auto">
              <div class="spinner-border" role="status" v-if="loading">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="dynamic-content" ref="content_wrapper">
                <template v-if="!loading && content">
                  <table_of_contents :notes="notes" :meta="meta" :index="index" :blocks="blocks" :bookmarks="bookmarks" :isOpen="isOpenTableOfContents" @close="closeTableOfContents"></table_of_contents>
                  
                  <summary_viewer v-if="checked" :notes="notes" :content="content" :meta="meta" :visible-notes-ids="visibleNotesIds" :visible-blocks-ids="visibleBlocksIds" :all-sec-blocks-have-note="allSecBlocksHaveNote" @update="update" @getVisibleItems="getScrollItem"></summary_viewer>
    
                  <dynamic-content v-if="!checked && content" ref="content" :has-context-menu="hasNoteAndContextMenu" :content="content" :notes="notes" :hfurl="meta.hfurl" :id='meta.id' @update="update" @addBlockNote="addBlockNote" @addNoteInSummary="addNoteInSummary" @openMenu="cleanSelection" :scroll-el-id="scrollElId" ></dynamic-content>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isPopupInfoOpen" class="info-popup" ref="popup_info">
      <div class="info-popup__content" @click.stop>
        <button type="button" class="info-popup__btn" @click="closePopup('info')">
          <img :src="imageUrl('close.svg')"/>
        </button>

        <h4 class="info-popup__title">
          {{ meta.title }}
        </h4>

        <div class="info-popup__desc">
          {{ meta.epub_reference }}
        </div>

        <div>
          Ссылка на оригинал: <a :href="safeSource" target="_blank" class="info-popup__link">{{ meta.source }}</a>
        </div>
      </div>
    </div>

    <div v-show="isPopupTablesOpen" class="info-popup" ref="popup_tables">
      <div class="info-popup__content info-popup__tables" @click.stop>
        <button type="button" class="info-popup__btn" @click="closePopup('tables')">
          <img :src="imageUrl('close.svg')"/>
        </button>

        <h4 class="info-popup__title">Перечень таблиц</h4>

        <div v-for="table in table_index">
          <a @click="$event.preventDefault(); navigateTo(table.id)" href="#" class="info-popup__desc">
            <template v-if="table.title && table.title.content !== ''">
              <template v-inline-html v-html="table.title.content">
              </template>
            </template>
            <em v-else>
              Таблица без названия
            </em>
          </a>
        </div>
      </div>
    </div>

    <div class="info-popup popup-notes" v-show="isPopupNotesOpen" ref="popup_notes">
      <div class="info-popup__content" @click.stop>
        <button type="button" class="info-popup__btn popup__close-btn" @click="closePopup('notes')">
          <img :src="imageUrl('close.svg')"/>
        </button>

        <h4 class="info-popup__title">Заметки</h4>

        <div class="row" v-if="notes.length == 0 && !selection">
          <div>
            <em><ins>
              Чтобы добавить заметки к выбранным фрагментам текста, выделите необходимый контент и нажмите соответствующие кнопки во всплывающем меню
            </ins></em>
          </div>
        </div>

        <div class="info-popup__note" v-for="n in notes" :data-note-id="n.id">
          <figure>
            <blockquote class="blockquote d-flex flex-row">
              <p v-if="isNoteInEdit(n) && editEdges && addNewEdges" class="info-popup__text">
                <template v-for="bq in blockquoteParts(selection.selectionHTML)">
                  <div v-if="bq.dataset?.grade" class="info-popup__textWrap">
                    <GradeBadge :grade="bq.dataset.grade" />
                    <template v-inline-html v-html="bq.outerHTML ?? bq.textContent">
                    </template>
                  </div>
                  <template v-else>
                    <template v-inline-html v-html="bq.outerHTML ?? bq.textContent">
                    </template>
                  </template>
                </template>
              </p>
              <p v-else class="info-popup__text">
                <template v-for="bq in blockquoteParts(n.blockquote_html)">
                  <div v-if="bq.dataset?.grade" class="info-popup__textWrap">
                    <GradeBadge :grade="bq.dataset.grade" />
                    <template v-inline-html v-html="bq.outerHTML ?? bq.textContent">
                    </template>
                  </div>
                  <template v-else>
                    <template v-inline-html v-html="bq.outerHTML ?? bq.textContent">
                    </template>
                  </template>
                </template>
              </p>
            </blockquote> 
            <div v-if="hasContentChanges(n)" class="info-popup__warning">Контент блока изменился после создания заметки.<br/>Цитата может быть уже неактуальна.</div>
            <figcaption class="info-popup__figcaption" v-if="!isNoteInEdit(n) && hasSourceContentBlock(n)">
              <a href="#" class="nav-link-inner" @click="$event.preventDefault(); showNoteInContent(n.anchors[0])">Показать в тексте</a>
            </figcaption>
          </figure>

          <div class="info-popup__note-edit-wrap" :ref="n.id">
            <div class="info-popup__note-block" v-html="n.note_html" v-if="n.note_html && !isNoteInEdit(n)"></div>
            <div v-if="isNoteInEdit(n)" class="info-popup__editor mb-3">
              <QuillEditor
                v-model:content="n.note_html"
                contentType="html"
                theme="snow"
              ></QuillEditor>
            </div>
            <div class="mb-3">
              <div v-if="isNoteInEdit(n) && editEdges" class="mb-3">
                <p class="container-viewer__toggle-selection-position">Оставить старые границы выделения</p>
            
                <label class="switch">
                  <input type="checkbox" id="checbox-selection-edge" v-model="addNewEdges">
                  <span class="slider round"></span>
                </label>

                  <p class="container-viewer__toggle-selection-position">В новых границах выделения</p>
              </div>
            </div>

            <div class="row row-cols-auto">
              <div class="col-auto" v-for="(a, idx) in n.attachments">
                <AttachmentPreview
                  v-model="n.attachments[idx]"
                  :idx="idx"
                  :key="a"
                  :parent_id="n.id"
                  parent_type="note"
                  :readonly="!isNoteInEdit(n)"
                  @remove-attachment="removeAttachment"
                />
              </div>
            </div>

            <div class="info-popup__btns-wrap">
              <div class="info-popup__attachements" v-if="isNoteInEdit(n)">
                <!-- <label for="formFile" class="form-label">
                  <template v-if="!n.attachments || n.attachments.length == 0">Приложите необходимые файлы</template>

                  <template v-else>Добавьте необходимые файлы</template>
                </label> -->
                <label class="info-popup__label">
                  <span class="info-popup__label-btn">Прикрепить файл</span>        
                  <input 
                    class="info-popup__intup-file" 
                    type="file" 
                    ref="note-editor-file-input" 
                    multiple
                    @change="uploadFiles($event, n)"
                    :disabled="loading"
                  />
                </label>
              </div>

              <div class="info-popup__note-btns">
                <button type="button" v-if="!isNoteInEdit(n)" class=" info-popup__note-btn info-popup__note-btn-start-edit" @click="startEditNote(n)">Редактировать заметку</button>

                <button type="button" v-if="isNoteInEdit(n)" class="info-popup__note-btn info-popup__note-btn-save" @click="saveChangesNote(n)">Сохранить</button>

                <button type="button" v-if="isNoteInEdit(n)" class="info-popup__note-btn info-popup__note-btn-cancel" @click="stopNoteEdit(n)">Отменить</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="selection && !editEdges" ref="notes_form">
          <div class="col info-popup__note" >
            <fieldset>
              <legend class="info-popup__title">Создание заметки</legend>
              <figure class="mb-3">
                <blockquote class="blockquote d-flex flex-row">
                  <p class="info-popup__text">
                    <template v-for="bq in blockquoteParts(selection.selectionHTML)">
                      <div v-if="bq.dataset?.grade" class="info-popup__textWrap" >
                        <GradeBadge :grade="bq.dataset.grade" />
                        <template v-inline-html v-html="bq.outerHTML ?? bq.textContent">
                        </template>
                      </div>
                      <template v-else>
                        <template v-inline-html v-html="bq.outerHTML ?? bq.textContent">
                        </template>
                      </template>
                    </template>
                  </p>
                </blockquote> 
              </figure>
              <div class="info-popup__editor mb-3">
                <QuillEditor
                  v-model:content="note.note_html"
                  contentType="html"
                  theme="snow"
                ></QuillEditor>
              </div>

              <div class="mb-3">
                <div class="row row-cols-auto">
                  <div
                    v-if="note.attachments"
                    v-for="(a, idx) in note.attachments"
                    class="col-2"
                  >
                    <AttachmentPreview
                      v-model="note.attachments[idx]"
                      :idx="idx"
                      :parent_id="note.id"
                      parent_type="note"
                      @remove-attachment="removeAttachment"
                    />
                  </div>
                </div>
              </div>

              <div class="info-popup__btns-wrap">
                <div class="row mb-2">
                  <div class="col">
                    <!-- <label for="formFile" class="form-label">
                      <template v-if="note.attachments.length == 0">
                        Приложите необходимые файлы
                      </template>
                      <template v-else>
                        Добавьте необходимые файлы
                      </template>
                    </label> -->
                    <label class="info-popup__label">
                      <span class="info-popup__label-btn">Прикрепить файл</span>    
                      <input 
                      type="file" 
                      ref="note-editor-file-input" 
                      multiple
                      @change="uploadAttachmentFiles($event, note)"
                      :disabled="loading"
                    />
                    </label>
                  </div>
                </div>

                <div class="info-popup__note-btns">
                  <button type="button" class="info-popup__note-btn info-popup__note-btn-save" @click="saveNote">Сохранить</button>
                  <button type="button" class="info-popup__note-btn info-popup__note-btn-cancel" @click="cancelNoteEdit">Отменить</button>
                </div>
              </div>
             
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import tippy from 'tippy.js'
  import 'tippy.js/dist/tippy.css'
  import { computePosition, flip } from '@floating-ui/dom'
  import { 
    clinical_guideline_path,
    outer_gate_path,
    rails_service_blob_path
  } from '../../javascript/routes'

  import { QuillEditor } from '@vueup/vue-quill'
  import table_of_contents from '../components/table_of_contents.vue'
  import '@vueup/vue-quill/dist/vue-quill.snow.css'

  import 'viewerjs/dist/viewer.css'
  import { api as imageGallery, directive as dViewer } from "v-viewer"

  import ContentNotes from '../javascripts/content_notes'
  import ContentBookmarks from "../javascripts/content_bookmarks"
  import Table_of_contents from '../components/table_of_contents.vue'
  import summary_viewer from '../components/summary_viewer.vue'
  import AttachmentPreview from './attachment_preview.vue'

  import Uploader from '../javascripts/direct_uploads'
  import GradeBadge from './content/grade_badge.vue'

  import { getCurrentInstance } from 'vue/dist/vue.esm-bundler'
  import { getImageUrl } from '../javascripts/utils'
  
  export default {
    directives: {
      viewer: dViewer()
    },
    data() {
      return {
        content: null,
        search_matches: [],
        current_search_match: null,
        bookmarks: new Set(),
        summary: [],
        index: null,
        table_index: null,
        image_index: null,
        loading: false,
        selection: null,
        key: 1,
        selection_range: null,
        gallery: null,
        summaryMode: false,
        selection_offset: {
          x: 0,
          y: 0
        },
        note: {
          attachments: [],
          note_html: '',
          id: null
        },
        notes: [],
        blocks: [],
        isOpenTableOfContents: false,
        checked: false, 
        hasNoteAndContextMenu: true, 
        visibleNotesIds: [],
        visibleBlocksIds: [],
        isPopupInfoOpen: false,
        isPopupTablesOpen: false,
        isPopupNotesOpen: false,
        scrollElId: {},
        isEditNoteText: false,
        isStartEditNote: false, 
        currentNote: null,
        currentAttacnment: {},
        isTyped: {},
        addNewEdges: false,
        editEdges: false, 
        amountAnchor: null,
        notesForNotChangedContent: [],
        allSecBlocksHaveNote: false,
        isNarrowMenu: true
      }
    },
    props: {
      meta: {
        type: Object,
        default: {}
      },
      context: {
        type: [String, null],
      }
    },
    components: {
      QuillEditor,
      table_of_contents,
      summary_viewer,
      AttachmentPreview,
      GradeBadge
    },
    name: 'ContentViewer',
    methods: {
      toggleMenu() {
        this.isNarrowMenu = !this.isNarrowMenu
      },
      previousSearchMatch() {
        if(this.current_search_match > 1) {
          this.current_search_match = this.current_search_match - 1
        } else if(this.current_search_match === 1) {
          this.current_search_match = this.search_matches.length
        }
        this.scrollIntoView(this.search_matches[this.current_search_match - 1])
      },
      nextSearchMatch() {
        if(this.current_search_match < this.search_matches.length) {
          this.current_search_match = this.current_search_match + 1
         } else if(this.current_search_match === this.search_matches.length) {
          this.current_search_match = 1
        }
        this.scrollIntoView(this.search_matches[this.current_search_match - 1])
      },
      getAmountAcnchors(selection) {
        let notesId = []
        this.amountAnchor = null
        if(selection) {
          selection.overlyingNotes.forEach(el => notesId.push(el))
          if(notesId[0]) {
            let currentNote = this.notes.find(el => el.id === (notesId[0]))
            if(currentNote.anchors.length === 1) {
              this.amountAnchor = 1
            } 
            if( currentNote.anchors.length === 2) {
              this.amountAnchor = 2
            } 
          }
        }
      },
      requestNoteEdit(set) {
        this.startEditNote(Array.from(set)[0])
        this.editEdges = true
        this.addNewEdges = false
        this.openPopup('notes')
      },
      setTypedStatus(id) {
        this.isTyped[id] = true
      },
      uploadFiles(event, note) {
        this.uploadAttachmentFiles(event, note)
        this.currentAttacnment[note.id] = note.attachments
      },
      startEditNote(note_or_id) {
        if (typeof note_or_id === 'string') {
          this.currentNote = this.notes.find((n) => n.id === note_or_id)
        } else {
          this.currentNote = note_or_id
        }
      },
      isNoteInEdit(n) {
        return this.currentNote?.id === n.id
      },
      stopNoteEdit(note) {
        this.currentNote = null
      },
      saveChangesNote(note) {
        const attachments = this.currentAttacnment[note.id] ?? note.attachments
        var note_html = document.createElement('DIV')
        note_html.innerHTML = note.note_html ?? ''
        if (note_html.textContent !== "") {
          note_html = note.note_html
        } else {
          note_html = ''
        }

        let blockquote_html = this.addNewEdges ? this.selection.selectionHTML.outerHTML : undefined
        let anchors = this.addNewEdges ? this.selection.anchors.map((a) => { 
            return {
              anchor_id: a.anchor_id, 
              parent_id: a.parent_id, 
              block_id: a.block_id, 
              offset: a.offset
            }
          }) : undefined

        const data = {
          anchors,
          blockquote_html,
          note_html,
          content_id: note.content_id,
          block_hashes: note.block_hashes,
          attachments: attachments.map((a) => typeof a === 'string' ? a : a.signed_id),
          id: note.id
        }

        ContentNotes
          .saveNote(data)
          .then((resp) => {
            this.eventBus.emit("toast-show", {
              severity: "success",
              message: "Заметка сохранена",
            })
            
            try {
              if (this.hasNoteAndContextMenu) {
                ContentNotes.unmarkNote(note, this.notes)
                const { ranges } = ContentNotes.rangesForNoteAnchors(resp.data.content_note.anchors)
                this.markupNote(resp.data.content_note, ranges)
              }
            } catch (error) {
              console.error("Ошибка разметки заметки", error)
            } finally {
              Object.assign(note, resp.data.content_note)
              this.stopNoteEdit(note)
              this.closePopup('notes')
            }
          }).catch((error) => {
            console.error(error)
            this.eventBus.emit("toast-show", {
              severity: "error",
              message: "Ошибка сохранения заметки",
            })
          }).finally(() => {
          })
      },
      getScrollItem(id, type){
        this.scrollElId.id = id
        this.scrollElId.type = type
      },
      openPopup(popup) {
        if(popup === 'info') {
          this.isPopupInfoOpen = true
        } else if(popup === 'tables') {
          this.isPopupTablesOpen = true
        } else if(popup === 'notes') {
          this.isPopupNotesOpen = true
          this.$nextTick(() => {
            if (this.currentNote) {
              this.$refs[this.currentNote.id][0].scrollIntoView( {behavior: "smooth", block: "end"})
            } else if(this.$refs.notes_form) {
              this.$refs.notes_form.scrollIntoView( {behavior: "smooth", block: "end"})
            }
          })  
        }
      },
      closePopup(popup) {
        if (popup ==='info') {
          this.isPopupInfoOpen = false
        } else if(popup === 'tables') {
          this.isPopupTablesOpen = false
        } else if(popup === 'notes') {
          this.isPopupNotesOpen = false
          this.currentNote = null
          this.editEdges = false
          if (this.selection_range) {
            const selection = window.getSelection()
            selection.removeAllRanges()
            selection.addRange(this.selection_range)
          }
        }
      },
      handleClickOutside(event) {
        if (this.$refs.popup_info.contains(event.target)) {
          this.closePopup('info')
        } else if (this.$refs.popup_tables.contains(event.target)) {
          this.closePopup('tables')
        } else if (this.$refs.popup_notes.contains(event.target)) {
          this.closePopup('notes')
        }
      },
      cleanSelection() {
        const selection = window.getSelection()
        selection.removeAllRanges()
      }, 
      getVisibleItems() {
        if(!this.checked) {
          this.visibleBlocksIds = []
          this.visibleNotesIds = []
          let notes = document.querySelectorAll('.mark-content-note')
          let documentHeight = document.documentElement.clientHeight
          let ids = []

          let visibleNotes = []
          for(let i = 0; i < notes.length; i++) {
            if(notes[i].getBoundingClientRect().bottom < documentHeight && notes[i].getBoundingClientRect().bottom > 0) {
              visibleNotes.push(notes[i])
            }
          }

          if(visibleNotes.length > 0) {
            for(let i = 0; i < visibleNotes.length; i++) {
              ids.push(visibleNotes[i])
            }
            this.visibleNotesIds = ids
            let idNote = this.visibleNotesIds[0].dataset.id
            let idNode = this.notes.find(n => n.id === idNote).anchors[0].block_id
            let sec = idNode.split('_')[0]
            let secBlocks = this.blocks.filter(b => b.node_id.startsWith(`${sec}_p`))
            let allSecBlocksHaveNote = true
            let notesOnOneBlock = this.notes.filter(n => n.anchors.length === 1)
            let i = 0
            while(i < secBlocks.length) {
              if(!notesOnOneBlock.find( n => n.anchors[0].block_id === secBlocks[i].node_id )) {
                allSecBlocksHaveNote = false
                break
              }
              i = i + 1
            }
            this.allSecBlocksHaveNote = allSecBlocksHaveNote
          } else { 
            let items = document.getElementsByClassName('me-content')
            let visibleItems = []
          
            for(let i = 0; i < items.length; i++) {
              if(items[i].getBoundingClientRect().top > 0 && items[i].getBoundingClientRect().top < documentHeight ) {
                visibleItems.push(items[i])
              }
            }

            if(visibleItems.length === 0) {
              let j = 0
              while(j < items.length) {
                if(items[j].getBoundingClientRect().top > 0) {
                  visibleItems.push(items[j])
                  break
                }
                j = j + 1
              }
            }
          
            for(let k = 0; k < visibleItems.length; ++k) {
              if(visibleItems[k].id) {
                ids.push(visibleItems[k].id)
              }
            }
            this.visibleBlocksIds = ids
          }
        }
      },
      openTableOfContents() {
        this.isOpenTableOfContents = true
      },
      closeTableOfContents() {
        this.isOpenTableOfContents = false
      },
      update () {
        ContentNotes.getNotes(this.meta)
          .then((resp) => {
            this.notes =  resp.data.map((el) => el.content_note)
          })
        
        ContentBookmarks.getBookmarks(this.meta)
        .then((resp) => {
            this.bookmarks = new Set(resp.data)
          })
      },
      getContent(callback = null) {
        this.loading = true
        axios
          .get(
            clinical_guideline_path(this.meta.id, {format: 'json'}),
            {
              params: {
                context: this.$props.context
              },
              headers: {
                'Accept': 'application/json'
              }
            }
          ).then((resp) => {
            this.content = resp.data.clinical_guideline.content_chunk.content
            this.search_matches = resp.data.clinical_guideline.content_chunk.matched_content_ids
            if(window.location.hash) {
              const index = this.search_matches.indexOf(window.location.hash.slice(1))
              if(index > -1) {
                this.current_search_match = index + 1
              }
            }
            this.initialContent = JSON.parse(JSON.stringify(this.content))
            this.index = []
            this.table_index = []
            this.image_index = []
            this.content.blocks.forEach((b) => {
              if (b.type === "heading"){
                this.index.push({
                  id: b.node_id,
                  title: b.data.content,
                  level: b.data.level,
                })
              } else if (b.type === "table") {
                this.table_index.push({
                  id: b.node_id,
                  title: b.data.caption
                })
              } else if (b.type === "media") {
                b.data.files.forEach((f) => {
                  if (f.content_type?.startsWith("image/") || f.blob?.startsWith('data:image/') || f.url?.startsWith('data:image/')) {
                    const url = (f.signed_id ? rails_service_blob_path(f.signed_id, {filename: 'preview'}) : null) ?? f.url ?? f.blob
                    f.galleryIndex = this.image_index.length
                    this.image_index.push({
                      src: url,
                      alt: f.caption || 'Изображение без названия'
                    })
                  }
                })
              }
            }
          )
          }).catch((error) => {
          }).finally(() => {
            this.loading = false
            callback()
          })
      },
      resetContent() {
        this.content = JSON.parse(JSON.stringify(this.initialContent))
      },
      getNotesForNotChangedContent() {
        this.notesForNotChangedContent = []
        for(let i = 0; i < this.notes.length; i++) {
          if(!this.hasContentChanges(this.notes[i])) {
            this.notesForNotChangedContent.push(this.notes[i])
          }
        }
      },
      hasContentChanges(note) {
        let hash = null
        let node_id = null
        if(typeof note.block_hashes[Object.keys(note.block_hashes)[0]] === 'object') {
          node_id = Object.keys(note.block_hashes)[0]
          hash = note.block_hashes[Object.keys(note.block_hashes)[0]].hash
        } else {
          node_id = Object.keys(note.block_hashes)[0]
          hash = Object.values(note.block_hashes)[0]
        }
        let sourceBlock = this.content.blocks.find(b => b.node_id === node_id)
        if(sourceBlock) {
          return !(sourceBlock.hash === hash)
        } else {
          return true
        }
      },
      hasSourceContentBlock(note) {
        return this.content.blocks.some(b => {
          return b.node_id === Object.keys(note.block_hashes)[0]
        })
      }, 
      getContentNotes(callback = null) {
        ContentNotes
          .getNotes(this.meta)
          .then((resp) => {
            this.notes = resp.data.map((el) => el.content_note)
            this.getNotesForNotChangedContent()
          }).catch((error) => {
          }).finally(() => {
            callback ? callback() : null
          })
      },
      navigateTo(id, closeSidebar = true) {
        this.scrollIntoView(id)
        if (closeSidebar) {
          this.isPopupInfoOpen = false
          this.isPopupTablesOpen = false
          this.isPopupNotesOpen = false
        }
      },
      isBookmarked (id) {
        return ContentBookmarks.isBookmarked(id, this.bookmarks)
      },
      getSectionBookmarks(id) {
        return ContentBookmarks.getSectionBookmarks(id, this.bookmarks, this.content.blocks)
      },
      scrollIntoView(id, block = 'start') {
        const el = document.getElementById(id)
        if (el) {
          window.location.hash = id
          el.scrollIntoView({ 
            behavior: 'smooth', 
            block: block 
          })
        }
      },
      delay(ms) {
        return new Promise(res => {
          setTimeout(res, ms)
        })
      },
      hasImages() { 
        return this.image_index && this.image_index.length > 0 
      },
      hasTables() {
        return this.table_index && this.table_index.length > 0 
      },
      showGallery(event, index = 0) {
        event?.preventDefault()
        event?.stopPropagation()
        if (this.image_index.length > 0) {
          if (!this.gallery) {
            this.gallery = imageGallery({images: this.image_index, options: {initialViewIndex: index ?? 0}})
          } else {
            this.gallery.view(index)
          }
        }
      },
      updateTools() {
        computePosition(this.$refs.tools_reference, this.$refs.tools, {
          placement: 'top',
          middleware: [flip()],
        }).then(({x, y}) => {
          Object.assign(this.$refs.tools.style, {
            left: `${x}px`,
            top: `${y}px`,
          })
        })
      },
      showTools() {
        this.$refs.tools.style.display = 'block'
        this.updateTools()
      },
      hideTools() {
        this.$refs.tools.style.display = ''
      },
      proceedWithNote(note) {
        if (note) {
          this.note = note
        } else {
          this.note = {
            id: null,
            note_html: null,
            attachments: []
          }
        }
        this.openPopup('notes')
      },
      showNoteInContent(anchor) {
        this.selection = null
        this.scrollIntoView(anchor.anchor_id || anchor.block_id || anchor.parent_id)
        this.isPopupNotesOpen = false
        this.hideTools()
      },
      cancelNoteEdit() {
        this.isPopupNotesOpen = false
        this.selection = null
        this.hideTools()
      },
      saveNote() {
        const data = {
          anchors: this.selection.anchors.map((a) => { 
            return {
              anchor_id: a.anchor_id, 
              parent_id: a.parent_id, 
              block_id: a.block_id, 
              offset: a.offset
            }
          }),
          blockquote_html: this.selection.selectionHTML.outerHTML,
          note_html: this.note.note_html,
          content_id: this.meta.id,
          block_hashes: this.selection.block_hashes,
          attachments: this.note.attachments.map((a) => a.signed_id),
        }
        ContentNotes
          .saveNote(data)
          .then((resp) => {
            const { ranges } = ContentNotes.rangesForNoteAnchors(resp.data.content_note.anchors)

            this.markupNote(resp.data.content_note, ranges)
            this.selection_range = new Range()
            this.selection_range.setStart(ranges[0].startContainer, ranges[0].startOffset)
            this.selection_range.setEnd(ranges.at(-1).endContainer, ranges.at(-1).endOffset)
            this.notes.push(resp.data.content_note)
            this.selection = null
            this.note.note_html = null
            this.isPopupNotesOpen = false
            this.hideTools()
          }).catch((error) => {
            console.error(error)
            this.eventBus.emit("toast-show", {
              severity: "error",
              message: "Ошибка сохранения заметки",
            })
          }).finally(() => {
          })
      },
      selectionCallback() {
        const browser_selection = document.getSelection()
        this.selection = ContentNotes.selectionToNoteDetails(browser_selection)
        this.calculateBlockHashes()
        this.selection_range = browser_selection.getRangeAt(0).cloneRange()
      },
      addBlockNote(id) {
        this.selection = ContentNotes.blockToNoteDetails(id)
        this.selection_range = ContentNotes.rangeForElement(document.getElementById(id))
        this.calculateBlockHashes()
        this.proceedWithNote()
      },
      addNoteInSummary(id) {
        this.selection = ContentNotes.blockToNoteDetails(id)
        this.selection_range = ContentNotes.rangeForElement(document.getElementById(id))
        this.calculateBlockHashes()
        this.saveNote()
      },
      calculateBlockHashes() {
        this.selection.block_hashes = {}
        this.content.blocks
          .forEach((b) => {
            const [sec, p, sub, t, parametrizedTitle] = ContentNotes.extractBlockOrder(b.node_id)
            const order = [sec, p]
            if (!(order < this.selection.anchors[0].order.slice(0, 2)) && !(order > this.selection.anchors[0].order.slice(0, 2))) {
              this.selection.block_hashes[ContentNotes.buildId(sec, p, undefined, undefined, parametrizedTitle)] = { 
                hash: b.hash,
                block_uid: b.id
              }
            }
          })
      },
      addNoteInSummaryTools() {
        this.saveNote()
        this.eventBus.emit("toast-show", {
          severity: "success",
          message: "Добавлено в конспект",
        })
      },
      uploadAttachmentFiles(event, accum) {
        let addition = Array.from(event.target.files).map((f) => {
          const a = {
            filename: f.name, 
            progress: 0,
            signed_id: null,
          }
          let up = new Uploader(
            f, 
            (progress) => {
              a.progress = progress
              this.$forceUpdate()
            }
          )
          up.upload()
            .then((response) => {
              delete a.progress
              a.signed_id = response.signed_id
              a.content_type = response.content_type
              this.$forceUpdate()
            }).catch((error) => {
              a.error = error
              this.$forceUpdate()
            })

          return a
        })
        accum.attachments = accum.attachments.concat(addition)
      },
      removeAttachment(signedId, parentType) {
        if (parentType === 'note') {
          this.currentNote.attachments = this.currentNote.attachments.filter((a) => (a.signed_id !== signedId))
        }
      },
      blockquoteParts(bq) {
        return ContentNotes.blockquoteParts(bq)
      },
      markupNote(note) {
        try {
          
          ContentNotes.markupNote(note, this.notes)
        } catch (error) {
          console.error("Error rendering note", note, error)
        }
      },
      imageUrl(path) {
        return getImageUrl(path)
      }
    },
    computed: {
      sourceURL() {
        return this.meta.hfurl || window.location.pathname
      },
      safeSource() {
        return outer_gate_path({to: this.meta.source})
      },
    },
    watch: {
      checked(newValue, oldValue) {
        if(!newValue) {
          this.getContentNotes(() => {
            this.notesForNotChangedContent.forEach((note) => {
              this.markupNote(note)
            })
          })
        }
      },
    },
    mounted() {
      ContentNotes.app = getCurrentInstance()

      this.updateTools()

      const copyListener = (event) => {
        let s = document.getSelection()
        if (s && s.toString().length > 300) {
          const url = new URL(location.href)
          url.hash = ""
          event.clipboardData.setData("text/plain", 
            s.toString().substring(0, 300) + 
            `... читайте остальное на ${decodeURI(url.toString())}`
          )
          event.preventDefault()
        }
      }
      document.addEventListener("copy", copyListener)

      let that = this
      const selectionListener = (event) => {
        if (document.getSelection().toString() === "") {
          that.selection = null
          that.hideTools()
        } else {
          const selectionEndTimeout = setTimeout(function () {
            if (document.getSelection().toString() === "") {
              that.selection = null
              that.hideTools()
              return
            }
            that.$refs.tools_reference.style.top = event.clientY - document.body.scrollTop + window.scrollY + 'px'
            that.$refs.tools_reference.style.left = event.clientX - document.body.scrollLeft + window.scrollX + 'px'
            that.selectionCallback()
            if (that.selection.notable && that.selection.intersectedNotes.size <= 1) {
              that.getAmountAcnchors(that.selection)
              that.showTools()
            }
          }, 100)
        }
      }
      this.$refs.content_wrapper.addEventListener('mouseup', selectionListener)
      
      this.getContent(() => {
        this.$nextTick(() => {
          if (window.location.hash !== "") {
            this.delay(300).then(() => {
              this.navigateTo(window.location.hash.substring(1))
            })
          }
          const tooltipTriggerList = document.querySelectorAll('[data-tippy-content]')
          const tooltipList = [...tooltipTriggerList].map(el => tippy(el, {placement: el.dataset.tippyPlacement || 'right', allowHTML: true}))
        })
        this.blocks = this.content.blocks
        ContentBookmarks.getBookmarks(this.meta.id)
        .then((resp) => {
            this.bookmarks = new Set(resp.data)
          })
        
        if(this.hasNoteAndContextMenu) {
          this.getContentNotes(() => {
          this.notesForNotChangedContent.forEach((note) => {
            this.markupNote(note)
          })
        })
        }
      })
      this.eventBus.on('imageClicked', (f) => {this.showGallery(null, f.galleryIndex)})
      this.eventBus.on('edit-content-note', (id) => {
        this.startEditNote(id)
        this.openPopup('notes')
      })
      document.addEventListener("click", this.handleClickOutside)
    },
  }
</script>

<style>
  .right {
    transform: rotateY(180deg);
  }
 
  .nav-link-wide {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: black;
  }

  .nav-wrap {
    border: 2px solid #CFECEE;
    border-radius: 8px;
    background: #F7F9FB;
    height: 60px;
    width: 150px;
  }

  .nav-link__image {
    width: 40px;
    height: 40px;
    transition: transform .75s ease-out;
  }

  .nav-link__image:hover {
    transform: translateY(3px);
  }

  .content-viewer__nav-wrap {
    width: 85px;
    background: #fff;
    border-right: 1px solid #CFECEE;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .content-viewer__nav-item-wide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 320px;
    height: 60px;
  }

  .content-viewer__nav-img-info {
    padding: 22px;
  }

  .content-viewer__nav-img-gallery {
    padding: 22px;
    padding-left: 27px;
  }

  .content-viewer__nav-img-table {
    padding: 18px;
    padding-right: 24px;
  }

  .content-viewer__nav-text {
    display: block;
    margin: 0;
  }

  .content-viewer__nav {
    background: #fff;
    height: 100vh;
    padding-top: 124px;
    position: fixed;
    border-right: 1px solid #CFECEE;
  }
  .content-viewer__nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 60px;
    border-bottom: 1px solid #CFECEE;
  }

  .content-nav-link {
    color: unset;
    text-decoration-style: dashed;
    text-decoration-color: #A8234C;
  }

  .content-viewer__nav-icon {
    margin-top: 15px;
  }

  .content-viewer__nav-toggle-btn {
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #44B5BB;
    position: absolute;
    top: 16.5%;
    left: 85%;
  }

  .content-viewer__nav-toggle-btn-wide {
    left: 97%;
    top: 16.5%;
  }

  .content-viewer__link-wide {
    display: flex;
  }

  .content-viewer__nav-img-search {
    padding-left: 2px;
  }

  .content-viewer__nav-img-note {
    margin-left: -4px;
  }

  .text-tools-reference {
    position: absolute;
    top: 0;
    left: 0;
  }

  .text-tools {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 10;
  }

  .nav-link-inner {
    color: #5B5F67;
    text-decoration: none;
    font-size: 14px;
  }

  .glow {
    text-shadow: 0 0 3px magenta;
  }

  mark {
    padding: 0;
  }

  .container-viewer__toggle {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .container-viewer__toggle-selection {
    display: flex;
    align-items: center;
  }

  .container-viewer__toggle-position {
    text-transform: uppercase;
    font-size: 9px;
    margin: auto 0;
    width: 50px;
  }
  
  .container-viewer__toggle-selection-position {
    font-size: 11px;
    margin: 20px 0;
    width: 120px;
    text-align: center;
  }

  .container-viewer__toggle-wide {
    flex-direction: row;
    margin-left: 25px;
  }

  .container-viewer__toggle-position-wide {
    font-size: 16px;
    margin: 20px 0;
    width: 150px;
    text-align: center;
    margin-left: 10px;
  }

  .content-viewer__nav-wide {
    background: #fff;
    height: 100vh;
    padding-top: 124px;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    border-right: 1px solid #CFECEE;
  }

  .search {
    display: flex;
    flex-direction: column;
    height: 110px;
  }

  .content-viewer__nav-search-wrap {
    display: flex;
    margin-top: -20px;
  }

  .content-viewer__nav-search-button {
    padding: 5px;
  }

  .content-viewer__nav-icon-search {
    margin-top: 15px;
  }

  .underline {
    border-bottom: 1px solid #CFECEE;
  }

  .wideWrap {
    width: 350px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    border-right: 1px solid #CFECEE;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin: 5px 0;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .info-popup__note .switch {
    margin: 15px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .2s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .2s;
  }

  input:checked + .slider {
    background-color:  #44B5BB;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px  #44B5BB;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .info-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(205, 214, 219, 0.8);
    z-index: 10;
    color: #5B5F67;
  }

  .info-popup__tables .me-tooltip {
    display: inline;
    text-decoration: none;
  }

  .info-popup__content {
    position: fixed;
    top: 5%;
    left: 50%;
    background: #fff;
    width: 85%;
    z-index: 2;
    height: 90vh;
    padding: 40px;
    transform: translateX(-50%);
    overflow-y: scroll;
  }
  
  .info-popup__link {
    color: #177277;
  }

  .info-popup__text {
    font-size: 16px;
    margin-left: 8%;
  }

  .info-popup__textWrap {
    display: flex;
  }

  .info-popup__tables {
    overflow-y: hidden;
  }

  .info-popup__tables .info-popup__desc {
    color: #177277;
    display: block;
    margin-bottom: 16px;
  }

  .info-popup__btn {
    width: 25px;
    height: 25px;
    border: none;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .info-popup__title {
    margin-bottom: 40px;
    font-size: 28px;
    font-weight: 700;
  }

  .info-popup__figcaption {
    width: fit-content;
    padding: 3px;
    border-bottom: 2px dashed #44B5BB;
    margin-top: 16px;
  }

  .info-popup__note blockquote:after {
    display: none;
  }

  .info-popup__note blockquote:before {
    position: absolute;
    content: url(/images/open-quote.svg);
  }

  .info-popup__note blockquote.blockquote:before {
    color: #44B5BB;
  }

  .info-popup__desc {
    margin-bottom: 20px;
  }

  .info-popup__note {
    margin-bottom: 80px;
  }

  .info-popup__note-block {
    border: 1px solid #44B5BB;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 40px;
    background: #EDF8F9;
  }

  .info-popup__note .blockquote {
    padding: 0;
    margin: 0;
    margin-top: 24px;
  }

  .info-popup__note .selection-blockquote-part {
    margin: 0;
  }

  .info-popup__note blockquote.blockquote{
    border: none;
  }

  .info-popup__warning {
    width: fit-content;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #f0715f;
    border-radius: 8px;
    font-size: 16px;
    color: #f0715f;
  }

  .info-popup__note-input {
    display: flex;
    margin-bottom: 20px;
  }

  .info-popup__label input[type=file]{
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }

  .info-popup__label-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    height: 54px;
    padding: 10px;
    border: none;
    color: #5B5F67;
    padding-left: 30px;
    padding-top: 18px;
  }

  .info-popup__label-btn:before {
    content: url(/images/link.svg);
    margin-right: 15px;
    position: absolute;
    left: 0;
  }

  .info-popup__label-btn:hover {
    color: #177277;
  }

  .info-popup__note .ql-toolbar.ql-snow {
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 1px solid #CFECEE;
    background: #EDF8F9;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .info-popup__editor {
    border: 1px solid #CFECEE;
    border-radius: 8px;
    height: 152px;
  }

  .info-popup__note .ql-container.ql-snow {
    border: none;
  }

  .info-popup__note-btn {
    border: none;
    border-radius: 8px;
    width: 188px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(270deg, #44B5BB, #1F5255 104.62%);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
  }

  .info-popup__note-btn-start-edit {
    margin-left: -20px;
  }

  .info-popup__note-btn-save {
    background: #44B5BB; 
    width: 145px;
    margin-right: 6px;
  }

  .info-popup__note-btn-save:hover {
    background: linear-gradient(270deg, #44B5BB, #1F5255 104.62%);
  }

  .info-popup__attachements {
    display: flex;
    flex-direction: column;
  }

  .info-popup__btns-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }

  .info-popup__note-btn-edit {
    display: flex;
  }

  .info-popup__note-btn-cancel {
    background: transparent;
    color: #5B5F67;
    width: 94px;
  }

  .info-popup__note-btn-cancel:hover {
    color: #177277;
  }

  .info-popup__note-btns {
    display: flex;
  }

  .info-popup__note-btn-add {
    display: flex;
  }

  .selection-blockquote-part {
    margin-bottom: 1.5rem;
    display: block;
  }

  .popup-notes {
    display: flex;
  }

  .mark-content-note {
    padding: 0;
    /*background: #CFECEE;
    color: #177277;*/

    .mark-content-note {
      background-color: bisque;
    }
  }

  .mark-block-note + .mark-content-note:not(.mark-block-note), .mark-content-note:not(.mark-block-note):has(+ .mark-block-note) {
    background-color: bisque;
  }

  .mark-search-match {
    background-color: rgb(213, 251, 237);
    padding: 2px;
    &::before {
      font: var(--fa-font-solid);
      font-size: 0.75rem;
      content: '\f002  ';
    }
  }
</style>
