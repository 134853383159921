<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-light mb-3 editor-menu drop-shadow sticky-top">
      <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#editorNavbar" aria-controls="editorNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <span>Редактирование фрагмента контента {{ this.chunk?.name || this.id }}</span>

        <div class="collapse navbar-collapse" id="editorNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" :class="{disabled: !editorState.canUndo}" href="#" @click="doUndo">Undo</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{disabled: !editorState.canRedo}" href="#" @click="doRedo">Redo</a>
            </li>
          </ul>
        </div>

        <div class="d-flex">
          <button type="button" class="btn btn-primary" @click="saveContent" :disabled="saveInProgress">Сохранить</button>
        </div>
      </div>
    </nav>
    
    <ContentEditor :content="chunk?.content_chunk?.content" v-model="editorState" ref="editor" />
  </div>
</template>

<script>
  import axios from 'axios'
  import { 
    content_chunks_path, 
    content_chunk_path,
    edit_content_chunk_path 
  } from '../../javascript/routes'

  import ContentEditor from './content_editor.vue'
  
  export default {
    data() {
      return {
        content: this.chunk?.content_chunk?.content,
        id: this.chunk?.content_chunk?.id,
        saveInProgress: false,
        editorState: {
          canUndo: false,
          canRedor: false,
        }
      }
    },
    components: {
      ContentEditor
    },
    props: {
      chunk: Object
    },
    methods: {
      saveContent() {
        this.error = null
        this.saveInProgress = true
        this.$refs.editor.getContent().then((output) => {
          this
            .saveChunk(output)
            .then(() => {
              this.saveInProgress = false
            })
        }).catch((error) => {
          this.eventBus.emit('toast-show', {
            severity: 'alert',
            message: 'Ошибка подготовки контента к сохранению',
          })
          console.error(error)
        })
      },
      doUndo(event) {
        event.preventDefault()
        this.$refs.editor.doUndo()
      },
      doRedo(event) {
        event.preventDefault()
        this.$refs.editor.doRedo()
      },
      saveChunk(content) {
        const data = {
          content_chunk: { content }
        }

        const csrfToken = document.getElementsByName("csrf-token")[0].content
        const url = this.id ? content_chunk_path(this.id, {format: 'json'}) : content_chunks_path({format: 'json'})
        const method = this.id ? 'patch' : 'post'
        
        return axios[method](
          url,
          data,
          { headers: { "X-CSRF-Token": csrfToken } }
        ).then((r) => {
          if (!this.id) {
            this.id = r.data.content_chunk.id
            window.history.replaceState({}, "Редактор", edit_content_chunk_path(r.data.id));
          }
          this.eventBus.emit('toast-show', {
            severity: 'success',
            message: 'Контент сохранён',
            delay: 5000
          })
        }).catch((error) => {
          this.eventBus.emit('toast-show', {
            severity: 'alert',
            message: 'Ошибка сохранения контента',
          })
          console.error(error)
        })
      },
    },
    mounted() {
      console.log(this.$refs)
    }
  }
</script>