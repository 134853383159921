<template>
  <div class="mb-2">
    <GradeBadge :grade="block.data.grade" v-if="block.data.grade"/>

    <div class="mb-3 me-content" :id="block.node_id" :data-hash="block.hash" :data-id="orderId()">
      <figure v-for="f in block.data.files" :id="f.node_id">
        <LazyImage 
          :src="src(f)"
          :width="f.width"
          :height="f.height"
          @imageClicked="imageClicked(f)"
        />
        <figcaption v-html="f.caption"></figcaption>
      </figure>
      <div :id="block.data.caption.node_id">{{ block.data.caption.content }}</div>
    </div>
  </div>
</template>

<script>
import LazyImage from '../lazy_image.vue';
import ContentNotes from '../../javascripts/content_notes'
import GradeBadge from './grade_badge.vue'

export default {
  components: {
    LazyImage,
    GradeBadge,
  },
  props: {
    block: {
      type: Object,
      required: true,
    }
  },
  methods: {
    src(f) {
      return (f.signed_id ? rails_service_blob_path(f.signed_id, {filename: 'preview'}) : null) ?? f.url ?? f.blob
    },
    imageClicked(obj) {
      this.eventBus.emit('imageClicked', obj)
    },
    orderId() {
      return this.block.node_id ? ContentNotes.orderId(this.block.node_id) : ''
    },
   }
}
</script>