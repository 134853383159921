<template>
  <h3>Редакция контента</h3>
  <div class="row mb-3">
    <div class="col">
      <input class="form-control" v-model="filter.prompt" placeholder="Начните вводить любую часть названия для поиска"/>
    </div>
    <div class="col-auto">
      <a :href="pathToNew()" target="_blank">
        <fa-icon :icon="['fas', 'plus-circle']"></fa-icon>
        Новый фрагмент контента
      </a>
    </div>
  </div>
  <div class="row justify-content-center" v-if="list">
    <div class="col">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Название</th>
            <th>Создано</th>
            <th>Последнее изменение</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cc in filtered">
            <td>
              <h6>
                <a :href="editPath(cc)" target="_blank">
                  {{ cc.name }}
                </a>
              </h6>
            </td>
            <td>{{ cc.created_at }}</td>
            <td>{{ cc.updated_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row justify-content-center" v-else>
    <div class="col-auto">
      <div class="spinner-border my-5">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { 
    content_chunks_path, 
    edit_content_chunk_path,
    new_content_chunk_path
   } from '../../../javascript/routes'
  import axios from 'axios'
  
  export default {
    data() {
      return {
        list: null,
        error: null,
        loading: false,
        filter: {
          prompt: ''
        }
      }
    },
    methods: {
      fetchList() {
        this.error = null
        this.list = null
        this.loading = true
        axios
          .get(content_chunks_path({format: 'json'}))
          .then((resp) => {
            this.list = resp.data
          }).catch((error) => {
            this.error = error
          }).finally(() => {
            this.loading = false
          })
      },
      pathToNew() {
        return new_content_chunk_path()
      },
      editPath(cc) {
        return edit_content_chunk_path(cc.id)
      }
    },
    computed: {
      filtered() {
        if (this.filter.prompt && this.filter.prompt !== '') {
          console.log(this.list[0])
          return this.list.filter((el) => el.name?.toLowerCase()?.includes(this.filter.prompt.toLowerCase()))
        } else {
          return this.list
        }
      }
    },
    mounted() {
      this.fetchList()
    }
  }
</script>
