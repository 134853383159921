import axios from "axios"
import {
  content_bookmarks_path,
  content_bookmark_path,
} from "../../javascript/routes"
export default class ContentBookmarks {
  static getBookmarks(id) {
    return axios.get(content_bookmarks_path(id, { format: "json" }))
  }

  static setBookmark(selectionId, metaId) {
    const bookmark = {
      id: selectionId,
    }
    const csrfToken = document.getElementsByName("csrf-token")[0].content
    return axios.post(
      content_bookmarks_path(metaId, { format: "json" }),
      { bookmark },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      }
    )
  }

  static removeBookmark(selectionId, metaId) {
    const csrfToken = document.getElementsByName("csrf-token")[0].content
    return axios.delete(
      content_bookmark_path(metaId, selectionId, { format: "json" }),
      {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      }
    )
  }

  static isBookmarked(id, bookmarks) {
    return bookmarks.has(id)
  }

  static getSectionBookmarks(id, bookmarks, blocks) {
    const sec = parseInt(id.split("_")[0].split("sec")[1] || "0")
    return Array.from(bookmarks)
      .filter(
        (bm_id) =>
          parseInt(bm_id.split("_")[0].split("sec")[1] || "0") === sec &&
          id !== bm_id
      )
      .map((bm_id) => {
        const block = blocks.find((b) => b.node_id === bm_id)
        var content_preview = null
        if (block.type === "paragraph") {
          const el = document.createElement("DIV")
          el.innerHTML = block.data.content
          content_preview = el.textContent.substring(0, 100)
        } else if (block.type === "table") {
          content_preview = "Таблица " + block.data.caption ?? "без названия"
        } else if (block.type === "list") {
          if (block.data.explanation && block.data.explanation !== "") {
            content_preview = "Список. " + block.data.explanation
          } else {
            content_preview = "Список без пояснения"
          }
        } else if (block.type === "media") {
          content_preview = "Медиа-блок"
        } else {
          content_preview = "Контент"
        }
        return {
          fragment_id: bm_id,
          content_preview,
        }
      })
  }
}
