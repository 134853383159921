import './index.css';
import tooltipIcon from './assets/tooltipIcon.svg?raw';
import SelectionUtils from '../../selection';

/**
 * Tooltip for the Editor.js.
 * Add a tooltip inline in the Toolbar.
 * Requires no server-side uploader.
 *
 * @typedef {object} TooltipData
 * @description Tool's input and output data format
 * @property {string} tooltip — tooltip text
 */

export default class Tooltip {
  static get isInline() {
    return true;
  }

  /**
   * @param {object} api Editor.js api
   */
  constructor({ api, config = {} }) {
    this.api = api;
    this.button = null;
    this.state = false;
    this.editing = false;

    const { location = 'bottom' } = config;
    this.tooltipLocation = location;
    this.holder = config.holder
    this.placeholder = config.placeholder ?? 'Введите текст подсказки';

    this.tag = 'SPAN';

    this.inProgress = null

    this.CSS = {
      input: 'ce-inline-tool-input',
      inputShowed: 'ce-inline-tool-input--showed',
      tooltip: 'me-tooltip',
    };
    
    this.observe()

    this.selection = new SelectionUtils()
  }

  /**
   * Observe if some tooltip span is inserted and create the respective tooltip
   */
  observe() {
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === 'childList'
        && mutation.target.classList.contains('codex-editor__redactor')) {
          const spanTooltips = document.querySelectorAll(`.${this.CSS.tooltip}`);

          spanTooltips.forEach((span) => this.createTooltip(span.dataset.meTooltip, span));
        }
      });
    });

    observer.observe(this.holder, { childList: true, subtree: true });
  }

  /**
   * Create the Tooltips with the Tooltip API
   * @param {String} tooltipValue is the tooltip text
   * @param {HTMLElement} spanTooltip is the selected text where the tooltip is created
   */
  createTooltip(tooltipValue, spanTooltip) {
    if (!spanTooltip) {
      spanTooltip = this.api.selection.findParentTag(this.tag, this.CSS.tooltip)
      if (!spanTooltip) {
        spanTooltip = document.createElement(this.tag)
      }
      spanTooltip.classList.add(this.CSS.tooltip)
    }
      
    spanTooltip.dataset.meTooltip = tooltipValue
    
    const { tooltipLocation } = this;
    this.api.tooltip.onHover(spanTooltip, tooltipValue, { placement: tooltipLocation })

    return spanTooltip
  }

  /**
   * render the button in the inline toolbar
   * @returns the button element created to the inline toolbar
   */

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML = tooltipIcon;
    const { inlineToolButton } = this.api.styles;
    this.button.classList.add(inlineToolButton);

    return this.button;
  }

  restoreSelection() {
    this.selection.restore()
    this.selection.removeFakeBackground()
  }
  
  /**
   * The method is called when the button rendered in render() is clicked
   * create a span to enclose the selected text.
   * @param {object} range is an object with info about the selected text
   * @returns
   */

  surround(range) {
    if (range) {
      if (this.tooltipInput.classList.contains(this.CSS.inputShowed)) {
        this.restoreSelection()
        this.unwrap()
        this.hideActions(false)
      } else {
        if (this.state) {
          this.editing = true
          this.tooltipInput.value = this.state.dataset.meTooltip
        }
        this.selection.setFakeBackground();
        this.selection.save(range);
        this.showActions()
      }
    } else {
      this.hideActions()
    }
  }

  clear() {
    this.hideActions()
  }

  /**
   * wrap creates the span element for the selected text
   * @param {object} range is an object with info about the selected text
   */

  wrap(spanTooltip) {
    const selectedText = this.selection.savedSelectionRange.extractContents();
    spanTooltip.appendChild(selectedText);
    this.selection.savedSelectionRange.insertNode(spanTooltip);

    this.api.selection.expandToTag(spanTooltip);
  }

  /**
   * unwrap delete the span if the tool is disabled
   * @param {object} range is an object with info about the selected text
   */
  unwrap() {
    let spanTooltip = this.api.selection.findParentTag(this.tag, this.CSS.tooltip);
    if (!spanTooltip) {
      return
    }
    
    const parent = spanTooltip.parentNode
    while (spanTooltip.firstChild) {
      parent.insertBefore(spanTooltip.firstChild, spanTooltip)
    }
    parent.removeChild(spanTooltip)
  }

  /**
   * Checkstate is called when the user select any text
   * check the state of the tool in the selected text
   */
  checkState() {
    this.state = this.api.selection.findParentTag(this.tag, this.CSS.tooltip);
    if (this.state || this.editing) {
      const { inlineToolButtonActive } = this.api.styles;
      this.button.classList.toggle(inlineToolButtonActive, true);
    } else {
      const { inlineToolButtonActive } = this.api.styles;
      this.button.classList.toggle(inlineToolButtonActive, false);
    }
  }

  /**
   * render actions in the Toolbar
   * @returns the input in the Toolbar to insert the tooltip
   */
  renderActions() {
    this.tooltipInput = document.createElement('input');
    this.tooltipInput.placeholder = this.placeholder;
    this.tooltipInput.classList.add(this.CSS.input);
    return this.tooltipInput;
  }

  /**
   * Show the input and create the tooltip when the user presses Enter
   */
  showActions() {
    this.tooltipInput.classList.add(this.CSS.inputShowed);
    this.tooltipInput.focus()
    this.api.listeners.on(this.tooltipInput, 'keydown', (e) => {
      if (e.key === 'Enter') {
        this.restoreSelection()
        if (this.tooltipInput.value !== '') {
          let spanTooltip = this.createTooltip(this.tooltipInput.value);
          this.wrap(spanTooltip)
        } else {
          this.unwrap()
        }
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation(); 
        this.editing = false 
        this.api.inlineToolbar.close()
      }
    }, false);
  }

  /**
   * Hide the input if the user do not have tooltip in the selected text
   */
  hideActions(clearSavedSelection = true) {
    this.editing = false
    if (this.selection.isFakeBackgroundEnabled) {
      // if actions is broken by other selection We need to save new selection
      const currentSelection = new SelectionUtils();

      currentSelection.save();

      this.selection.restore();
      this.selection.removeFakeBackground();

      // and recover new selection after removing fake background
      currentSelection.restore();
    }

    this.tooltipInput.classList.remove(this.CSS.inputShowed);
    this.tooltipInput.value = ''

    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
  }

  /**
   * satanize the data output
  */
  static get sanitize() {
    return {
      span: {
        class: true, 
        'data-me-tooltip': true 
      }
    }
  }
}