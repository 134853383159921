<template>
  <div class="pages">
    <h3 class="pages__title">Клинические рекомендации</h3>
    <div class="row mb-3">
      <div class="pages__searchWrap">
        <div class="pages__searchIcon"></div>
        <input class="form-control pages__searchInput" v-model="filter.prompt" placeholder="Начните вводить любую часть названия для поиска"/>
      </div>
    </div>
    <div class="row justify-content-center" v-if="guidelines">
      <div class="col">
        <div v-for="g in filteredGuidelines" class="pages__item">
          <div class="pages__row">
            <div class="pages__year">
              <p class="pages__agesText">Возрастная группа:</p>  
              <span class="pages__listItem">
                {{ setAgesBadge(g.clinical_guideline.ages) }}
              </span>
            </div>
            <div class="pages__year">
              <p class="pages__yearText">Год: </p>
              <p class="pages__yearValue"> {{ g.clinical_guideline.year }}</p>
            </div>
          </div>
        
          <div class="pages__row">
            <h6 class= "pages__itemTitle">
              <a :href="g.clinical_guideline.hfurl" target="_blank" class= "pages__link">
                  {{ g.clinical_guideline.title }}
              </a>
            </h6>

            <a :href="g.clinical_guideline.hfurl + '/edit'" target="_blank">
              <div class="pages__editIcon"></div>
            </a>
          </div>
          
          <div class="pages__wrap">
            <span class="pages__icd10Wrap" v-for="d in g.clinical_guideline.icd10">
              <p class="pages__icd10Text">{{ d }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col-auto">
        <div class="spinner-border my-5">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { clinical_guidelines_path, edit_clinical_guidelines_hfurl_path } from '../../../javascript/routes'
  import axios from 'axios'
  
  export default {
    data() {
      return {
        guidelines: null,
        error: null,
        loading: false,
        filter: {
          prompt: ''
        }, 
      }
    },
    methods: {
      fetchList() {
        this.error = null
        this.guidelines = null
        this.loading = true
        axios
          .get(clinical_guidelines_path({format: 'json'}))
          .then((resp) => {
            this.guidelines = resp.data
          }).catch((error) => {
            this.error = error
          }).finally(() => {
            this.loading = false
          })
      },
      setAgesBadge(ages) {
        let agesBadge = ''
        if(ages.length > 1)  {
          return agesBadge = "Все возрастные группы"
        } else {
          if(ages[0] === 'adults') {
            return agesBadge = "Взрослые"
          } else {
            return agesBadge = "Дети"
          }
        }
      }
    },
    computed: {
      filteredGuidelines() {
        if (this.filter.prompt && this.filter.prompt !== '') {
          return this.guidelines.filter((el) => el.clinical_guideline.title.toLowerCase().includes(this.filter.prompt.toLowerCase()))
        } else {
          return this.guidelines
        }
      }
    },
    mounted() {
      this.fetchList()
    }
  }
</script>

<style lang="scss">
@import "../../stylesheets/application.scss";

.pages {
    background: $neutral-color-4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 36px;
    overflow: hidden;

    &__title {
      margin-top: 80px;
      text-align: center;
      color: $secondary-color-1;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    &__searchWrap {
      max-width: 950px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 36px;
    }

    &__searchIcon {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 5px;
      border: none;
      background-image: url('/images/search.svg');
      background-repeat: no-repeat;
      margin-top: 20px;
      margin-left: 22px;
    }

    &__editIcon {
      width: 20px;
      height: 20px;
      border: none;
      background-image: url('/images/edit.svg');
    }

    &__searchInput {
      padding-left: 50px;
      height: 54px;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(68, 181, 187, 0.25);
      }

      &::placeholder {
        color: $secondary-secondary-color-2;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      max-width: 1150px;
      margin: 0 auto;
      background: #fff;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 24px;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__agesWrap {
      display: flex;
      align-items: center;
    }

    &__agesText {
      padding: 0;
      margin: 0;
      color: #5B5F67;
      margin-right: 12px;
      padding: 4px;
    }

    &__listItem {
      background: 8px;
      background: rgba(68, 127, 196, 0.1);
      padding: 4px;
      border-radius: 8px;
      text-transform: capitalize;
      color: $system-pause;
      height: fit-content;
    }

    &__year {
      display: flex;
    }

    &__yearText {
      padding: 0;
      margin: 0;
      color: #5B5F67;
      margin-right: 12px;
    }

    &__link {
      text-decoration: none;
      color: #1A1C1C;
      font-size: 16px;
      font-weight: 700;
    }

    &__wrap {
      display: flex;
    }

    &__icd10Wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border: 1px solid $secondary-secondary-color-2;
      margin-right: 16px;
      border-radius: 8px;
    }

    &__icd10Text {
      color: $secondary-secondary-color-2;
      font-size: 13px;
      padding: 0;
      margin: 0;
    }
}

</style>
