import { h, render, createApp } from 'vue/dist/vue.esm-bundler.js'

function createComponent(component, props = {}, slots = {}) {
  return h(component, props, slots)
}

function createAppComponent(app, component, el, props = {}, slots = {}) {
  var expose = null  
  const childApp = createApp({ render: () => expose = createComponent(component, props, slots) })
  Object.assign(childApp._context, app.appContext) 
  childApp.mount(el)
  return expose.component.exposed
}

function getImageUrl(path) {
  return new URL(`../images/${path}`, import.meta.url).href
}

export { createComponent, createAppComponent, getImageUrl }