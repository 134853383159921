export default class SelectionUtils {
  constructor() {
    this.isFakeBackgroundEnabled = false
    this.savedSelectionRange = null

    this.command = {
      backColor: 'backColor',
      removeFormat: 'removeFormat',
    }
  }

  /**
   * Removes fake background
   */
  removeFakeBackground() {
    if (!this.isFakeBackgroundEnabled) {
      return;
    }

    this.isFakeBackgroundEnabled = false;
    document.execCommand(this.command.removeFormat);
  }

  /**
   * Sets fake background
   */
  setFakeBackground() {
    document.execCommand(this.command.backColor, false, '#a8d6ff');

    this.isFakeBackgroundEnabled = true;
  }

  /**
   * Save SelectionUtils's range
   */
  save(range) {
    this.savedSelectionRange = range
  }

  /**
   * Restore saved SelectionUtils's range
   */
  restore() {
    if (!this.savedSelectionRange) {
      return;
    }

    const sel = window.getSelection();

    sel.removeAllRanges();
    sel.addRange(this.savedSelectionRange);
  }

  /**
   * Clears saved selection
   */
  clearSaved() {
    this.savedSelectionRange = null;
  }
}