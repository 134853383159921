import Toolbox from '../../utils/toolbox';
import * as $ from '../../utils/dom';
import IconMergeUp from './images/merge-up.svg?raw'
import IconMergeDown from './images/merge-down.svg?raw'
import IconMergeLeft from './images/merge-left.svg?raw'
import IconMergeRight from './images/merge-right.svg?raw'
import IconSplitRows from './images/col-split.svg?raw'
import IconSplitColumns from './images/row-split.svg?raw'

import {
  IconDirectionLeftDown,
  IconDirectionRightDown,
  IconDirectionUpRight,
  IconDirectionDownRight,
  IconCross,
  IconPlus,
  IconTableWithHeadings,
  IconTable
} from '@codexteam/icons';

/**
 * Generates and manages table contents.
 */
export default class Table {
  /**
   * Creates
   *
   * @constructor
   * @param {boolean} readOnly - read-only mode flag
   * @param {object} api - Editor.js API
   * @param {TableData} data - Editor.js API
   * @param {TableConfig} config - Editor.js API
   */
  constructor(readOnly, api, data, config) {
    this.readOnly = readOnly;
    this.api = api;
    this.data = data;
    this.config = config;

    /**
     * DOM nodes
     */
    this.wrapper = null;
    this.world = null
    this.captionInput = null
    this.table = null;
    this.tbody = null
    this.vTable = []
    this.rowElements = []
    this.tfoot = null

    this.CSS = {
      wrapper: 'tc-wrap',
      wrapperReadOnly: 'tc-wrap--readonly',
      table: 'tc-table',
      row: 'tc-row',
      cellHeading: 'tc-cell--heading',
      cellFooter: 'tc-cell--footer',
      rowSelected: 'tc-row--selected',
      cell: 'tc-cell',
      cellSelected: 'tc-cell--selected',
      addRow: 'tc-add-row',
      addColumn: 'tc-add-column',
      caption: 'tc-caption',
      input: this.api.styles.input,
    }

    this.size = null
    
    /**
     * Toolbox for managing of columns
     */
    this.toolboxColumn = this.createColumnToolbox();
    this.toolboxRow = this.createRowToolbox();

    /**
     * Create table and wrapper elements
     */
    this.createTableWrapper();

    // Current hovered cell element
    this.hoveredCell = null;

    // Index of last selected row via toolbox
    this.selectedRow = null;

    // Index of last selected column via toolbox
    this.selectedColumn = null;

    /**
     * Resize table to match config/data size
     */
    this.init();

    /**
     * The cell in which the focus is currently located, if 0 and 0 then there is no focus
     * Uses to switch between cells with buttons
     */
    this.unfocus()

    /**
     * Global click listener allows to delegate clicks on some elements
     */
    this.documentClicked = (event) => {
      const clickedInsideTable = event.target.closest(`.${this.CSS.table}`) !== null;
      const outsideTableClicked = event.target.closest(`.${this.CSS.wrapper}`) === null;
      const clickedOutsideToolboxes = clickedInsideTable || outsideTableClicked;

      if (clickedOutsideToolboxes) {
        this.hideToolboxes();
      }

      const clickedOnAddRowButton = event.target.closest(`.${this.CSS.addRow}`);
      const clickedOnAddColumnButton = event.target.closest(`.${this.CSS.addColumn}`);

      /**
       * Also, check if clicked in current table, not other (because documentClicked bound to the whole document)
       */
      if (clickedOnAddRowButton && clickedOnAddRowButton.parentNode === this.wrapper) {
        this.addRow(null, true);
        this.hideToolboxes();
      } else if (clickedOnAddColumnButton && clickedOnAddColumnButton.parentNode === this.wrapper) {
        this.addColumn(null, true);
        this.hideToolboxes();
      }
    };

    if (!this.readOnly) {
      this.bindEvents();
    }
  }

  /**
   * Returns the rendered table wrapper
   *
   * @returns {Element}
   */
  getWrapper() {
    return this.world;
  }

  /**
   * Hangs the necessary handlers to events
   */
  bindEvents() {
    // set the listener to close toolboxes when click outside
    document.addEventListener('click', this.documentClicked);

    // Update toolboxes position depending on the mouse movements
    //this.tbody.addEventListener('mousemove', throttled(150, (event) => this.onMouseMoveInTable(event)), { passive: true });

    // Controls some of the keyboard buttons inside the table
    this.tbody.onkeypress = (event) => this.onKeyPressListener(event);

    // Tab is executed by default before keypress, so it must be intercepted on keydown
    this.tbody.addEventListener('keydown', (event) => this.onKeyDownListener(event));

    // Determine the position of the cell in focus
    this.tbody.addEventListener('focusin', event => this.focusInTableListener(event));
    //this.tbody.addEventListener('focusout', event => this.unfocus())
  }

  /**
   * Configures and creates the toolbox for manipulating with columns
   *
   * @returns {Toolbox}
   */
  createColumnToolbox() {
    return new Toolbox({
      api: this.api,
      cssModifier: 'column',
      items: [
        {
          label: this.api.i18n.t('Add column to left'),
          icon: IconDirectionLeftDown,
          onClick: () => {
            this.addColumn(this.focusedColumn, true);
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Add column to right'),
          icon: IconDirectionRightDown,
          onClick: () => {
            this.addColumn(this.focusedColumn + 1, true);
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Split cell by columns'),
          icon: IconSplitColumns,
          hideIf: () => Number.parseInt(this.focusedCell.colSpan) < 2,
          onClick: () => {
            this.splitCellByCols(this.focusedCell)
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Merge cell to the left'),
          icon: IconMergeLeft,
          hideIf: () => this.focusedColumn === 0 || !this.horizontalMergingPossible(this.focusedRow, this.focusedColumn - 1, 1),
          onClick: () => {
            this.spanColumns(this.focusedRow, this.focusedColumn - 1, 1)
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Merge cell to the right'),
          icon: IconMergeRight,
          hideIf: () =>
            this.focusedColumn + Number.parseInt(this.focusedCell.colSpan) === this.size.cols || 
            !this.horizontalMergingPossible(this.focusedRow, this.focusedColumn, 1),
          onClick: () => {
            this.spanColumns(this.focusedRow, this.focusedColumn, 1)
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Delete column'),
          icon: IconCross,
          hideIf: () => {
            return this.numberOfColumns === 1;
          },
          confirmationRequired: true,
          onClick: () => {
            this.deleteColumn(this.focusedColumn);
            this.hideToolboxes();
          }
        }
      ],
      onOpen: () => {
        this.selectColumn();
        this.hideRowToolbox();
      },
      onClose: () => {
        this.unselectColumn();
      }
    });
  }

  /**
   * Configures and creates the toolbox for manipulating with rows
   *
   * @returns {Toolbox}
   */
  createRowToolbox() {
    const dummy = document.createElement('div')
    dummy.innerHTML = IconTableWithHeadings
    dummy.getElementsByTagName("svg")[0].setAttribute("style", "transform:rotate(180deg);")
    const IconTableWithFooter = dummy.innerHTML
    
    return new Toolbox({
      api: this.api,
      cssModifier: 'row',
      items: [
        {
          label: this.api.i18n.t('Add row above'),
          icon: IconDirectionUpRight,
          onClick: () => {
            this.addRow(this.focusedRow, true);
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Add row below'),
          icon: IconDirectionDownRight,
          onClick: () => {
            this.addRow(this.focusedRow + 1, true);
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Merge cell up'),
          icon: IconMergeUp,
          hideIf: () => this.focusedRow === 0 || !this.verticalMergingPossible(this.focusedColumn, this.focusedRow - 1, 1),
          onClick: () => {
            this.spanRows(this.focusedColumn, this.focusedRow - 1, 1)
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Merge cell down'),
          icon: IconMergeDown,
          hideIf: () =>
            this.focusedRow + Number.parseInt(this.focusedCell.rowSpan) === this.size.rows.total ||
            !this.verticalMergingPossible(this.focusedColumn, this.focusedRow, 1),
          onClick: () => {
            this.spanRows(this.focusedColumn, this.focusedRow, 1)
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Split cell by rows'),
          icon: IconSplitRows,
          hideIf: () => Number.parseInt(this.focusedCell.rowSpan) < 2,
          onClick: () => {
            this.splitCellByRows(this.focusedCell)
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Set header downto here'),
          icon: IconTableWithHeadings,
          hideIf: () => this.size.rows.thead - 1 === this.focusedRow,
          onClick: () => {
            this.setTableColontitles({thead: this.focusedRow + 1})
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Set footer from here'),
          icon: IconTableWithFooter,
          hideIf: () => this.size.rows.total - this.size.rows.tfoot - 1 === this.focusedRow,
          onClick: () => {
            this.setTableColontitles({tfoot: this.size.rows.total - this.focusedRow})
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Make plain row'),
          icon: IconTable,
          hideIf: () => this.size.rows.thead <= this.focusedRow,
          onClick: () => {
            this.setTableColontitles({plain: this.focusedRow})
            this.hideToolboxes();
          }
        },
        {
          label: this.api.i18n.t('Delete row'),
          icon: IconCross,
          hideIf: () => {
            return this.numberOfRows === 1 || 
              (this.numberOfRows === 2 && this.tunes.withFooter && this.tunes.withHeadings)
          },
          confirmationRequired: true,
          onClick: () => {
            this.deleteRow(this.selectedRow);
            this.hideToolboxes();
          }
        }
      ],
      onOpen: () => {
        this.selectRow();
        this.hideColumnToolbox();
      },
      onClose: () => {
        this.unselectRow();
      }
    });
  }

  /**
   * When you press enter it moves the cursor down to the next row
   * or creates it if the click occurred on the last one
   */
  moveCursorToNextRow() {
    if (this.focusedRow === this.size.rows.total - 1) {
      this.addRow();      
    }
    this.vTable[this.focusedRow + 1][0].focus()
    this.updateToolboxesPosition();
  }

  /**
   * Add column in table on index place
   * Add cells in each row
   *
   * @param {number} columnIndex - number in the array of columns, where new column to insert, -1 if insert at the end
   * @param {boolean} [setFocus] - pass true to focus the first cell
   */
  addColumn(columnIndex = null, setFocus = false) {
    let expandedCells = []
    
    if (this.selectedColumn === columnIndex) {
      this.selectedColumn += 1
    }
    
    var focusCell = null
    this.vTable.forEach((row, rowIndex) => {
      if (columnIndex !== null && row[columnIndex - 1] === row[columnIndex]) {
        row.splice(columnIndex, 0, null)
        if (!expandedCells.includes(row[columnIndex - 1])) {
          row[columnIndex - 1].colSpan = Number.parseInt(row[columnIndex - 1].colSpan) + 1
          expandedCells.push(row[columnIndex - 1])
        }
        row[columnIndex] = row[columnIndex - 1]
      } else {
        const newCell = this.createCell()
        if (row[columnIndex]) {
          row.splice(columnIndex, 0, newCell)
          const next = row.find((el, i) => i >= columnIndex + 1 && this.rowElements[rowIndex].contains(row[i]))
          this.rowElements[rowIndex].insertBefore(newCell, next)
        } else {
          row.push(newCell)
          this.rowElements[rowIndex].appendChild(newCell)
        }
      }
    })

    this.size.cols += 1
    
    if (setFocus) {
      this.vTable[this.focusedRow ?? 0][columnIndex ?? this.size.cols - 1].focus()
    }
    
    this.recalculateWidths()
    this.resetCellClasses()

    this.rerender()
  };

  /**
   * Add row in table on index place
   *
   * @param {number} index - number in the array of rows, where new column to insert, -1 if insert at the end
   * @param {boolean} [setFocus] - pass true to focus the inserted row
   * @returns {HTMLElement} row
   */
  addRow(index = null, setFocus = false, cols = this.size.cols, data = null, modifyVtable = true) {
    let rowEl = $.make('tr', [this.CSS.row]);

    if (modifyVtable) {
      let vRow = new Array(cols).fill(null)
      if (index !== null) {
        this.vTable.splice(index, 0, vRow)
        this.rowElements.splice(index, 0, rowEl)
      } else {
        this.vTable.push(vRow)
        this.rowElements.push(rowEl)
      }
    }

    if (index !== null) {
      this.tbody.insertBefore(rowEl, this.tbody.querySelector(`tr:nth-child(${index + 1})`))
      if (this.selectedRow === index) {
        this.selectedRow += 1
      }
    } else {
      this.tbody.appendChild(rowEl)
    }

    this.fillRow(rowEl, data, cols, index ?? this.size.rows.total);

    this.size.rows.total += 1
    
    if (index !== null && index < this.size.rows.thead) {
      this.setTableColontitles({thead: this.size.rows.thead + 1})
    }
    
    if ((index === null && this.size.rows.tfoot > 0) ||
        (index !== null && index > this.size.rows.total - this.size.rows.tfoot - 1)) {
          this.setTableColontitles({tfoot: this.size.rows.tfoot + 1})
    }
    
    if (setFocus) {
      rowEl.firstChild.focus()
    }

    if (modifyVtable) {
      this.recalculateWidths()
    }
    this.resetCellClasses()

    return rowEl;
  };

  
  /**
   * Delete a column by index
   *
   * @param {number} index
   */
  deleteColumn(index) {
    let shrinkedCells = []

    this.vTable.forEach((row, i) => {
      const hNeighbours = index === 0 ? [row[index + 1]] : [row[index - 1], row[index + 1]]
      const cell = row[index]
      hNeighbours.forEach((nb) => {
        if (cell == nb && !shrinkedCells.includes(nb)) {
          nb.colSpan = Number.parseInt(nb.colSpan) - 1
          shrinkedCells.push(nb)
        }
      })

      if (Number.parseInt(cell.colSpan) === 1 && !shrinkedCells.includes(cell) && !(index !== 0 && row[index - 1] === cell)) {
        cell.remove()
      }

      row.splice(index, 1)
    })

    this.size.cols -= 1
    
    this.unfocus()
  }

  /**
   * Delete a row by index
   *
   * @param {number} index
   */
  deleteRow(index) {
    let shrinkedCells = []
    let keepingSpanRoots = new Set()
    
    this.vTable[index].forEach((cell, j) => {
      const vNeighbours = index === 0 ? [this.vTable[index + 1]?.at(j)] : [this.vTable[index - 1]?.at(j), this.vTable[index + 1]?.at(j)]

      vNeighbours.forEach((nb) => {
        if (cell === nb && !shrinkedCells.includes(nb)) { 
          nb.rowSpan = Number.parseInt(nb.rowSpan) - 1
          shrinkedCells.push(nb)
        }
      })

      if (Number.parseInt(cell.rowSpan) > 1 && (index === 0 || this.vTable[index - 1]?.at(j) !== cell)) {
        keepingSpanRoots.add(cell)
      }
    })
    
    if (keepingSpanRoots.size > 0 && index !== this.size.rows.total - 1) {
      const newNextRow = [...(new Set(this.vTable[index + 1].filter((cell, k) =>
        this.vTable[index, k] !== cell || keepingSpanRoots.has(cell)
      )))]
      const newTr = $.make('tr')
      newNextRow.forEach((el) => newTr.appendChild(el))
      this.rowElements[index + 1].replaceWith(newTr)
      this.rowElements[index + 1] = newTr
    }

    this.rowElements[index].remove()
    
    this.vTable.splice(index, 1)
    this.rowElements.splice(index, 1)
    this.size.rows.total -= 1

    this.unfocus()
  }

  /**
   * Create a wrapper containing a table, toolboxes
   * and buttons for adding rows and columns
   *
   * @returns {HTMLElement} wrapper - where all buttons for a table and the table itself will be
   */
  createTableWrapper() {
    this.world = $.make('div');
    this.wrapper = $.make('div', this.CSS.wrapper);
    
    this.thead = $.make('table')
    this.tbody = $.make('table', [], {'table-layout': 'fixed'})
    //this.tbody.setAttribute('table-layout', "fixed")
    this.tfoot = $.make('table')

    if (this.readOnly) {
      this.wrapper.classList.add(this.CSS.wrapperReadOnly);
    }

    this.wrapper.appendChild(this.toolboxRow.element);
    this.wrapper.appendChild(this.toolboxColumn.element);
    this.wrapper.appendChild(this.tbody);

    if (!this.readOnly) {
      const addColumnButton = $.make('div', this.CSS.addColumn, {
        innerHTML: IconPlus
      });
      const addRowButton = $.make('div', this.CSS.addRow, {
        innerHTML: IconPlus
      });

      this.wrapper.appendChild(addColumnButton);
      this.wrapper.appendChild(addRowButton);
    }

    this.world.appendChild(this.wrapper)
    
    this.captionInput = $.make('div', [this.CSS.input, this.CSS.caption], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.caption?.content ?? this.data.caption ?? "",
    })
    this.captionInput.dataset.placeholder = this.api.i18n.t("Table caption")
    this.world.appendChild(this.captionInput)
  }

  rerender() {
    const old = this.world
    this.data = this.getData()
    this.createTableWrapper()
    this.init()
    old.replaceWith(this.world)
    if (!this.readOnly) {
      this.bindEvents();
    }
  }

  /**
   * Returns the size of the table based on initial data or config "size" property
   *
   * @return {{rows: number, cols: number}} - number of cols and rows
   */
  computeInitialSize() {
    const defaultRows = 3;
    const defaultCols = 3;

    var cols = Math.max(...(this.data?.content?.map((row) => row.reduce((a, el) => a += (Number.parseInt(el.colspan ?? 1)), 0)) || [-1]))
    
    if (cols <= 0) {
      cols = this.config.colsCount ?? defaultCols
    }

    return {
      rows: {
        thead: this.data?.thead ?? 0,
        tfoot: this.data?.tfoot ?? 0,
        total: this.data?.content?.length ?? this.config.rows ?? defaultRows
      },
      cols
    }
  }

  /**
   * Init table to match config size or transmitted data size
   *
   * @return {{rows: number, cols: number}} - number of cols and rows
   */
  init() {
    const size = this.computeInitialSize();
    this.size = {
      rows: {
        total: 0
      },
      cols: size.cols
    }

    this.vTable = []
    this.rowElements = []
    for (let i = 0; i < size.rows.total; i++) {
      const row = new Array(size.cols).fill(null)
      this.vTable.push(row)
    }

    for (let i = 0; i < size.rows.total; i++) {
      const row = this.addRow(null, false, size.cols, this.data?.content?.at(i), false);
      this.rowElements.push(row)
    }

    this.size = size
    this.recalculateWidths()
    this.resetCellClasses()
  }

  recalculateWidths() {
    this.vTable.forEach((row) => row.forEach((td) => td.style.width = `${100/this.size.cols}%`))
  }

  /**
   * Fills a row with cells
   *
   * @param {HTMLElement} row - row to fill
   * @param {number} numberOfColumns - how many cells should be in a row
   */
  fillRow(row, data, cols, rowIndex) {
    const realCols = data ? data.length : cols

    let classes = []
    if (rowIndex <= this.size.rows.thead - 1) {
      classes.push(this.CSS.cellHeading)
    } else if (rowIndex >= this.size.rows.total - this.size.rows.tfoot - 1) {
      classes.push(this.CSS.cellFooter)
    }
    
    let expandedCells = []
    for (let i = 0; i < realCols; i++) {
      if (!data && this.vTable[rowIndex - 1]?.at(i) && this.vTable[rowIndex + 1]?.at(i) && this.vTable[rowIndex - 1]?.at(i) === this.vTable[rowIndex + 1]?.at(i)) {
        this.vTable[rowIndex][i] = this.vTable[rowIndex - 1][i]
        if (!expandedCells.includes(this.vTable[rowIndex][i])) {
          this.vTable[rowIndex][i].rowSpan = Number.parseInt(this.vTable[rowIndex][i].rowSpan) + 1
          expandedCells.push(this.vTable[rowIndex][i])
        }
        continue
      }

      const newCell = this.createCell({
        colspan: data?.at(i)?.colspan ?? 1,
        rowspan: data?.at(i)?.rowspan ?? 1,
        classes,
      });
      
      if (data && data[i]) {
        newCell.innerHTML = typeof(data[i]) === 'string' ? data[i] : data[i].content ?? ''
      }
      row.appendChild(newCell);

      for (let k = rowIndex; k < rowIndex + newCell.rowSpan; k++) {
        let base = this.vTable[k].findIndex((el) => el === null)
        for(let j = 0; j < newCell.colSpan; j++) {
          this.vTable[k][j + base] = newCell
        }
      }
    }

    const firstNull = this.vTable[rowIndex].findIndex(cell => !cell)
    if (firstNull !== -1) {
      const newCell = this.createCell({
        colspan: cols - firstNull,
        rowspan: 1,
        classes,
      });
      row.appendChild(newCell);
      for (let k = firstNull; k < cols; k++) {
        this.vTable[rowIndex][k] = newCell
      }
    }
  }

  /**
   * Creating a cell element
   *
   * @return {Element}
   */
  createCell(options = {}) {
    const cell = $.make('td', [this.CSS.cell, ...(options.classes ?? [])], {
      contentEditable: !this.readOnly
    });
    cell.colSpan = options.colspan ?? 1
    cell.rowSpan = options.rowspan ?? 1
    return cell
  }

  /**
   * Is the column toolbox menu displayed or not
   *
   * @returns {boolean}
   */
  get isColumnMenuShowing() {
    return this.selectedColumn !== null;
  }

  /**
   * Is the row toolbox menu displayed or not
   *
   * @returns {boolean}
   */
  get isRowMenuShowing() {
    return this.selectedRow !== null;
  }

  /**
   * Set the coordinates of the cell that the focus has moved to
   *
   * @param {FocusEvent} event - focusin event
   */
  focusInTableListener(event) {
    this.getFocusedCell(event)
    
    this.hideToolboxes()
    this.updateToolboxesPosition();
  }

  /**
   * Prevents default Enter behaviors
   * Adds Shift+Enter processing
   *
   * @param {KeyboardEvent} event - keypress event
   */
  onKeyPressListener(event) {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        return true;
      }

      event.preventDefault()
      event.stopPropagation()
      this.moveCursorToNextRow();
    }

    return event.key !== 'Enter';
  };

  /**
   * Prevents tab keydown event from bubbling
   * so that it only works inside the table
   *
   * @param {KeyboardEvent} event - keydown event
   */
  onKeyDownListener(event) {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  }

  /**
   * Unselect row/column
   * Close toolbox menu
   * Hide toolboxes
   *
   * @returns {void}
   */
  hideToolboxes() {
    this.hideRowToolbox();
    this.hideColumnToolbox();
    this.updateToolboxesPosition();
  }

  /**
   * Unselect row, close toolbox
   *
   * @returns {void}
   */
  hideRowToolbox() {
    this.unselectRow();
    this.toolboxRow.hide();
  }
  /**
   * Unselect column, close toolbox
   *
   * @returns {void}
   */
  hideColumnToolbox() {
    this.unselectColumn();
    this.toolboxColumn.hide();
  }

  /**
   * Set the cursor focus to the focused cell
   *
   * @returns {void}
   */
  focusCell() {
    this.focusedCell?.focus();
  }

  unfocus() {
    this.focusedCell = null
    this.focusedRow = null
    this.focusedColumn = null
  }

  /**
   * Update toolboxes position
   *
   * @param {number} row - hovered row
   * @param {number} column - hovered column
   */
  updateToolboxesPosition(cell = this.focusedCell) {
    if (!this.isColumnMenuShowing && cell) {
      const tr = cell.parentElement
      this.toolboxColumn.show(() => {
        return {
          left: `${cell.getBoundingClientRect().left - tr.getBoundingClientRect().left + cell.clientWidth / (Number.parseInt(cell.getAttribute('colspan') ?? 1)) / 2}px`
        };
      });
    }

    if (!this.isRowMenuShowing && cell) {
      this.toolboxRow.show(() => {
        const tr = cell.parentElement

        return {
          top: `${cell.getBoundingClientRect().top - this.tbody.getBoundingClientRect().top + cell.clientHeight / (Number.parseInt(cell.getAttribute('rowspan') ?? 1)) / 2}px`
        };
      });
    }
  }

  /**
   * Add effect of a selected row
   *
   * @param {number} index
   */
  selectRow(index = this.focusedRow) {
    this.vTable[index].forEach((cell, j) => {
      if (cell !== this.vTable[index - 1]?.at(j)) {
        cell.classList.add(this.CSS.cellSelected)
      }
    })
    this.selectedRow = index
  }

  unselectRow() {
    if (this.selectedRow === null) return

    this.vTable[this.selectedRow]?.forEach((cell) => {
      cell.classList.remove(this.CSS.cellSelected)
    })
    this.selectedRow = null
    this.focusedCell?.focus()
  }

  /**
   * Add effect of a selected column
   *
   * @param {number} index
   */
  selectColumn(index = this.focusedColumn) {
    this.vTable.forEach((row) => {
      const cell = row[index]
      if (cell !== row[index - 1]) {
        row[index].classList.add(this.CSS.cellSelected)
      }
    })
    this.selectedColumn = index
  }

  /**
   * Remove effect of a selected column
   */
  unselectColumn() {
    if (this.selectedColumn === null) return

    this.vTable.forEach((row) => {
      const cell = row[this.selectedColumn]
      cell?.classList?.remove(this.CSS.cellSelected)
    })
    this.selectedColumn = null
    this.focusedCell?.focus()
  }

  splitCellByRows(mergedCell = this.focusedCell) {
    this.vTable.forEach((row, i) => {
      var newCell = null
      for (let j = 0; j < row.length; j += 1) {
        if (row[j] === mergedCell) {
          if (!newCell) {
            newCell = mergedCell.cloneNode()
            newCell.classList.remove(this.CSS.cellSelected)
            newCell.rowSpan = 1
            newCell.innerHTML = mergedCell.innerHTML
          }
          row[j] = newCell
        }
      }

      if (newCell) {
        const newNextRow = [...(new Set(row))]
        const newTr = $.make('tr')
        newNextRow.forEach((el) => newTr.appendChild(el))
        this.rowElements[i].replaceWith(newTr)
        this.rowElements[i] = newTr
      }
    })

    mergedCell.remove()
    if (mergedCell === this.focusedCell) {
      this.focusedCell = null
      this.focusedRow = null
      this.focusedColumn = null
    } else {
      this.focusedCell.focus()
    }
  }

  splitCellByCols(mergedCell = this.focusedCell) {
    this.vTable.forEach((row, i) => {
      const j = row.findIndex((cell) => cell === mergedCell)
      const cell = row[j]
      if (!cell) return

      for (let k = 0; k < Number.parseInt(cell.colSpan) - 1; k += 1) {
        const newCell = cell.cloneNode()
        newCell.colSpan = 1
        newCell.innerHTML = cell.innerHTML
        newCell.classList.remove(this.CSS.cellSelected)
        this.rowElements[i].insertBefore(newCell, cell)
        row[j + k] = newCell
      }
      cell.colSpan = 1
      cell.classList.remove(this.CSS.cellSelected)
    })
  }

  horizontalMergingPossible(rowIndex, from, count) {
    if (count <= 0) return false
    const cells = [...(new Set(this.vTable[rowIndex].filter((el, i) => i >= from)))].slice(0, count + 1)
    return (new Set(cells.map((c) => c.rowSpan)).size) === 1
  }

  spanColumns(rowIndex, from, count) {
    if (count <= 0) {
      console.error("Invalid colspan range")
      return
    }
    
    var cells = [...(new Set(this.vTable[rowIndex].filter((el, i) => i >= from)))].slice(0, count + 1)
    if ((new Set(cells.map((c) => c.rowSpan)).size) > 1) {
      console.error("Unable to horizontally merge different by rowspan cells")
      return
    }
    
    let content = cells.map((cell) => cell.innerHTML).join("<br/>")
    var [spanRoot, ...rest] = cells
    spanRoot.colSpan = cells.map((cell) => Number.parseInt(cell.colSpan)).reduce((accum, v) => accum + v, 0)
    spanRoot.innerHTML = content

    for (let i = from; i < this.size.cols; i += 1) {
      if (cells.includes(this.vTable[rowIndex][i])) {
        for (let j = 0; j < Number.parseInt(spanRoot.rowSpan); j += 1) {
          this.vTable[rowIndex + j][i] = spanRoot
        }
      }
    }
    rest.forEach((cell) => cell.remove())
  }

  verticalMergingPossible(columnIndex, from, count) {
    if (count <= 0) return false
    const cells = [...(new Set(this.vTable.filter((row, i) => i >= from).map((row) => row[columnIndex])))].slice(0, count + 1)
    return (new Set(cells.map((c) => c.colSpan)).size) === 1
  }

  spanRows(columnIndex, from, count) {
    if (count <= 0) {
      console.error("Invalid rowspan range")
      return
    }

    var cells = [...(new Set(this.vTable.filter((row, i) => i >= from).map((row) => row[columnIndex])))].slice(0, count + 1)
    if ((new Set(cells.map((c) => c.colSpan)).size) > 1) {
      console.error("Unable to vertically merge different by colspan cells")
      return
    }

    let content = cells.map((cell) => cell.innerHTML).join("<br/>")
    var [spanRoot, ...rest] = cells
    spanRoot.rowSpan = cells.map((cell) => Number.parseInt(cell.rowSpan)).reduce((accum, v) => accum + v, 0)
    spanRoot.innerHTML = content

    for (let i = from; i < this.size.rows.total; i += 1) {
      if (cells.includes(this.vTable[i][columnIndex])) {
        for (let j = 0; j < Number.parseInt(spanRoot.colSpan); j += 1) {
          this.vTable[i][columnIndex + j] = spanRoot
        }
      }
    }

    rest.forEach((cell) => cell.remove())
  }

  setTableColontitles(options) {
    if (options.thead !== null) {
      this.size.rows.thead = options.thead
    }
    if (options.tfoot !== null) {
      this.size.rows.tfoot = options.tfoot + options.thead < this.size.rows.total ? options.tfoot : this.size.rows.total - options.thead
    }
    if (options.plain !== null) {
      if (options.plain < this.size.rows.thead) {
        this.size.rows.thead = options.plain
      }
      if (options.plain >= this.size.rows.total - options.tfoot) {
        this.size.rows.tfoot = this.size.rows.total - options.plain - 1
      }
    }
    this.resetCellClasses()
  }

  resetCellClasses() {
    for (let i = this.size.rows.total - this.size.rows.tfoot - 1; i < this.size.rows.total; i += 1) {
      this.vTable[i].forEach((cell) => {
        cell.classList.remove(this.CSS.cellHeading)
        cell.classList.add(this.CSS.cellFooter)
      })
    }
    for (let i = this.size.rows.thead; i < this.size.rows.total - this.size.rows.tfoot - 1; i += 1) {
      this.vTable[i].forEach((cell) => {
        cell.classList.remove(this.CSS.cellHeading)
        cell.classList.remove(this.CSS.cellFooter)
      })
    }
    for (let i = 0; i < this.size.rows.thead; i += 1) {
      this.vTable[i].forEach((cell) => {
        cell.classList.add(this.CSS.cellHeading)
        cell.classList.remove(this.CSS.cellFooter)
      })
    }
  }

  /**
   * Calculates the row and column that the cursor is currently hovering over
   * The search was optimized from O(n) to O (log n) via bin search to reduce the number of calculations
   *
   * @param {Event} event - mousemove event
   * @returns hovered cell coordinates as an integer row and column
   */
  getHoveredCell(event) {
    let hoveredRow = this.hoveredRow;
    let hoveredColumn = this.hoveredColumn;
    const { clientX, clientY } = event

    var node = document.elementFromPoint(clientX, clientY)
    while(node && !(node.tagName === 'TD' && node.classList.contains(this.CSS.cell))) {
      node = node.parentElement
    }
    
    return node
  }

  getFocusedCell(event) {
    var node = event.target
    while(node && !(node.tagName === 'TD' && node.classList.contains(this.CSS.cell))) {
      node = node.parentElement
    }

    for (let i = 0; i < this.vTable.length; i++) {
      for (let j = 0; j < this.vTable[i].length; j++) {
        if (this.vTable[i][j] === node) {
          this.focusedCell = node
          this.focusedRow = i
          this.focusedColumn = j
          return
        }
      }
    }
    
    this.focusedCell = null
    this.focusedRow = null
    this.focusedColumn = null
  }

  /**
   * Collects data from cells into a two-dimensional array
   *
   * @returns {string[][]}
   */
  getData() {
    const content = this.rowElements.map((row) => {
      return Array.from(row.querySelectorAll('td')).map((cell) => {
        return {
          content: cell.innerHTML,
          rowspan: cell.rowSpan,
          colspan: cell.colSpan
        }
      })
    })

    return {
      content,
      thead: this.size.rows.thead,
      tfoot: this.size.rows.tfoot,
      caption: {
        content: this.captionInput.innerHTML
      }
    }
  }

  /**
   * Remove listeners on the document
   */
  destroy() {
    document.removeEventListener('click', this.documentClicked);
  }
}
