<template>
  <div v-if="bookmarks" class="context-menu" ref="menu">
    <div class="context-menu__bookmark me-nocontent" :class="{ 'has-bookmark': hasBookmark || confirmDel }" @click="openPopupBookmark">
      <div class="context-menu__bookmark-image">
        <fa-icon :icon="['fas', 'bookmark']" size="xl" color="#44B5BB" />
      </div>
    </div>
    
    <button
      class="context-menu__button me-nocontent"
      :class="{ 'click-button': showPopup }"
      type="button"
      @click="openPopup"
    >
      <div class="context-menu__button-image" :class="{'transtarent': showPopup }">
        <img :class="{'hidden': showPopup}" :src="imageUrl('menu.svg')"/>
        <img :class="{'hidden': !showPopup}" class="context-menu__btn-close" :src="imageUrl('close.svg')"/>
      </div>
    </button>

    <div class="context-menu__menu me-nocontent" @click.stop :class="{ 'open-menu': showPopup, 'position-up': positionUp }">
      <div class="context-menu__list" :class="{ 'position-up': positionUp }">
        <div class="context-menu__item" :class="{ 'hidden': isSuccessCoping,'error-navigator': errorNavigator}" @click="copyLink">{{ textMenuLink }}</div>

        <div class="context-menu__message-success" :class="{ 'success-coping': isSuccessCoping }">Ссылка скопирована</div>

        <input
          ref="input"
          type="text"
          @focus="focus"
          name="context-menu-link"
          :value="link"
          class="context-menu__input-link"
          :class="{ 'error-navigator': !errorNavigator }"
        />

        <div v-if="!confirmDel" class="context-menu__item context-menu__item-bookmark" :class="{ 'bookmark': hasBookmark }">
          <div @click="toggleBookmarks">{{ textMenuBookmark.added }}</div>
        </div>

        <div v-if="hasNoteInSummary(anchor) && type != 'heading'" class="context-menu__item" @click="deleteNoteInSummary(anchor)">Удалить из конспекта</div>
        <div v-if="hasNoteInSummary(anchor) && type === 'heading'" class="context-menu__item" @click="deleteSectionNotes(anchor)">Удалить раздел из конспекта</div>

        <div class="context-menu__item context-menu__item-bookmark" :class="{ 'bookmark': !hasBookmark, 'confirm-deletion': confirmDel }">
          <div v-if="!confirmDel" @click="toggleBookmarks">{{ textMenuBookmark.notAdded }}</div>

          <div class="context-menu__item-delete" :class="{  'confirm-deletion': confirmDel }" @click="deleteMainMenu">Кликните для удаления закладки</div>
        </div>

        <div v-if="!hasNoteInSummary(anchor) && type !== 'heading'" class="context-menu__item"  @click="selectContent(anchor, 'summary')">Добавить в конспект</div>
        <div v-if="!hasNoteInSummary(anchor) && type === 'heading'" class="context-menu__item"  @click="selectContent(anchor, 'summary')">Добавить заголовок в конспект</div>
        <div v-if="!hasNoteInSummary(anchor) && type === 'heading'" class="context-menu__item"  @click="selectSection(anchor, 'summary')">Добавить содержание раздела в конспект</div>

        <div v-if="!hasNoteInSummary(anchor)" class="context-menu__item context-menu__item-note" :class="{'note': acceptNote}" @click="selectContent(anchor, 'content')">{{ textMenuNote }}</div>
      </div>
    </div>

    <div v-if="showPopupForBookmark" class="context-menu__menu context-menu__menu-bookmark me-nocontent" @click.stop :class="{'position-up': positionUp }">
      <div class="context-menu__list" :class="{ 'position-up': positionUp }">
        <div v-if="!confirmDel" class="context-menu__item context-menu__item-bookmark" :class="{ 'bookmark': hasBookmark }">
          <div @click="confirmDel = true">{{ textMenuBookmark.added }}</div>
        </div>

        <div class="context-menu__item context-menu__item-bookmark" :class="{ 'bookmark': !hasBookmark, 'confirm-deletion': confirmDel }">
          <div class="context-menu__item-delete" :class="{  'confirm-deletion': confirmDel }" @click="deleteMenuBookmark">Подтвердите удаление закладки</div>
        </div>
      </div>
    </div>

    <div class="context-menu__slot" :class="{ 'active': showPopup || showPopupForBookmark }" ref="slot">
      <slot />
    </div>
  </div>

  <div v-if="isPopupDeleteOpen" class="context-menu__popup">
    <div class="context-menu__popup-content">
      <button type="button" class="info-popup__btn" @click="closePopupDeleteNote()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="20px" height="20px" fill="#A8234C" >
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
        </svg>
      </button>
      <p>Часть конспекта содержит заметку, заметка будет удалена. Подтвердить удаление</p>
      <div class="context-menu__delete-btns">
        <button class="context-menu__delete-btn" @click="deleteNote(currentNote, currentId)">Да</button>
        <button class="context-menu__delete-btn" @click="closePopupDeleteNote()">Нет</button>
      </div>
    </div>
  </div>
</template>

<script>
import ContentBookmarks from "../javascripts/content_bookmarks"
import ContentNotes from "../javascripts/content_notes"
import { getImageUrl } from "../javascripts/utils"

export default {
  data() {
    return {
      showPopup: false,
      showPopupForBookmark: false,
      showLink: false,
      menu: null,
      positionUp: false,
      menuItemLink: {
        heading: "Скопировать ссылку на раздел",
        paragraph: "Скопировать ссылку на абзац",
        table: "Скопировать ссылку на таблицу",
        list: "Скопировать ссылку на список",
        media: "Скопировать ссылку на файл",
      },
      menuItemNote: {
        paragraph: "Сделать заметку для абзаца",
        table: "Сделать заметку для таблицы",
        list: "Сделать заметку для списка",
      },
      menuItemBookmark: {
        heading: {
          added: "Убрать закладку на раздел",
          notAdded: "Поставить закладку на раздел",
        },
        paragraph: {
          added: "Убрать закладку на абзац",
          notAdded: "Поставить закладку на абзац",
        },
        table: {
          added: "Убрать закладку на твблицу",
          notAdded: "Поставить закладку на таблицу",
        },
        list: {
          added: "Убрать закладку на список",
          notAdded: "Поставить закладку на список",
        },
        media: {
          added: "Убрать закладку на файл",
          notAdded: "Поставить закладку на файл",
        },
      },
      baseUrl: window.location.origin,
      link: "",
      errorNavigator: false,
      isSuccessCoping: false,
      isBookmark: false,
      isCliked: false,
      confirmDel: false,
      loadedMenu: false,
      isPopupDeleteOpen: false,
      currentNote: null,
      currentId: null
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    hfurl: {
      type: String,
      required: true,
    },
    anchor: {
      type: String,
      required: true,
    },
    metaId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    bookmarks: {
      type: Set,
      required: true,
    },
    notes: {
      type: Array,
      required: true,
    }
  },
  emits: ['update', 'addBlockNote', 'addNoteInSummary',  'openMenu'],
  mounted() {
    document.addEventListener("click", this.handleClickOutside)
  },
  computed: {
    textMenuLink() {
      const text = this.menuItemLink[this.type]
      return text
    },
    acceptNote() {
      const accept = {
        paragraph: true,
        table: true,
        list: true,
        heading: false,
        media: false
      }
      
      return accept[this.type]
    },
    textMenuNote() {
      const text = this.menuItemNote[this.type]
      return text
    },
    hasBookmark() {
      if (!this.isCliked) {
        return (this.isBookmark = this.bookmarks?.has(this.anchor))
      } else {
        return this.isBookmark
      }
    },
    textMenuBookmark() {
      let text = {}
      text.added = this.menuItemBookmark[this.type].added
      text.notAdded = this.menuItemBookmark[this.type].notAdded
      return text
    },
  },
  methods: {
    selectContent(id, type) {
      const el = document.getElementById(id)
      const selection = window.getSelection()
      selection.removeAllRanges()
      const range = ContentNotes.rangeForElement(el)
      selection.addRange(range)
      if(type === 'content') {
        this.$emit('addBlockNote', id)
      } else {
        this.$emit('addNoteInSummary', id)
        this.eventBus.emit("toast-show", {
          severity: "success",
          message: "Добавлено в конспект",
        })
      }
     
      this.showPopup = false
    },
    selectSection(id) {
      let currentTag = document.getElementById(id).tagName
      let level = currentTag.slice(1, currentTag.length)
      let indexTitle = null
      let arContent = document.getElementsByClassName('me-content')
      let notesInSummary =  this.notes.filter(el => el.anchors.length === 1)
      let isFindNextTitle = false
      
      for(let i = 0; i < arContent.length; i++) {
        if(arContent[i].id === id) {
          indexTitle = i
        }
      }
      
      while(!isFindNextTitle) {
        if(arContent[indexTitle + 1].nodeName[0] != currentTag[0] || (arContent[indexTitle + 1].nodeName[0] ===  currentTag[0] && typeof parseInt(arContent[indexTitle + 1].nodeName.slice(1, arContent[indexTitle + 1].nodeName.length)) === 'number' && level < arContent[indexTitle + 1].nodeName.slice(1, arContent[indexTitle + 1].nodeName.length))) {
          let hasNote = false
          for(let i = 0; i < notesInSummary.length; i++) {
            if(notesInSummary[i].anchors[0].block_id === arContent[indexTitle + 1].id) {
              hasNote = true
            }
          }
          if(!hasNote) {
            this.selectContent(arContent[indexTitle + 1].id, 'summary')
          }
          
        } else {
          isFindNextTitle = true
        } 

        indexTitle ++
      }
    },
    hasNote(id) {
      let hasNote = false
      for(let i = 0; i < this.notes.length; i++) {
        let anchors = []
        this.notes[i].anchors.forEach(a => anchors.push(a.parent_id))
        this.notes[i].anchors.forEach(a => anchors.push(a.block_id))
        if(anchors.includes(id)) {
          hasNote = true
        }
      }
      return hasNote
    },
    hasNoteInSummary(id) {
      let hasNoteInSummary = false
      for(let i = 0; i < this.notes.length; i++) {
        let anchors = []
        this.notes[i].anchors.forEach(a => anchors.push(a.block_id))
        if(anchors.includes(id) && anchors.length === 1) {
          if(this.notes[i].attachments.length === 0) {
              hasNoteInSummary = true
          }  
        }
      }
      return hasNoteInSummary
    },
    deleteNoteInSummary(id) {
      this.currentNote = this.notes.find(n => n.anchors[0].block_id === id && n.anchors.length === 1)
      this.currentId = id
      if(this.currentNote.note_html) {
        this.isPopupDeleteOpen = true
      } else {
        this.deleteNote(this.currentNote, this.currentId)
      }
    },
    deleteSectionNotes(id) {
      this.deleteNoteInSummary(id)
      let notesInSummary = this.notes.filter( n => n.anchors.length === 1 && !n.note_html && n.attachments.length === 0)
      let currentTag = document.getElementById(id).tagName
      let indexTitle = null
      let arContent = document.getElementsByClassName('me-content')
      let isFindNextTitle = false
      
      for(let i = 0; i < arContent.length; i++) {
        if(arContent[i].id === id) {
          indexTitle = i
        }
      }
      
      while(!isFindNextTitle) {
        if(arContent[indexTitle + 1].nodeName != currentTag) {
          let hasNote = false
          for(let i = 0; i < notesInSummary.length; i++) {
            if(notesInSummary[i].anchors[0].block_id === arContent[indexTitle + 1].id) {
              hasNote = true
              this.deleteNote(notesInSummary[i], arContent[indexTitle + 1].id)
            }
          }
        } else {
          isFindNextTitle = true
        } 

        indexTitle ++
      }
    },
    deleteNote(note, id) {
      ContentNotes.deleteNote(note)
        .then(() => {
          this.eventBus.emit("toast-show", {
            severity: "success",
            message: "Удалено из конспекта",
          })
          let el = document.getElementById(id)
          ContentNotes.unmarkNote(note, this.notes, el)
          this.$emit("update")
          this.isPopupDeleteOpen = false
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
        })
    },
    toggleBookmarks() {
      this.isCliked = true
      if (!this.isBookmark) {
        this.isBookmark = !this.isBookmark
        ContentBookmarks.setBookmark(this.anchor, this.metaId)
          .then(() => {
            this.$emit("update")
            this.eventBus.emit("toast-show", {
              severity: "success",
              message: "Закладка создана",
            })
          })
          .catch((error) => {
            this.eventBus.emit("toast-show", {
              severity: "alert",
              message: "Ошибка создания закладки",
            })
          })
      } else {
        this.confirmDel = true
      }
    },
    delete() {
      ContentBookmarks.removeBookmark(this.anchor, this.metaId)
        .then(() => {
          this.$emit("update")
          this.eventBus.emit("toast-show", {
            severity: "info",
            message: "Закладка удалена",
          })
        })
        .catch((error) => {
          console.log(error)
          this.eventBus.emit("toast-show", {
            severity: "alert",
            message: "Ошибка удаления закладки",
          })
        })
    },
    deleteMainMenu() {
      this.confirmDel = false
      this.isBookmark = !this.isBookmark
      this.delete()
    },
    deleteMenuBookmark() {
      this.isCliked = true
      this.isBookmark = !this.isBookmark
      this.confirmDel = false
      this.showPopupForBookmark = false
      this.delete()
    },
    openPopup() {
      this.$emit('openMenu')
      this.showPopup = !this.showPopup
      if (this.showPopup) {
        this.showPopupForBookmark = false
        const position =
          document.documentElement.clientHeight -
          this.$refs.menu.getBoundingClientRect().top
        if (position < 200) {
          this.positionUp = true
        } else {
          this.positionUp = false
        }
      }
    },
    openPopupBookmark() {
      this.showPopupForBookmark = !this.showPopupForBookmark
      if (this.showPopupForBookmark) {
        this.showPopup = false
        const position =
          document.documentElement.clientHeight -
          this.$refs.menu.getBoundingClientRect().top
        if (position < 200) {
          this.positionUp = true
        } else {
          this.positionUp = false
        }
      }
    },
    handleClickOutside(event) {
      if(this.$refs.menu) {
        if (!this.$refs.menu.contains(event.target) || this.$refs.slot.contains(event.target)) {
          this.showPopup = false
          this.showPopupForBookmark = false
        }
      }
      if(event.target.classList[0] === 'context-menu__popup') {
        this.isPopupDeleteOpen = false
        this.currentNote = null
        this.currentId = null
      }
      this.link = ""
      this.errorNavigator = false
      this.isSuccessCoping = false
      this.showLink = false
      this.confirmDel = false
    },
    copyLink() {
      const link = this.baseUrl + this.hfurl + "#" + this.anchor
      this.link = link
      navigator.clipboard.writeText(link).then(
        () => {
          this.isSuccessCoping = true
          this.eventBus.emit("toast-show", {
            severity: "success",
            message: "Ссылка скопирована",
          })
        },
        (err) => {
          this.errorNavigator = true
          this.showLink = true
        }
      )
    },
    focus() {
      this.$refs.input.select()
    },
    closePopupDeleteNote() {
      this.isPopupDeleteOpen = false
    },
    imageUrl(path) {
      return getImageUrl(path)
    }
  },
}
</script>

<style lang="scss">
.context-menu {
  position: relative;

  &__bookmark {
    position: absolute;
    left: 100%;
    opacity: 0;
  }

  &__bookmark-image {
    padding: 3px;

    :hover {
      background: rgba(232, 232, 235, 0.49);
      border-radius: 6px;
    }
  }

  &__button {
    display: flex;
    height: 0px;
    opacity: 0;
    justify-content: center;
    align-content: center;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    position: sticky;
    top: 0;
    left: 100%;
    transition: all 0.3s ease;
  }

  &__button-image {
    background: #F7F9FB;
    border-radius: 4px;
    display: flex;
    height: 20px;
  }

  &:hover .context-menu__button {
    opacity: 1;
  }

  &__item {
    margin: 0;
    padding: 6px;
    font-size: 14px;
    background-color: #ffffff;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &__menu {
    display: none;
    width: 200px;
    height: 0px;
    top: 30px;
    left: 100%;
    z-index: 1;
    position: sticky;
  }

  &__list {
    position: absolute;
    height: auto;
    width: 300px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    border: 3px solid rgba(226, 226, 229, 0.2);
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 5px 5px 5px #f0f0f0;
  }

  &__item-bookmark {
    display: none;
  }

  &__input-link {
    padding: 6px;
    font-size: 14px;
    background-color: #ffffff;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
  }

  &__message-success {
    display: none;
    padding: 6px;
    font-size: 14px;
    background-color: #ffffff;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--ss-success-color);
  }

  &__slot {
    margin-right: 30px;
  }

  &__item-delete {
    display: none;
  }

  &__menu-bookmark {
    display: block;
  }

  &__item-note {
    display: none;
  }

  &__popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(205, 214, 219, 0.8);
    z-index: 10;
  }

  &__popup-content {
    position: fixed;
    top: 30%;
    left: 50%;
    background: #fff;
    z-index: 2;
    padding: 40px;
    transform: translate(-50%, -25%);
  }

  &__delete-btns {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__delete-btn {
    border: none;
    border-radius: 8px;
    margin: 10px;
    width: 100px;
    height: 40px;
    background: rgba(205, 214, 219, 0.8);

    &:hover {
      background: #8d8d8d;
      color: #fff;
    }
  }
}

.content-container__block:hover {
  background-color: #ffffff;
}

.open-menu {
  display: flex;
}

.click-button {
  opacity: 1;
}

.active {
  background: #e1f2ff;
}

.position-up {
  top: -240px;
}

.error-navigator {
  display: none;
}

.success-coping {
  display: flex;
}

.hidden {
  display: none;
}

.has-bookmark {
  opacity: 1;
}

.bookmark {
  display: flex;
}

.confirm-deletion {
  display: flex;
  background: var(--ss-error-color);
  color: #fff;
}

.context-menu__item.confirm-deletion:hover {
  background: var(--ss-error-color);
}

.note {
  display: flex;
}

.transtarent {
  background: none;
}
</style>
