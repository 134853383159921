/**
 * Build styles
 */
import './index.less';

import { IconListBulleted, IconListNumbered, IconWarning } from '@codexteam/icons'

import * as $ from '../../utils/dom'
import Grades from '../../utils/grades'
import throttled from '../../utils/throttled'
import Toolbox from '../../utils/toolbox'

/**
 * @typedef {object} ListData
 * @property {string} style - can be ordered or unordered
 * @property {Array} items - li elements
 */

/**
 * @typedef {object} ListConfig
 * @description Tool's config from Editor
 * @property {string} defaultStyle — ordered or unordered
 */

/**
 * List Tool for the Editor.js 2.0
 */
export default class List {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: IconListBulleted,
      title: 'List',
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - tool constructor options
   * @param {ListData} params.data - previously saved data
   * @param {object} params.config - user config for Tool
   * @param {object} params.api - Editor.js API
   * @param {boolean} params.readOnly - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    /**
     * HTML nodes
     *
     * @private
     */
    this._elements = {
      wrapper: null,
      list: null,
      explanation: null
    };

    this.api = api;
    this.readOnly = readOnly;

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
    }

    /**
     * Tool's data
     *
     * @type {ListData}
     */
    this._data = {
      style: 'unordered',
      explanation: null,
      items: [],
      grade: null,
    };

    this.data = data;

    this.grades = new Grades(api)
    this.listItemToolbox = this.createListItemToolbox()

    this.hoveredLi = null
  }

  bindEvents(el) {
    el.addEventListener('mousemove', throttled(150, (event) => this.onItemMouseMove(event)), { passive: true })
  }

  /**
   * Returns list tag with items
   *
   * @returns {Element}
   * @public
   */
  render() {
    this._elements.wrapper = $.make('div', [this.CSS.wrapper])
    this._elements.wrapper.appendChild(this.listItemToolbox.element)
    if (typeof this._data.explanation === 'string') {
      this._elements.explanation = $.make('span', [this.CSS.explanation])
      if (!this.readOnly) {
        this._elements.explanation.contentEditable = true;
        this._elements.explanation.addEventListener('keyup', this.onKeyUp);
      }
      this._elements.explanation.innerHTML = this._data.explanation
      this._elements.explanation.dataset.placeholder = 'Поясняющее предложение';

      if (this._data.grade) {
        const wrapper = this.grades.wrap(this._elements.explanation, this._data.grade)
        this._elements.wrapper.appendChild(wrapper)
        this.bindEvents(wrapper)
      } else {
        this._elements.wrapper.appendChild(this._elements.explanation)
        this.bindEvents(this._elements.explanation)
      }
    } else {
      this._elements.explanation = null
    }
    this._elements.list = this.makeMainTag(this._data.style)

    // fill with data
    
    if (this._data.items.length) {
      this._data.items.forEach((item) => {
        const li = $.make('li', this.CSS.item)
        if (item.grade) {
          const wrapper = this.grades.wrap(item.content, item.grade)
          li.appendChild(wrapper)
          li.dataset.meGrade = item.grade
        } else {
          li.innerHTML = item.content ?? item
        }
        
        this.bindEvents(li)
        this._elements.list.appendChild(li)
      });
    } else {
      const empty = $.make('li', this.CSS.item)
      this._elements.list.appendChild(empty);
      this.bindEvents(empty)
    }

    if (!this.readOnly) {
      // detect keydown on the last item to escape List
      this._elements.wrapper.addEventListener('keydown', (event) => {
        const [ENTER, BACKSPACE] = [13, 8]; // key codes

        switch (event.keyCode) {
          case ENTER:
            this.getOutofList(event);
            break;
          case BACKSPACE:
            this.backspace(event);
            break;
        }
      }, false);
    }
    this._elements.wrapper.appendChild(this._elements.list)

    return this._elements.wrapper
  }

  /**
   * @returns {ListData}
   * @public
   */
  save() {
    return this.data;
  }

  /**
   * Allow List Tool to be converted to/from other block
   *
   * @returns {{export: Function, import: Function}}
   */
  static get conversionConfig() {
    return {
      /**
       * To create exported string from list, concatenate items by dot-symbol.
       *
       * @param {ListData} data - list data to create a string from thats
       * @returns {string}
       */
      export: (data) => {
        return data.items.join('. ');
      },
      /**
       * To create a list from other block's string, just put it at the first item
       *
       * @param {string} string - string to create list tool data from that
       * @returns {ListData}
       */
      import: (string) => {
        return {
          items: [ string ],
          style: 'unordered',
        };
      },
    };
  }

  /**
   * Sanitizer rules
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      style: {},
      items: {
        br: true,
      },
    };
  }

  /**
   * Settings
   *
   * @public
   * @returns {Array}
   */
  renderSettings() {
    return [
      {
        label: this.api.i18n.t('Unordered'),
        icon: IconListBulleted,
        onActivate: () => this.toggleTune('unordered'),
        isActive: this._data.style === 'unordered',
        closeOnActivate: true,
      },
      {
        label: this.api.i18n.t('Ordered'),
        icon: IconListNumbered,
        onActivate: () => this.toggleTune('ordered'),
        isActive: this._data.style === 'ordered',
        closeOnActivate: true,
      },
      {
        label: this.api.i18n.t('Has Explanation'),
        icon: IconWarning,
        default: false,
        onActivate: () => { this.toggleExplanation() },
        isActive: typeof this._data.explanation === 'string',
        closeOnActivate: true,
      },
      ...this.grades.gradeSettings(this)
    ];

  }

  createListItemToolbox() {
    return new Toolbox({
      api: this.api,
      cssModifier: 'list-item',
      items: this.grades.gradeSettings(this),
    })
  }

  onItemMouseMove(event) {
    this.listItemToolbox.hide()
    
    this.hoveredLi = event.target
    while (this.hoveredLi && this.hoveredLi.tagName !== 'LI' && !this.hoveredLi.classList.contains(this.CSS.item)) {
      this.hoveredLi = this.hoveredLi.parentElement
    }
    if (!this.hoveredLi) return

    this.listItemToolbox.show(() => {
      return {
        top: `${this.hoveredLi.offsetTop + this.hoveredLi.clientHeight / 2}px`,
      }
    })
  }

  /**
   * On paste callback that is fired from Editor
   *
   * @param {PasteEvent} event - event with pasted data
   */
  onPaste(event) {
    const list = event.detail.data;

    this.data = this.pasteHandler(list);
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const textContent = e.target.textContent;

    if (textContent === '') {
      e.target.innerHTML = '';
    }
  }

  /**
   * List Tool on paste configuration
   *
   * @public
   */
  static get pasteConfig() {
    return {
      tags: ['OL', 'UL', 'LI'],
    };
  }

  /**
   * Creates main <ul> or <ol> tag depended on style
   *
   * @param {string} style - 'ordered' or 'unordered'
   * @returns {HTMLOListElement|HTMLUListElement}
   */
  makeMainTag(style) {
    const styleClass = style === 'ordered' ? this.CSS.wrapperOrdered : this.CSS.wrapperUnordered;
    const tag = style === 'ordered' ? 'ol' : 'ul';

    const add = typeof this._data.explanation === 'string' ? [this.CSS.hasExplanation] : []

    return $.make(tag, [this.CSS.baseBlock, this.CSS.listWrapper, styleClass, ...add], {
      contentEditable: !this.readOnly,
    });
  }

  get hasExplanation() {
    return typeof this.data.explanation === 'string'
  }

  /**
   * Toggles List style
   *
   * @param {string} style - 'ordered'|'unordered'
   */
  toggleTune(style = this._data.style) {
    this.data
    this._data.style = style
    this.rerender()
  }

  toggleExplanation() {
    if (this.hasExplanation) {
      this._data.items = [this.data.explanation, ...this.data.items]
      this._data.explanation = null
    } else {
      const items = this.data.items.length > 1 ? [...this._data.items] : [ ...this._data.items, '']
      const expl = items.shift()
      this._data.explanation = expl.content
      this._data.grade = expl.grade ?? null
      this._data.items = items
    } 
    
    this.rerender()
  }

  rerender() {
    const old = this._elements.wrapper
    const wrapper = this.render()
    old.replaceWith(wrapper)
  }

  setGrade(g) {
    if (this.hoveredLi) {
      if (g) {
        this.hoveredLi.dataset.meGrade = g
      } else {
        delete this.hoveredLi.dataset.meGrade
      }
      this.data
    } else {
      this._data.grade = g ?? null
      this.data
      if (this._elements.explanation) {
        if (g) {
          this._elements.explanation.dataset.meGrade = g
        } else {
          delete this._elements.explanation.dataset
        }
      }
    }
    this.listItemToolbox.hide()
    this.rerender()
  }

  /**
   * Styles
   *
   * @private
   */
  get CSS() {
    return {
      baseBlock: this.api.styles.block,
      listWrapper: 'cdx-list',
      wrapper: 'cdx-list-wrapper',
      wrapperOrdered: 'cdx-list--ordered',
      wrapperUnordered: 'cdx-list--unordered',
      item: 'cdx-list__item',
      explanation: 'ce-paragraph',
      block: this.api.styles.block,
      hasExplanation: 'cdx-list--has-explanation'
    };
  }

  /**
   * List data setter
   *
   * @param {ListData} listData
   */
  set data(listData) {
    if (!listData) {
      listData = {};
    }

    this._data.explanation = listData.explanation || null

    this._data.style = listData.style ?? 'unordered';
    this._data.items = listData.items ?? [];

    const oldView = this._elements.wrapper;

    if (oldView) {
      oldView.parentNode.replaceChild(this.render(), oldView);
    }
  }

  /**
   * Return List data
   *
   * @returns {ListData}
   */
  get data() {
    const items = this._elements.wrapper?.querySelectorAll(`.${this.CSS.item}`);

    if (items) {
      this._data.items = [];
      for (let i = 0; i < items.length; i++) {
        var content = null
        if (items[i].querySelector('span[data-me-service="grade"]')) {
          content = items[i].querySelector('span[data-me-service="content"]').innerHTML
        } else {
          content = items[i].innerHTML
        }

        this._data.items.push({
          content,
          grade: items[i].dataset.meGrade ?? null,
        });
      }
    }
    this._data.explanation = this._elements.explanation?.innerHTML ?? this._data.explanation ?? null

    return this._data;
  }

  /**
   * Returns current List item by the caret position
   *
   * @returns {Element}
   */
  get currentItem() {
    let currentNode = window.getSelection().anchorNode;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return currentNode.closest(`.${this.CSS.item}`);
  }

  /**
   * Get out from List Tool
   * by Enter on the empty last item
   *
   * @param {KeyboardEvent} event
   */
  getOutofList(event) {
    const items = this._elements.wrapper.querySelectorAll('.' + this.CSS.item);

    /**
     * Save the last one.
     */
    if (items.length < 2) {
      return;
    }

    const lastItem = items[items.length - 1];
    const currentItem = this.currentItem;

    /** Prevent Default li generation if item is empty */
    if (currentItem === lastItem && !lastItem.textContent.trim().length) {
      /** Insert New Block and set caret */
      currentItem.parentElement.removeChild(currentItem);
      this.api.blocks.insert();
      this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex());
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Handle backspace
   *
   * @param {KeyboardEvent} event
   */
  backspace(event) {
    const items = this._elements.wrapper.querySelectorAll('.' + this.CSS.item),
        firstItem = items[0];

    if (!firstItem) {
      return;
    }

    /**
     * Save the last one.
     */
    if (items.length < 2 && !firstItem.innerHTML.replace('<br>', ' ').trim()) {
      event.preventDefault();
    }
  }

  /**
   * Select LI content by CMD+A
   *
   * @param {KeyboardEvent} event
   */
  selectItem(event) {
    event.preventDefault();

    const selection = window.getSelection(),
        currentNode = selection.anchorNode.parentNode,
        currentItem = currentNode.closest('.' + this.CSS.item),
        range = new Range();

    range.selectNodeContents(currentItem);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * Handle UL, OL and LI tags paste and returns List data
   *
   * @param {HTMLUListElement|HTMLOListElement|HTMLLIElement} element
   * @returns {ListData}
   */
  pasteHandler(element) {
    const { tagName: tag } = element;
    let style;

    switch (tag) {
      case 'OL':
        style = 'ordered';
        break;
      case 'UL':
      case 'LI':
        style = 'unordered';
    }

    const data = {
      style,
      items: [],
    };

    if (tag === 'LI') {
      data.items = [ element.innerHTML ];
    } else {
      const items = Array.from(element.querySelectorAll('LI'));

      data.items = items
        .map((li) => li.innerHTML)
        .filter((item) => !!item.trim());
    }

    return data;
  }
}
