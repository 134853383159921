<template>
    <div v-if="isPopupOpen" class="popup">
        <div class="popup__content">
        <button type="button" class="popup__btn" @click="closePopup()">
            <img :src="imageUrl('close.svg')"/>
        </button>
        <slot/>
        </div>
    </div>
    
</template>

<script>
import { getImageUrl } from '../javascripts/utils'

export default {
    props: {
        isPopupOpen: {
            type: Boolean,
            default: false
        } 
    },
    emits: ['closePopup'],
    methods: {
        handleClickOutside(event) {
        if(event.target.classList[0] === 'ontology__popup') {
          this.isPopupOpen = false
        }
      },
      closePopup() {
        this.$emit('closePopup')
      },
      imageUrl(path) {
        return getImageUrl(path)
      }
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside)
    }
}
</script>

<style lang="scss">
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(205, 214, 219, 0.8);
    z-index: 10;
  

  &__content {
    position: fixed;
    top: 30%;
    left: 50%;
    background: #fff;
    z-index: 2;
    padding: 40px;
    transform: translate(-50%, -25%);
  }

  &__btn {
    border: none;
    background-color: transparent;
    color: #fff;
    position: absolute;
    right: 3%;
    top: 7%;
  }
}
</style>