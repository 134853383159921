import { createApp } from 'vue/dist/vue.esm-bundler'

import {createBootstrap} from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import '../stylesheets/containers.scss'

import '../stylesheets/application.scss'

import NewSearch from '../views/searches/new.vue'
import GuidelinesList from '../views/guidelines/index.vue'
import ContentChunksList from '../views/content_chunks/index.vue'

import DynamicContent from '../components/dynamic_content.vue'
import ContentViewer from '../components/content_viewer.vue'
import ContentChunkEditor from '../components/content_chunk_editor.vue'

import ToastList from '../components/notifications/toast_list.vue'

import OntologyView from '../views/ontologies/ontology.vue'

import TermEditor from '../views/terms/editor.vue'
import TermIndex from '../views/terms/index.vue'
import TermSearch from '../views/terms/search.vue'
import Term from '../views/terms/term.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { 
  faMagnifyingGlass, faIndent, faImages, faTable, faInfo, 
  faBrain, faCertificate, faComment, faFilePen, faNotesMedical, 
  faUserDoctor, faBookmark, faTriangleExclamation, faQuoteLeft,
  faExclamation, faArrowRightToBracket,
  faBook, faPills, faDisease,
  faSuperscript, faSubscript,
  faUpload, faPlusCircle, faCircleExclamation, faCodeCompare,
  faEdit, faPenToSquare, faNoteSticky, faTrashCan,
  faForward, faBackward,
  faMaximize, faMinimize, faCircleInfo
} from '@fortawesome/free-solid-svg-icons'
import {
  faBookmark as farBookmark,
  faFile as farFile,
  faFileAudio as farFileAudio,
  faFileVideo as farFileVideo
} from '@fortawesome/free-regular-svg-icons'
library.add(
  faMagnifyingGlass, faIndent, faImages, faTable, faInfo, 
  faBrain, faCertificate, faComment, faFilePen, faNotesMedical, faUserDoctor, 
  faBookmark, farBookmark, faTriangleExclamation, faQuoteLeft,
  faExclamation, faArrowRightToBracket,
  faBook, faPills, faDisease,
  faSuperscript, faSubscript,
  faUpload, faPenToSquare, faNoteSticky,
  faPlusCircle, faCircleExclamation, faCodeCompare,
  faEdit, faTrashCan,
  farFile, farFileAudio, farFileVideo,
  faForward, faBackward,
  faMaximize, faMinimize, faCircleInfo,
)

import mitt from 'mitt'

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp()
  const eventBus = mitt()
  app.config.globalProperties.eventBus = eventBus
  app
    .use(createBootstrap())
    .component('new-search', NewSearch)
    .component('guidelines-list', GuidelinesList)
    .component('content-chunks-list', ContentChunksList)
    .component('dynamic-content', DynamicContent)
    .component('content-viewer', ContentViewer)
    .component('fa-icon', FontAwesomeIcon)
    .component('fa-layers', FontAwesomeLayers)
    .component('fa-layers-text', FontAwesomeLayersText)
    .component('toast-list', ToastList)
    .component('content-chunk-editor', ContentChunkEditor)
    .component('ontology-view', OntologyView)
    .component('term-editor', TermEditor)
    .component('term-index', TermIndex)
    .component('term-search', TermSearch)
    .component('term', Term)
    .directive('inline-html', (el) => {
        if (!el) {
          return
        }
    
        const content = el.tagName === 'TEMPLATE' ? el.content : el
        if (content.children.length === 1) {
          [...el.attributes].forEach((attr) => content.firstChild.setAttribute(attr.name, attr.value))
        }
    
        if (el.tagName === 'TEMPLATE') {
          el.replaceWith(el.content)
        } else {
          el.replaceWith(...el.children)
        }
      })
    .mount("body");
})
