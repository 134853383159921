<template>
  <span class="me-grade me-nocontent" :data-grade="grade">
    <div v-if="icon === 'triangle-exclamation'" class="me-grade__wrap">
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="26" viewBox="0 0 34 26" fill="none">
        <path :fill="color" d="M15.3479 1.42124C16.1419 0.257563 17.8581 0.257562 18.6521 1.42124L33.0527 22.5266C33.9586 23.8542 33.0078 25.6538 31.4007 25.6538H2.59932C0.992167 25.6538 0.04143 23.8542 0.947257 22.5266L15.3479 1.42124Z"/>
      </svg>
      <p class="me-grade__text">{{ text }}</p>
    </div>
    
    <fa-layers v-else full-width class="me-2 fa-2x unselectable">
      <fa-icon :icon="['fas', icon]" :color="color" />
      <fa-layers-text class="fa-inverse prevent-select unselectable" style="font-weight:900" transform="shrink-9" :value="text" />
    </fa-layers>
  </span>
</template>

<script>
  import Grades from '../content_editor/utils/grades'
  
  export default {
    data() {
      var icon = 'triangle-exclamation'
      var text = this.grade
      if (this.grade === 'comments') {
        icon = 'comment'
        text = ''
      } else if (this.grade === 'nb') {
        icon = 'circle-exclamation'
        text = ''
      } else if (this.grade === 'diff') {
        icon = 'code-compare'
        text = ''
      }

      const g = new Grades()

      return {
        icon,
        text,
        color: g.evLevelColors[g.fixGrade(this.grade)].color
      }
    },
    props: {
      grade: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss">
  .me-grade {
    &__wrap {
      position: relative;
    }

    &__text {
      color: #fff;
      position: absolute;
      top: 9px;
      left: 9px;
      font-size: 12px;
    }
  }

</style>