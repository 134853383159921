<template>
    <div v-if="isOpen" class="table-of-contents__popup-container">
        <div class="table-of-contents" ref="index_tab" @click.stop >
            <div class="table-of-contents__header">
                <h3 class="table-of-contents__title">Оглавление</h3>
                
                <button type="button" class="table-of-contents__btn" @click="$emit('close')">
                    <img :src="imageUrl('close.svg')"/>
                </button>
            </div>
    
            <div class="table-of-contents__container">
                <div v-for="(title, num) in index" class="table-of-contents__list" :key="num">
                    <div class="table-of-contents__title-content">
                        <div class="table-of-contents__title-item" :style="caclMargin(title.level)" :ref="calcRefTitle(title)">
                            <a class="table-of-contents__content" @click=" $event.preventDefault(); navigateTo(title.id);" href="" v-html="title.title"></a>

                            <div class="table-of-contents__items" :ref="title.id">
                                <div class="table-of-contents__labels">
                                    <span v-for="cnt in title.level - 1" class="table-of-contents__item-title"></span>

                                    <div class="table-of-contents__icons">
                                        <img  v-if="hasNote(title.id)" :src="imageUrl('note-icon.svg')"/>

                                        <img v-if="isBookmarked(title.id)" :src="imageUrl('bookmark.svg')"/>
                                    </div>
                
                                    <button v-if="hasChildrenTitle(title.title, title.level) || getSectionBookmarks(title.id)[0]" class="table-of-contents__btn table-of-contents__btn-hide" @click="toggle(title.id, title.title, title.level, num)">
                                        <img class="table-of-contents__btn-plus" :src="imageUrl('plus.svg')"/>

                                        <img class="table-of-contents__btn-minus" :src="imageUrl('minus.svg')"/>
                                    </button>
                                </div> 
                                
                                <div v-for="b in getSectionBookmarks(title.id)" class="table-of-contents__item-content" :class="{'close': !isListOpen}">
                                    <span v-for="cnt in title.level" class=""></span>

                                    <a class="table-of-contents__content" @click="$event.preventDefault(); navigateTo(b.fragment_id)" href="#">
                                        <img :src="imageUrl('bookmark.svg')"/>
                                        {{ b.content_preview }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>
import ContentBookmarks from "../javascripts/content_bookmarks"
import { getImageUrl } from "../javascripts/utils"

export default {
    data() {
        return {
            isListOpen: true,
        }
    },
    props: {
        index: {
            type: Array,
            required: true,
        },
        bookmarks: {
            type: Set,
            required: true,
        },
        blocks: {
            type: Array,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true
        },
        meta: {
            type: Object,
            default: {}
        },
        notes: {
            type: Array, 
            required: true,
        }
    },
    methods: {
        calcRefTitle(el) {
            let strong = '<strong>'
            let substring = el.title.split(' ')[0].replace(strong,'')
            if(substring) {
                return substring
            } else {
                return ''
            }
        },
        hasChildrenTitle(title, level) {
            let strong = '<strong>'
            let substring = title.split(' ')[0].replace(strong,'')
            let hasChildrenTitle  = () => {
                const ar = this.index.filter(el =>  el.title.startsWith(substring ) === true && parseInt(el.title.split('.')[0]))
                if(ar.length > 0)  {
                    const has = ar.find( el => el.level === level + 1)
                    return !!has

                } else {
                    return false
                }   
           }
           return hasChildrenTitle()
        },
        caclMargin(level) {
            let margin = () => {
                if(level === 1) {
                    return 0
                } else {
                    return  level * 20
                }
            } 
            return `margin-left: ${margin()}px`
        },
        hasNote(id) {
            const sec = id.split("_")[0]
            let notesBlocks= []
            for (let i = 0; i < this.notes.length; i++) {
                this.notes[i].anchors.forEach(el => { notesBlocks.push(el.block_id.split("_")[0])}) 
            }
            if(notesBlocks.includes(sec)) {
                return true
            } else {
                return false
            }
        },
        toggle(id, title, level, num) {

            this.$refs[id][0].classList.toggle('hidden-list')
            let strong = '<strong>'
            let substring = title.split(' ')[0].replace(strong,'')
            let children = Object.keys(this.$refs).filter(el =>  el.startsWith(substring ) === true && parseInt(el.split('.')[0]))
            children.shift()
            children.forEach(el => {
                this.$refs[el][0].classList.toggle('hidden')
            })
        },
        isBookmarked (id) {
            return ContentBookmarks.isBookmarked(id, this.bookmarks)
        },        
        scrollIntoView(id, block = 'start') {
            const el = document.getElementById(id)
            if (el) {
                window.location.hash = id
                el.scrollIntoView({ 
                    behavior: 'smooth', 
                    block: block 
                })
            }
        },
        navigateTo(id, closeSidebar = true) {
            this.scrollIntoView(id)
            this.$emit('close')
        },
        getSectionBookmarks(id) {
            return ContentBookmarks.getSectionBookmarks(id, this.bookmarks, this.blocks)
        },
        handleClickOutside(event) {
            if(this.$refs.index_tab) {
                if (
                    !this.$refs.index_tab.contains(event.target)) {
                    this.$emit('close')
                }
            } 
        },
        imageUrl(path) {
            return getImageUrl(path)
        }
    },
    emits: ['close'],
    mounted() {
        document.addEventListener("click", this.handleClickOutside)
    },
    unmounted() {
        document.removeEventListener("click", this.handleClickOutside)
    }
}
</script>

<style lang="scss">
@import "../stylesheets/application.scss";

.margin {
    margin-left: v-bind(marginLeft);
}
.table-of-contents {
    position: fixed;
    top: 5%;
    left: 50%;
    background: #fff;
    width: 85%;
    z-index: 2;
    overflow-y: scroll;
    height: 90vh;
    padding: 20px;
    transform: translateX(-50%);
    border-radius: 8px;
    color: $secondary-color-1;
    padding-left: 50px;

    &__title {
        margin-left: 24px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    &__popup-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(205, 214, 219, 0.8);
        z-index: 4;
    }

    &__icon {
        width: 22px;
        height: 22px;
        border: 3px solid $primary-light-color-3;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__line-ver {
        width: 2px;
        background: $primary-light-color-3;
        height: 0px;
        border-radius: 2px;
        margin-left: 1px;
    }

    &__line-hor {
        width: 2px;
        height: 8px;
        background: $primary-light-color-3;
        transform: rotateZ(90deg);
        position: absolute;
        left: 45%;
        border-radius: 2px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__btn {
        width: 25px;
        height: 25px;
        border: none;
        background: transparent;
    }

    &__title-content {
        display: flex;
    }
    
    &__icons  {
        position: absolute;
        top: 0;
        left: -25px;
    }

    &__title-item {
        margin-left: 35px;
    }

    &__list {
        display: flex;
        position: relative;
        margin-right: 45px;
    }

    &__labels {
        display: flex;
        min-width: 50px;
        justify-content: flex-start;
    }

    &__content {
        display: block;
        color: $secondary-color-1;
        text-decoration: none;
        padding-left: 25px;
        margin-bottom: 16px;
    }

    &__icon {
        margin-right: 5px;
    }

    &__btn-hide {  
        margin-left: 35px;
        position: absolute;
        top: 0;
        left: 98%;
    }

    &__items {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
    }

    &__btn-plus {
        display: none;
    }
}

.hidden-list {
    height: 0px;
    overflow: hidden;

    .table-of-contents__btn-minus {
        display: none;
    }

    .table-of-contents__btn-plus {
        display: flex;
    }
}

.hidden {
    display: none;
}

.close {
    display: none;
}
</style>
