<template>
  <div ref='refImagePlaceholder' class="image__placeholder" :class="{ loaded, error, loading }"
    :style="{ height: `${size.height}px` }">
    <img
      v-if="!error"
      ref='refImage'
      class='image__item'
      :class="$props.class" 
      :width="size.width"
      :height="size.height"
      @click="onImageClick">
    <div id='error' v-if="error" class="p-3 text-danger fw-normal text-decoration-none fst-normal">
      Ошибка загрузки файла
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      loading: true,
      loaded: null,
      containerWidth: null
    }
  },
  mounted() {
    this.containerWidth = this.$refs.refImagePlaceholder.offsetWidth
    if (window.IntersectionObserver) {
      this.createObserver()
    } else {
      this.loadImage()
    }
  },
  computed: {
    size: function () {
      if (!this.height || !this.width) {
        return { width: this.width, height: this.height }
      }

      const ratio = this.width / this.height

      let nextWidth
      if (ratio < 1) {
        nextWidth = Math.min(2.5 * this.height, this.containerWidth) * 1.2 * ratio
      } else {
        nextWidth = Math.min(2.5 * this.width, this.containerWidth)
      }
      return { width: nextWidth, height: nextWidth / ratio }
    }
  },
  methods: {
    loadImage() {
      const imageElement = this.$refs.refImage
      if (imageElement) {
        this.loading = true
        imageElement.addEventListener('load', () => {
          this.loaded = true
          this.loading = false
          this.error = false
        })
        imageElement.addEventListener('error', () => {
          this.loaded = false
          this.loading = false
          this.error = true
        })

        imageElement.src = this.$props.src
      }
    },

    handleIntersect(entries, observer) {
      const el = this.$refs.refImagePlaceholder
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadImage()
          observer.unobserve(el)
        }
      })
    },

    createObserver() {
      const el = this.$refs.refImagePlaceholder
      const options = {
        root: null,
        threshold: '0',
        rootMargin: '200px',
      };
      const observer = new IntersectionObserver(this.handleIntersect, options);
      observer.observe(el);
    },

    onImageClick(event) {
      this.$emit('imageClicked', event)
    }
  },
  props: {
    width: Number,
    height: Number,
    class: String,
    src: {
      type: String,
      required: true
    },
  }
}

</script>
<style>
.image__placeholder img {
  height: auto;
  max-width: 100%;
}

.image__placeholder.loading {
  background-color: #eee;
}

.image__placeholder #error {
  display: none
}

.image__placeholder.error {
  background-color: #fee;
}

.image__placeholder.error #error {
  display: block;
}
</style>