<template>
  <div class="mb-2">
    <GradeBadge :grade="block.data.grade" v-if="block.data.grade"/>
    <span 
      :id="block.node_id" 
      :data-id="orderId()"
      :data-hash="block.hash" 
      :data-grade="block.data.grade"
      :class="calloutHash()"
      class="me-content paragraph"
      v-html="block.data.content"
    >
    </span>
  </div>
</template>

<script>
  import ContentNotes from '../../javascripts/content_notes'
  import GradeBadge from './grade_badge.vue'
  
  export default {
    props: {
      block: {
        type: Object,
        required: true
      }
    },
    components: {
      GradeBadge
    },
    methods: {
      calloutClass() {
        if (!this.block.data.grade) {
          return null
        }

        const evLevel = this.block.data.grade[0]
        return `callout-ev-level-${evLevel}`
      },
      calloutHash() {
        let cc = this.calloutClass()
        return (cc) ? {[cc]: true} : {}
      },
      orderId() {
        return this.block.node_id ? ContentNotes.orderId(this.block.node_id) : ''
      },
      calloutColor() {
        return {
          "callout-ev-level-1": "#5cb85c",
          "callout-ev-level-2": "#f0ad4e",
          "callout-ev-level-3": "#d38312",
          "callout-ev-level-4": "#538cc6",
          "callout-ev-level-5": "#29527a",
          "callout-ev-level-comments": "#777",
        }[this.calloutClass()]
      },
      pevLevelString() {
        if (this.block.data.grade !== 'comments') {
          return this.block.data.grade
        } else {
          return ""
        }
      }
    },
  }
</script>

<style lang="scss">
.paragraph {
  display: block;
  margin-bottom: 24px;
}

@media screen and (max-width: 1024px) {
  .paragraph {
    margin-bottom: 20px;
  }
}
</style>
