import { 
  content_notes_path, 
  content_note_path
} from '../../javascript/routes'
import axios from 'axios'
import { createAppComponent, createComponent } from '../javascripts/utils'
import Tooltip from '../components/tooltip.vue'
import ContentNoteTooltip from '../components/content_note_tooltip.vue'

export default class ContentNotes {
  static getNotes(content_or_id) {
    return axios
    .get(content_notes_path({
      content_id: content_or_id.id ?? content_or_id,
      format: 'json'
    }))
  }

  static rangeForElement(el) {
    var [first, last] = [null, null]
    let f = (el) => {
      if (el.nodeType === Node.TEXT_NODE && el.textContent.length > 0) {
        first = first ?? el
        last = el
      } else {
        el.childNodes.forEach((ch) => f(ch))
      }
    }
    el.childNodes.forEach((ch) => f(ch))
    const range = new Range()
    range.setStart(first, 0)
    range.setEnd(last, last.textContent.length)
    return range
  }

  static removeUselessMarkup(el, intersectedNotes = new Set()) {
    var marks = []
    let f = (el) => {
      if (el.nodeType === Node.COMMENT_NODE) {
        el.remove()
        return
      }
      
      if (el.classList?.contains('me-nocontent')) {
        el.remove()
        return
      }

      if (el.removeAttribute) {
        el.removeAttribute('id')
      }
      
      if (el.tagName === 'MARK') {
        if (el.classList.contains('mark-content-note') || el.classList.contains('mark-block-note')) {
          intersectedNotes.add(el.dataset.id)
          marks.push(el)
        } else {
          el.replaceWith(...el.childNodes)
        }
      }
      
      el.childNodes.forEach((ch) => f(ch))
    }
    el.childNodes.forEach((ch) => f(ch))
    marks.forEach(m => m.replaceWith(...m.childNodes))
    return { el, intersectedNotes }
  }

  static isReservedMarkup(el) {
    if (el.nodeType === Node.TEXT_NODE) {
      if (el.previousSibling || el.nextSibling) {
        return false
      } else {
        el = el.parentElement
      }
    } else if (el.tagName === 'MARK' && el.classList.contains('mark-content-note')) {
      return true
    }
    return !!['me-reference', 'me-toolpip', 'me-nocontent'].find((c) => el.classList?.contains(c))
  }

  static buildId(sec, p, sub, t, paramtrizedTitle) {
    var id = `sec${sec}`
    if (p && typeof p === 'number') {
      id += `_p${p}`
    }
    if (sub && typeof sub === 'number') {
      id += `_sub${sub}`
    }
    if (t && typeof t !== 'number') {
      id += `_t${t}`
    }
    if (paramtrizedTitle && paramtrizedTitle !== '') {
      id += `_${paramtrizedTitle}`
    }
    return id
  }

  static orderId(id) {
    const [sec, p, sub, t, paramtrizedTitle] = this.extractBlockOrder(id)
    return this.buildId(sec, p)
  }

  static selectionToNoteDetails(s) {
    var notable = true
    var bookmarkable = true
    
    const range = s.getRangeAt(0).cloneRange()

    var i = -1
    if (range.startContainer.nodeType === Node.TEXT_NODE && this.isReservedMarkup(range.startContainer)) {
      range.setStart(range.startContainer, 0)
    }
    if (range.endContainer.nodeType === Node.TEXT_NODE && this.isReservedMarkup(range.endContainer)) {
      range.setEnd(range.endContainer, range.endContainer.textContent.length)
    }
    
    if (range.startContainer.textContent.substring(range.startOffset, range.startOffset + 1) === ' ') {
      while (range.startContainer.textContent.substring(range.startOffset, range.startOffset + 1) === ' ') {
        range.setStart(range.startContainer, range.startOffset + 1)
      }
    } else if (range.startContainer.textContent[range.startOffset] === " ") {
      i = range.startContainer.textContent.substring(range.startOffset).search(/\S/)
      range.setStart(range.startContainer, range.startOffset + 1)
    } else if (range.startOffset !== range.startContainer.textContent.length) {
      i = range.startContainer.textContent.substring(0, range.startOffset).lastIndexOf(' ')
      if (i === -1) {
        range.setStart(range.startContainer, 0)
      } else {
        range.setStart(range.startContainer, i + 1)
      }
    }

    if (range.endContainer.textContent.substring(range.endOffset - 2, range.endOffset - 1) === ' ') {
      while (range.endContainer.textContent.substring(range.endOffset - 2, range.endOffset - 1) === ' ') {
        range.setEnd(range.endContainer, range.endOffset - 1)
      }
    } else if (range.endContainer.textContent[range.endOffset - 1] === " ") {
      i = range.endContainer.textContent.substring(0, range.endOffset).search(/\s+$/)
      range.setEnd(range.endContainer, i)
    } else if (range.endOffset !== 0) {
      i = range.endContainer.textContent.substring(range.endOffset).indexOf(' ')
      if (i == -1) {
        range.setEnd(range.endContainer, range.endContainer.textContent.length)
      } else {
        range.setEnd(range.endContainer, range.endOffset + i)
      }
    }

    var anchors = [[range.startContainer, range.startOffset], [range.endContainer, range.endOffset]].map((ary) => {
      let [n, offset] = ary
      let data = null
      if (n.nodeType === Node.TEXT_NODE) {
        let leftAnchor = n
        do {
          if (leftAnchor.previousSibling) {
            leftAnchor = leftAnchor.previousSibling
          } else if (leftAnchor.parentElement.tagName === 'MARK') {
            if (leftAnchor.parentElement.previousSibling) {
              leftAnchor = leftAnchor.parentElement.previousSibling
            } else {
              leftAnchor= leftAnchor.parentElement
              continue
            }
          } else {
            break
          }

          if (leftAnchor?.tagName === 'MARK') {
            leftAnchor = Array.from(leftAnchor.childNodes).at(-1)
          }

          if (leftAnchor?.classList?.contains('me-nocontent')) {
            continue
          }
          
          if (leftAnchor?.id) {
            break
          }
        
          let r = new Range()
          if (leftAnchor?.tagName === 'MARK') {
            r.selectNode(Array.from(leftAnchor.childNodes).find((n) => n.nodeType === Node.TEXT_NODE))
          } else {
            r.selectNode(leftAnchor)
          }
          offset += r.toString().length
        } while (true)
        data = {
          parent_id: leftAnchor?.id ? null : leftAnchor?.parentElement?.id,
          anchor_id: leftAnchor?.id || null,
        }
      } else {
        console.log('Unexpected selection range (maybe not an error):', range)
      }
      data.offset = offset
      
      const anyId = data.anchor_id || data.parent_id
      if (!anyId) {
        data.order = [-1]
        return data
      }
      const [sec, p, sub, t, paramtrizedTitle] = this.extractBlockOrderValues(data.anchor_id || data.parent_id)
      data.block_id = this.buildId(sec, p, undefined, undefined, paramtrizedTitle)
      data.order = [sec, p, sub, t, data.offset].map((s) => parseInt(s ?? 0))
      return data
    })
    console.log(anchors)

    const [a, b] = anchors
    if (a.order[0] != b.order[0]) {
      notable = false
      bookmarkable = false
    }
    if (a.order[1] === 0 || b.order[1] === 0) {
      notable = false
    }
    if (Math.abs(a.order[1] - b.order[1]) >= 3 || a.order[2] - b.order[2] >= 3) {
      notable = false
    }
    if (a.order[1] !== b.order[1] && a.order[2] !== b.order[2]) {
      bookmarkable = false
    }
    if (range.toString() === "" || range.toString().match(/^\s+$/)) {
      notable = false
      bookmarkable = false
    }

    if (notable) {
      let el = document.getElementById(anchors[0].block_id)
      let elRange = this.rangeForElement(el)
      if (elRange.endContainer === range.startContainer && elRange.endOffset === range.startOffset) {
        let next = document.querySelector(`[data-id="${this.buildId(anchors[0].order[0], anchors[0].order[1] + 1)}"]`)
        if (!next) {
          notable = false
          bookmarkable = false
        } else {
          let nextRange = this.rangeForElement(next)
          range.setStart(nextRange.startContainer, nextRange.startOffset)
          if (range.collapsed) {
            notable = false
            bookmarkable = false
          }
        }
      }
      if (notable) {
        el = document.getElementById(anchors[1].block_id)
        elRange = this.rangeForElement(el)
        if (elRange.startContainer === range.endContainer && elRange.startOffset === range.endOffset) {
          let prev = document.querySelector(`[data-id="${this.buildId(anchors[0].order[0], anchors[0].order[1] - 1)}"]`)
          if (!prev) {
            notable = false
            bookmarkable = false
          } else {
            let prevRange = this.rangeForElement(prev)
            range.setEnd(prevRange.endContainer, prevRange.endOffset)
            if (range.collapsed) {
              notable = false
              bookmarkable = false
            }
          }
        }
      }
    }

    if (a.block_id === b.block_id) {
      let el = document.getElementById(a.block_id)
      let elRange = this.rangeForElement(el)
      if (elRange.startContainer === range.startContainer && elRange.startOffset === range.startOffset &&
          elRange.endContainer === range.endContainer && elRange.endOffset === range.endOffset) {
        
        anchors = [{block_id: a.block_id, order: a.order}]
      }
    }

    var selectionHTML = notable ? this.reconstructPartialBlockSelection(range.cloneContents(), range) : null
    if (selectionHTML) {
      var { intersectedNotes } = this.removeUselessMarkup(selectionHTML)
      var overlyingNotes = this.getOverlyingNoteIdsForRange(range)
    }
    console.log(intersectedNotes, overlyingNotes)

    return { anchors, notable, bookmarkable, selectionHTML, range, intersectedNotes, overlyingNotes }
  }

  static getOverlyingNoteIdsForRange(range) {
    const overlyingNotes = new Set()
    let a = range.commonAncestorContainer
    do {
      if (a.tagName === 'MARK' && a.classList?.contains('mark-content-note')) {
        overlyingNotes.add(a.dataset.id)
      }
    } while (a = a.parentElement)
    return overlyingNotes
}

  static blockToNoteDetails(block_or_id) {
    const block_id = typeof block_or_id === 'string' ? block_or_id : block_or_id.id
    const el = typeof block_or_id === 'string' ? document.getElementById(block_id) : block_or_id
    const range = this.rangeForElement(el)
    const selectionHTML = document.createElement('DIV')
    selectionHTML.classList.add('selection-blockquote-part')
    if (el.dataset?.grade) {
      selectionHTML.dataset.grade = el.dataset?.grade
    }
    selectionHTML.innerHTML = el.innerHTML
    this.removeUselessMarkup(selectionHTML)
    const [sec, p, sub, t, paramtrizedTitle] = this.extractBlockOrder(block_id)
    const order = [sec, p, sub, t, 0].map((s) => parseInt(s))
    return { 
      anchors: [{ block_id, order }], 
      notable: true, 
      bookmarkable: true, 
      selectionHTML, 
      range
    }
  }

  static markupNote(note, allNotes, ranges = null) {
    if (!ranges) {
      var { ranges, reserved} = this.rangesForNoteAnchors(note.anchors)
      var overlyingNotes = ranges
        .map(r => this.getOverlyingNoteIdsForRange(r))
        .reduce((set, accum) => accum.union(set), new Set())
      overlyingNotes.forEach(id => this.unmarkNote({id}, allNotes))
      //retrying ranges because older ones may be invalid after unmarking
      var { ranges, reserved} = this.rangesForNoteAnchors(note.anchors)
    }
    //reserved.filter(n => n.tagName === 'MARK').forEach(n => n.classList.append('mark-content-note-inner'))
    ranges.forEach((range) => {
      const mark = document.createElement('MARK')
      mark.classList.add('mark-content-note')
      if (note.anchors.length === 1) {
        mark.classList.add('mark-block-note')
      }
      mark.dataset.id = note.id
      mark.appendChild(range.extractContents())
      range.insertNode(mark)
      if (mark.previousSibling?.textContent.length === 0) {
        mark.previousSibling.remove()
      }
      if (mark.nextSibling?.textContent.length === 0) {
        mark.nextSibling.remove()
      }
      const tooltipEl = document.createElement('SPAN')
      const selectorFocusElement = `.mark-content-note[data-id="${note.id}"]`
      const tooltip = createAppComponent(
        this.app,
        Tooltip, 
        tooltipEl,
        { 
          selectorFocusElement 
        }, 
        {
          default: () => createComponent(ContentNoteTooltip, { note, notes: allNotes })
        }
      )
      mark.appendChild(tooltipEl.firstChild)
    })

    overlyingNotes.forEach(id => this.markupNote(allNotes.find(n => n.id === id), allNotes))
  }

  static unmarkNote(note, allNotes, scope=document) {
    const overlyingNotesIds = new Set()
    scope.querySelectorAll(`.mark-content-note[data-id="${note.id}"]`).forEach(noteMark => {
      noteMark.querySelector('.my-tooltip')?.remove()
      if (noteMark.previousSibling?.classList?.contains('mark-block-note')) {
        overlyingNotesIds.add(noteMark.previousSibling.dataset.id)
      }
      if (noteMark.nextSibling?.classList?.contains('mark-block-note')) {
        overlyingNotesIds.add(noteMark.nextSibling.dataset.id)
      }
      let parent = noteMark.parentElement
      noteMark.replaceWith(...noteMark.childNodes)
      parent.normalize()
    })
    overlyingNotesIds.forEach(id => {
      this.unmarkNote({id}, allNotes)
      this.markupNote(allNotes.find(n => n.id === id), allNotes)
    })
  }

  static extractBlockOrderValues(id) {
    const [entire, sec, p, sub, t, paramtrizedTitle] = /^sec(\d+)(?:_p(\d+))?(?:_sub(\d+))?(?:_t(\d+))?(?:_(.*?))?$/.exec(id)
    return [...[sec, p, sub, t].map((v) => typeof v === 'undefined' ? v : parseInt(v)), paramtrizedTitle]
  }

  static extractBlockOrder(id) {
    const [sec, p, sub, t, paramtrizedTitle] = this.extractBlockOrderValues(id)
    return [...[sec, p, sub, t].map((v) => parseInt(v ?? "0")), paramtrizedTitle]
  }

  static rangesForNoteAnchors(anchors) {
    var [start, end] = [null, null]
    if (anchors.length === 1) {
      const range = this.rangeForElement(document.getElementById(anchors[0].block_id))
      start = [range.startContainer, range.startOffset]
      end = [range.endContainer, range.endOffset]
    } else {
      [start, end] = JSON.parse(JSON.stringify(anchors)).map((a, i) => {
        var node = null
        if (a.parent_id) {
          node = a.anchor_id ? document.getElementById(a.anchor_id).nextSibling : document.getElementById(a.parent_id).firstChild
          while (node.nodeType === Node.COMMENT_NODE) {
            node = node.nextSibling
          }
        } else {
          node = document.getElementById(a.anchor_id).nextSibling
        }

        let r = new Range()
        if (node.tagName === 'MARK') {
          r.selectNode(Array.from(node.childNodes).find((n) => n.nodeType === Node.TEXT_NODE))
        } else {
          r.selectNode(node)
        }
        
        while (i == 0 ? r.toString().length <= a.offset : r.toString().length < a.offset) {
          if (node.nodeType === Node.COMMENT_NODE) {
            continue
          }
          a.offset -= r.toString().length
          node = node.nextSibling
          r = new Range()
          if (node.tagName) {
            if (node.tagName === 'MARK') {
              r.selectNode(Array.from(node.childNodes).find((n) => n.nodeType === Node.TEXT_NODE))
            } else {
              console.error(`Unexpected node ${node.tagName} after incomplete text anchor`)
            }
          } else {
            r.selectNode(node)
          } 
        }

        if (node.nodeType !== Node.TEXT_NODE) {
          if (node.tagName === 'MARK') {
            node = node.firstChild
          } else {
            console.error("Unexpected anchor node:", node)
          }
        }

        return [node, a.offset]
      })
    }

    const blockData = {}
    var node = start[0]
    var seq = 0
    var [sec, p, sub, t, paramtrizedTitle] = this.extractBlockOrder(anchors[0].block_id)
    var prev = null
    var reserved = []
    do {
      let rangeId = null
      prev = node
      if (this.isReservedMarkup(node)) {
        reserved.push(node)
        seq += 1
      } else if (node.tagName === 'MARK' && node.classList.contains('mark-search-match')) {
        node = node.firstChild
        continue
      } else if (node.id) {
        [sec, p, sub, t, paramtrizedTitle] = this.extractBlockOrder(node.id)
        rangeId = `${sec}_${p}_${sub}_${seq}`
      } else if (node.nodeType === Node.TEXT_NODE) {
        rangeId = `${sec}_${p}_${sub}_${seq}`
//      } else if (node.tagName === 'MARK') {
//        rangeId = `${sec}_${p}_${sub}_${seq}`
      } else {
        seq += 1
      }
      
      if (!this.isReservedMarkup(node) && node.firstChild) {
        node = node.firstChild
        continue
      }

      if (rangeId && node.textContent.length > 0) {
        if (!blockData[rangeId]) {
          blockData[rangeId] = new Range()
          blockData[rangeId].setStart(node, node === start[0] ? start[1] : 0)
        }
        blockData[rangeId].setEnd(node, node === end[0] ? end[1] : node.textContent.length)
      }

      do {
        if (node.nextSibling) {
          node = node.nextSibling
          break
        } else {
          node = node.parentElement
          if (node?.id === anchors.at(-1).block_id) {
            node = null
          }
        }
      } while (node)
    } while (node && prev !== end[0])

    return { ranges: Object.values(blockData), reserved }
  }

  static reconstructPartialBlockSelection(fragment, originalRange=null) {
    const els = Array.from(fragment.childNodes)
    const tags = els.map((ch) => ch.tagName)
    const significantParents = [originalRange?.startContainer, originalRange?.endContainer].map((el) => {
      while (el && !(el.id && (el.classList?.contains('me-content') || ['LI'].includes(el.tagName)))) {
        el = el.parentElement
      }
      return el
    })
    var wrapper = null
    if (tags.includes('THEAD') || tags.includes('TBODY') || tags.includes('TFOOT') || tags.includes('CAPTION')) {
      let tableWrapper = document.createElement('TABLE')
      wrapper = document.createElement('DIV')
      wrapper.classList.add('selection-blockquote-part')
      tableWrapper.classList.add('table', 'table-striped', 'table-bordered')
      if (tags.includes('CAPTION')) {
        const captionEl = els.find((el) => el.tagName === 'CAPTION')
        const caption = document.getElementById(captionEl.id)
        tableWrapper.appendChild(caption.cloneNode(true))
      }
      if (tags.includes('THEAD')) {
        const theadEl = els.find((el) => el.tagName === 'THEAD')
        const thead = document.getElementById(theadEl.firstElementChild.firstElementChild.id).parentElement.parentElement
        tableWrapper.appendChild(thead.cloneNode(true))
      }
      if (tags.includes('TBODY')) {
        const tbody = document.createElement('TBODY')
        Array.from(els
          .find((el) => el.tagName === 'TBODY')
          .childNodes)
          .filter((n) => n.tagName === 'TR')
          .forEach((n) => tbody.appendChild(document.getElementById(n.firstElementChild.id).parentElement.cloneNode(true)))
          tableWrapper.appendChild(tbody)
      }
      if (tags.includes('TFOOT')) {
        const tfootEl = els.find((el) => el.tagName === 'TFOOT')
        const tfoot = document.getElementById(tfootEl.firstElementChild.firstElementChild.id).parentElement.parentElement
        tableWrapper.appendChild(tfoot.cloneNode(true))
      }
      wrapper.appendChild(tableWrapper)
    } else if (tags.includes('TR')) {
      const tbody = document.createElement('TBODY')
      let table = document.getElementById(els[0].firstElementChild.id).parentElement
      els.forEach((el) => tbody.appendChild(document.getElementById(el.firstElementChild.id).parentElement.cloneNode(true)))
      while (table.tagName !== 'TABLE') {
        table = table.parentElement
      }
      const thead = table.getElementsByTagName('THEAD')[0]
      table = document.getElementById(els[els.length - 1].firstElementChild.id).parentElement
      let tableWrapper = document.createElement('TABLE')
      wrapper = document.createElement('DIV')
      wrapper.classList.add('selection-blockquote-part')
      tableWrapper.classList.add('table', 'table-striped', 'table-bordered')
      if (thead) {
        tableWrapper.appendChild(thead.cloneNode(true))
      }
      tableWrapper.appendChild(tbody)
      wrapper.appendChild(tableWrapper)
    } else if (tags.includes('TD') || tags.includes('TH')) {
      var tr = document.getElementById(els[0].id).parentElement
      var table = tr.parentElement
      tr = tr.cloneNode(true)
      while (table.tagName !== 'TABLE') {
        table = table.parentElement
      }
      const thead = table.getElementsByTagName('THEAD')[0]
      const tbody = document.createElement('TBODY')
      tbody.appendChild(tr)
      let tableWrapper = document.createElement('TABLE')
      wrapper = document.createElement('DIV')
      wrapper.classList.add('selection-blockquote-part')
      tableWrapper.classList.add('table', 'table-striped', 'table-bordered')
      if (thead) {
        tableWrapper.appendChild(thead.cloneNode(true))
      }
      tableWrapper.appendChild(tbody)
      wrapper.appendChild(tableWrapper)
    } else if (tags.includes('LI')) {
      const list = document.getElementById(els[0].id).parentElement
      wrapper = document.createElement('DIV')
      wrapper.classList.add('selection-blockquote-part')
      if (list.previousElementSibling?.classList?.contains('dynamic-content-list-explanation')) {
        wrapper.appendChild(list.previousElementSibling.cloneNode(true))
      }
      if (list.tagName === 'OL') {
        const start = Array.from(list.getElementsByTagName('LI')).findIndex((li) => li.id === els[0].id) + 1
        const quoteList = document.createElement('OL')
        quoteList.setAttribute('start', start)
        els.forEach((el) => quoteList.appendChild(document.getElementById(el.id).cloneNode(true)))
        wrapper.appendChild(quoteList)
      } else {
        const quoteList = document.createElement('UL')
        els.forEach((el) => quoteList.appendChild(document.getElementById(el.id).cloneNode(true)))
        wrapper.appendChild(quoteList)
      }
    } else if (significantParents[0] === significantParents[1] && significantParents[0]?.tagName === 'LI') {
      const list = document.getElementById(significantParents[0].id).parentElement
      wrapper = document.createElement('DIV')
      wrapper.classList.add('selection-blockquote-part')
      if (list.previousElementSibling?.classList?.contains('dynamic-content-list-explanation')) {
        wrapper.appendChild(list.previousElementSibling.cloneNode(true))
      }
      if (list.tagName === 'OL') {
        const start = Array.from(list.getElementsByTagName('LI')).findIndex((li) => li.id === significantParents[0].id) + 1
        const quoteList = document.createElement('OL')
        quoteList.setAttribute('start', start)
        quoteList.appendChild(document.getElementById(significantParents[0].id).cloneNode(true))
        wrapper.appendChild(quoteList)
      } else {
        const quoteList = document.createElement('UL')
        quoteList.appendChild(document.getElementById(significantParents[0].id).cloneNode(true))
        wrapper.appendChild(quoteList)
      }
    } else if (tags.includes('UL') || tags.includes('OL') || tags.includes('TABLE') || tags.includes('DIV')) {
      wrapper = document.createElement('DIV')
      wrapper.classList.add('selection-blockquotes-collection')
      els.forEach((el) => {
        let f = new DocumentFragment()
        var grade = null
        if (el.tagName === 'DIV') {
          const block = el.getElementsByClassName('dynamic-content-block')[0]
          if (block) {
            grade = block.dataset.grade
            block.childNodes.forEach((n) => f.append(n.cloneNode(true)))
          } else {
            grade = el.dataset?.grade
            el.childNodes.forEach((n) => f.append(n.cloneNode(true)))
          }
        } else {
          grade = el.dataset?.grade
          el.childNodes.forEach((n) => f.append(n.cloneNode(true)))
        }
        const res = this.reconstructPartialBlockSelection(f)
        if (res) {
          if (grade) {
            res.dataset.grade = grade
          }
          wrapper.appendChild(res)
        }
      })
      els.filter((el) => el.tagName === 'OL' || el.tagName === 'UL')
    } else if (els.length === 0) {
      return null
    } else {
      wrapper = document.createElement('DIV')
      wrapper.classList.add('selection-blockquote-part')
      const grade = 
        els[0]?.dataset?.grade || 
        (significantParents[0] === significantParents[1] && significantParents[0]?.dataset?.grade)
      if (grade) {
        wrapper.dataset.grade = grade
      }
      wrapper.appendChild(fragment)
      if (originalRange) {
        let rootWrapper = document.createElement('DIV')
        rootWrapper.classList.add('selection-blockquotes-collection')
        rootWrapper.appendChild(wrapper)
        wrapper = rootWrapper
      }
    }
    return wrapper
  }

  static saveNote(data) {
    const content_note = JSON.parse(JSON.stringify(data))
    const csrfToken = document.getElementsByName("csrf-token")[0].content
    const method = content_note.id ? 'patch' : 'post'
    const url = content_note.id ? content_note_path(content_note.id, {format: 'json'}) : content_notes_path({format: 'json'})
    if (content_note.id) {
      delete content_note.id
    }
    return axios[method](
      url,
      { content_note, },
      {
        headers: {
          "X-CSRF-Token": csrfToken
        }
      },
    )
  }

  static app = null
  
  static set app(_app) {
    this.app = _app
  }

  static get app() {
    return this.app
  }

  static blockquoteParts(bq) {
    var f = null
    if (typeof bq === 'string') {
      const t = document.createElement('template')
      t.innerHTML = bq
      f = t.content
    } else {
      f = new DocumentFragment()
      f.append(bq)
    }
    return Array.from(f.querySelectorAll('.selection-blockquote-part'))
  }

  static deleteNote(content_note) {
    const csrfToken = document.getElementsByName("csrf-token")[0].content
    return axios.delete(
      content_note_path(content_note.id, { format: "json" }),
      {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
        validateStatus: (s => [204, 303, 404].includes(s))
      }
    )
  }
}