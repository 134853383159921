<template>
  <div>
    <b-modal 
      ref="locale-selector-modal" 
      title="Выбор новой локали" 
      no-trap
      @hidden="resetLocaleAddition"
      @ok="addLocale(localeToAdd)"
    >
      <SlimSelect 
        v-model="localeToAdd" 
        :settings="{placeholderText: 'Выберите локаль для добавления'}"
        :data="availableLocales"
      >
        <option data-placeholder="true"></option>
      </SlimSelect>
    </b-modal>

    <b-card no-body>
      <b-tabs card v-model="tabIndex">
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab v-for="t in term.locales" :key="t.locale" :title="t.locale.toUpperCase()">
          <b-modal 
            :ref="'definition-editor-modal-' + t.locale" 
            :title="`Редактор определения понятия в локали ${t.locale.toUpperCase()}`" 
            no-trap
            :scrollable="true"
            @ok="acceptDefinitionContentChange(t.locale)"
            @cancel="rejectDefinitionContentChange(t.locale)"
            size="xl"
          >
            <ContentEditor :content="definitionFor(t.locale)" :ref="'editor-' + t.locale" />
          </b-modal>
          <b-row>
            <b-col>
              <b-form-group label="Определение понятия">
                <div v-if="definitionFor(t.locale)">
                  <dynamic-content :content="definitionFor(t.locale)" :has-context-menu="false"></dynamic-content>
                </div>
                <b-button variant="secondary" @click="openDefinitionEditor(t.locale)">
                  Открыть редактор
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-form-group label="Основной термин">
                <b-form-input v-model="t.denotation"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Аббревиатуры основного термина">
                <b-row>
                  <b-col cols="auto" v-for="(abbr, i) in t.denotation_abbreviations">
                    <b-input-group>
                      <b-form-input v-model="t.denotation_abbreviations[i]"></b-form-input>
                      <b-button variant="outline-danger" @click="removeFromArrayAccum(t.denotation_abbreviations, i)">X</b-button>
                    </b-input-group>
                  </b-col>
                  <b-col cols="auto" align-self="center">
                    <b-button variant="secondary" @click="addAbbreviationTo(t.denotation_abbreviations)">+</b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-form-group label="Синонимы">
                <b-row v-for="(s, i) in t.synonyms" :key="s" class="mt-1">
                  <b-col>
                    <b-form-group label="Термин">
                      <b-form-input v-model="s.term"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Пояснение">
                      <b-form-input v-model="s.note"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Аббревиатуры синонима">
                      <b-row>
                        <b-col cols="auto" v-for="(abbr, i) in s.abbreviations">
                          <b-input-group>
                            <b-form-input v-model="s.abbreviations[i]"></b-form-input>
                            <b-button variant="outline-danger" @click="removeFromArrayAccum(s.abbreviations, i)">X</b-button>
                          </b-input-group>
                        </b-col>
                        <b-col cols="auto" align-self="center">
                          <b-button variant="secondary" @click="addAbbreviationTo(s.abbreviations)">+</b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col align-self="end" cols="auto">
                    <b-button class="float-right" variant="outline-danger" @click="removeFromArrayAccum(t.synonyms, i)">
                      Удалить
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <b-button size="sm" variant="secondary" @click="addSynonymTo(t)">
                      Добавить синоним
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-button 
                size="sm" 
                variant="danger" 
                class="float-right" 
                @click="closeTab(t.locale)"
              >
                Удалить локализацию {{ t.locale.toUpperCase() }} полностью
              </b-button>
            </b-col>
          </b-row>
        </b-tab>

        <!-- New Tab Button (Using tabs-end slot) -->
        <template #tabs-end>
          <b-nav-item role="presentation" @click.prevent="newTabPrompt" href="#"><b>+</b></b-nav-item>
        </template>

        <!-- Render this if no tabs -->
        <template #empty>
          <div class="text-center text-muted">
            There are no open tabs<br>
            Open a new tab using the <b>+</b> button above.
          </div>
        </template>
      </b-tabs>
    </b-card>

    <b-card class="mt-3" title="Ссылки">
      <b-row class="mt-1" v-for="l in term.links">
        <b-col>
          <b-form-group label="URL">
            <b-form-input v-model="l.uri"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Описание">
            <b-form-input v-model="l.description"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="float-right" variant="outline-danger" @click="removeFromArrayAccum(term.links, i)">
            Удалить
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button size="sm" variant="secondary" @click="addLink">
            Добавить ссылку
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-modal 
      ref="related-term-modal" 
      title="Поиск терминов для связи" 
      no-trap
      @hidden="resetTermsForRelation"
      @ok="addTermsForRelation"
      size="xl"
    >
      <term-search>
        <template #default="{ terms }">
          <table class="table table-borderless">
            <tr v-for="el in terms" :class="{'table-active': hasRelatedTerm(el.term)}">
              <td>
                <b-form-checkbox 
                  v-model="termsForRelation[el.term.id]"
                  :value="el.term"
                ></b-form-checkbox>
              </td>
              <td>
                <Term :term="el.term" />
              </td>
            </tr>
          </table>
          <b-form-group class="mt-3">
            <b-button variant="info" @click="markForRelation(terms)">Все</b-button>
            <b-button variant="secondary" class="ms-3" @click="resetTermsForRelation">Ни одного</b-button>
          </b-form-group>
          <!--b-form-group class="mt-3">
            <b-form-input v-model="termsRelationComment" placeholder="Комментарий к связи"></b-form-input>
          </b-form-group-->
        </template>
      </term-search>
    </b-modal>
    <b-card class="mt-3" title="Связанные понятия">
      <b-row class="mt-1" v-for="rt in term.related_terms">
        <b-col>
          <Term :term="rt" :show-related="false"/>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="float-right" variant="outline-danger" @click="removeRelatedTerm(rt)">
            Удалить
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button size="sm" variant="secondary" @click="chooseRelatedTerm">
            Добавить связанное понятие
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-alert variant="danger" :model-value="!!error" class="mt-3">
      <b-badge variant="danger">{{ error.response.status }}</b-badge>
      {{ error.response.data }}
    </b-alert>

    <div class="mt-3">
      <b-button
        v-if="term.id"
        class="me-3"
        variant="danger"
        @click="deleteTerm"
      >Удалить</b-button>
      <b-button
        variant="primary"
        @click="saveTerm"
      >Сохранить</b-button>
    </div>
  </div>
</template>

<script>
  import SlimSelect from '@slim-select/vue'
  import '@vueup/vue-quill/dist/vue-quill.snow.css'
  import { terms_path, term_path, content_chunk_path } from '../../../javascript/routes'
  import axios from 'axios'
  import ContentEditor from '../../components/content_editor.vue'
  import Term from '../terms/term.vue'

  export default {
    props: {
      allLocales: {
        type: Object,
        required: true
      },
      initialTerm: {
        type: Object,
        required: true
      }
    },
    data() {
      let term = JSON.parse(JSON.stringify(this.initialTerm))
      if (!term.locales || term.locales.length === 0) {
        term.locales = [{
          locale: 'ru',
          denotation: '',
          denotation_abbreviations: [],
          synonyms: []
        }]
        term.term_definitions_attributes = [{
          locale: 'ru',
          content_chunk_attributes: {
            content: {
              blocks: []
            }
          }
        }]
      }
      if (!term.links) {
        term.links = []
      }
      if (!term.related_terms) {
        term.related_terms = []
      }
      return {
        localeToAdd: null,
        term,
        tabIndex: 0,
        saving: false,
        error: null,
        termsForRelation: {},
        termsRelationComment: ''
      }
    },
    components: {
      SlimSelect,
      ContentEditor,
      Term
    },
    methods: {
      closeTab(x) {
        for (let i = 0; i < this.term.locales.length; i++) {
          if (this.term.locales[i].locale === x) {
            this.term.locales.splice(i, 1)
            break
          }
        }
      },
      newTabPrompt() {
        this.$refs['locale-selector-modal'].show()
        this.localeToAdd = this.availableLocales[0].value
        //this.terms.locales.push(this.tabCounter++)
      },
      resetLocaleAddition() {
        this.localeToAdd = null
      },
      addLocale() {
        if (!this.localeToAdd) {
          return
        }
        this.term.locales.push({
          locale: this.localeToAdd,
          denotation: '',
          denotation_abbreviations: [],
          synonyms: []
        })
        this.term.term_definitions_attributes.push({
          locale: this.localeToAdd,
          content_chunk_attributes: {
            content: {
              blocks: []
            }
          }
        })
        this.localeToAdd = null

        this.$nextTick(() => {
          this.tabIndex = this.term.locales.length - 1
        })
      },
      addSynonymTo(t) {
        t.synonyms.push({
          term: '',
          abbreviations: []
        })
      },
      addLink() {
        this.term.links.push({
          uri: null,
          description: null
        })
      },
      addAbbreviationTo(accum) {
        accum.push("")
      },
      removeFromArrayAccum(accum, i) {
        accum.splice(i, 1)
      },
      openDefinitionEditor(locale) {
        this.$refs['definition-editor-modal-' + locale][0].show()
      },
      definitionFor(locale) {
        return this.term.term_definitions_attributes.find(td => td.locale === locale).content_chunk_attributes.content
      },
      acceptDefinitionContentChange(locale) {
        this.$refs['editor-' + locale][0]
          .getContent()
          .then(content => {
            this.term.term_definitions_attributes.find(l => l.locale === locale).content_chunk_attributes.content = content
          })
      },
      rejectDefinitionContentChange(locale) {
        this.$refs['editor-' + locale][0].setContent(this.definitionFor(locale))
      },
      acceptDefinitionTeaserContentChange() {
      },
      chooseRelatedTerm() {
        this.$refs['related-term-modal'].show()
      },
      hasRelatedTerm(_t) {
        return this.term.related_terms.find(t => t.id === _t.id) || this.termsForRelation[_t.id]
      },
      markForRelation(list) {
        list.forEach(t => this.termsForRelation[t.term.id] = t.term)
      },
      resetTermsForRelation() {
        this.termsForRelation = {}
      },
      addTermsForRelation() {
        this.term.related_terms = Object.values(this.termsForRelation)
        this.resetTermsForRelation()
      },
      removeRelatedTerm(rt) {
        this.term.related_terms = this.term.related_terms.filter(t => t.id !== rt.id)
      },
      saveTerm() {
        this.saving = true
        this.error = null
        const term = JSON.parse(JSON.stringify(this.term))
        const csrfToken = document.getElementsByName("csrf-token")[0].content
        const method = term.id ? 'patch' : 'post'
        const url = term.id ? term_path(term.id, {format: 'json'}) : terms_path({format: 'json'})
        let persisted = !!term.id
        delete term.id
        delete term.created_at
        delete term.updated_at
        term.related_term_ids = term.related_terms.map(t => t.id)
        delete term.related_terms
        
        axios[method](
          url,
          { term },
          {
            headers: {
              "X-CSRF-Token": csrfToken
            }
          }
        ).then((resp) => {
          if (persisted) {
            this.eventBus.emit('toast-show', {
              severity: 'success',
              message: 'Данные сохранены',
              delay: 5000
            })
            this.term = resp.data
          } else {
            window.location = terms_path()
          }
        }).catch((error) => {
          this.error = error
        }).finally(() => {
          this.saving = false
        })
      },
      deleteTerm() {
        this.saving = true
        this.error = null
        const csrfToken = document.getElementsByName("csrf-token")[0].content
        
        axios.delete(
          term_path(this.term.id, {format: 'json'}),
          {
            headers: {
              "X-CSRF-Token": csrfToken
            }
          }
        ).then((resp) => {
          window.location = terms_path()
        }).catch((error) => {
          this.error = error
        }).finally(() => {
          this.saving = false
        })
      }
    },
    computed: {
      availableLocales() {
        const currentLocales = this.term.locales.map(l => l.locale)
        return this.allLocales
          .filter(l => !currentLocales.includes(l[0]))
          .map(l => {
            return {
              value: l[0],
              text: `[${l[0].toUpperCase()}] ${l[1]}`
            }
          })
      }
    }
  }
</script>