<template>
  <div>
    <component
      :is="`h${levelHTML}`"
      :id="block.node_id"
      :data-id="orderId"
      :data-hash="block.hash"
      v-html="block.data.content"
      class="heading me-content"
      :class="{'has-note': $attrs.hasNote, 'has-search-match': block.data.has_implicit_match && !block.data.has_search_match}"
    >
    </component>
  </div>
</template>

<script>
  import ContentNotes from '../../javascripts/content_notes'
  
  export default {
    props: {
      block: {
        type: Object,
        required: true
      }
    },
    computed: {
      levelHTML() {
        const level = Math.round(this.block.data.level)
        if (level <= 0) {
          return 1
        } else if (level > 6) {
          return 6
        } else {
          return level
        }
      },
      orderId() {
        return this.block.node_id ? ContentNotes.orderId(this.block.node_id) : ''
      },
    },
  }
</script>

<style scss>
  .has-search-match {
    text-decoration: underline;
    text-decoration-color: rgb(146, 215, 190);
  }

  .heading {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;

    h1 {
      font-size: 32px;
    }
  }

  @media screen and (max-width: 1024px) {
    .heading  {
      font-size: 20px;
      margin-bottom: 8px;

        h1 {
        font-size: 28px;
      }
    }
  }
</style>
