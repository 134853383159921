import Sortable from 'sortablejs';

import buttonIcon from './svg/button-icon.svg?raw';
import trashIcon from './svg/trash.svg?raw';

import { rails_service_blob_path } from '../../../../../javascript/routes';

import { h, render } from 'vue/dist/vue.esm-bundler'
import Progressbar from '../../../progressbar.vue'

import { make } from '../../utils/dom'

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class Ui {
  /**
   * @param {object} ui - image tool Ui module
   * @param {object} ui.api - Editor.js API
   * @param {ImageConfig} ui.config - user config
   * @param {Function} ui.onSelectFile - callback for clicks on Select file button
   * @param {boolean} ui.readOnly - read-only mode flag
   */
  constructor({ api, config, onSelectFile, onDeleteFile, onMoveFile, readOnly }) {
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.onDeleteFile = onDeleteFile;
    this.onMoveFile = onMoveFile;
    this.readOnly = readOnly;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      fileButton: this.createFileButton(),
      container: make('div', this.CSS.container),
      itemsContainer: make('div', this.CSS.itemsContainer),
      controls: make('div', this.CSS.controls),
      preloaderContainer: make('div', this.CSS.preloaderContainer),
      caption: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: !this.readOnly,
      }),
    };

    /**
     * Create base structure
     *  <wrapper>
     *    <container>
     *      <items-container>
     *        <image-container />
     *      </items-container>
     *      <controls>
     *        <preloader-container />
     *        <limit-counter />
     *        <select-file-button />
     *      </controls>
     *    </container>
     *    <caption />
     *  </wrapper>
     */
    this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;

    this.nodes.controls.appendChild(this.nodes.preloaderContainer);
    if (this.config.maxElementCount) {
      this.nodes.limitCounter = make('div', this.CSS.limitCounter);
      this.nodes.controls.appendChild(this.nodes.limitCounter);
    }
    this.nodes.controls.appendChild(this.nodes.fileButton);

    this.nodes.container.appendChild(this.nodes.itemsContainer);
    this.nodes.container.appendChild(this.nodes.controls);

    this.nodes.wrapper.appendChild(this.nodes.container);
    this.nodes.wrapper.appendChild(this.nodes.caption);

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.nodes.itemsContainer.addEventListener(eventName, function (e) {
        e.preventDefault();
        e.stopPropagation();
      }, false);
    });
  }

  /**
   * CSS classes
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'image-gallery',
      container: 'image-gallery__container',
      controls: 'image-gallery__controls',
      limitCounter: 'image-gallery__counter',
      itemsContainer: 'image-gallery__items',
      imageContainer: 'image-gallery__image',
      preloaderContainer: 'image-gallery__preloaders',
      imagePreloader: 'image-gallery__preloader',
      imageEl: 'image-gallery__image-picture',
      trashButton: 'image-gallery__image-trash',
      caption: 'image-gallery__caption',
    };
  };

  /**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   *
   * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
   */
  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled',
    };
  }

  /**
   * Renders tool UI
   *
   * @param {ImageGalleryData} toolData - saved tool data
   * @returns {Element}
   */
  render(toolData) {
    return this.nodes.wrapper;
  }

  onRendered() {
    if (!this.sortable) {
      this.sortable = new Sortable(this.nodes.itemsContainer, {
        handle: `.${this.CSS.imageContainer}`,
        filter: `.${this.CSS.trashButton}`,
        onStart: () => {
          this.nodes.itemsContainer.classList.add(`${this.CSS.itemsContainer}--drag`);
        },
        onEnd: (evt) => {
          this.nodes.itemsContainer.classList.remove(`${this.CSS.itemsContainer}--drag`);

          if (evt.oldIndex !== evt.newIndex) {
            this.onMoveFile(evt.oldIndex, evt.newIndex);
          }
        }
      });
    }
  }

  /**
   * Creates upload-file button
   *
   * @returns {Element}
   */
  createFileButton() {
    const button = make('div', [this.CSS.button]);

    button.innerHTML = this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }

  /**
   * Shows uploading button
   *
   * @returns {void}
   */
  showFileButton() {
    this.nodes.fileButton.style.display = '';
  }

  /**
   * Hide uploading button
   *
   * @returns {void}
   */
  hideFileButton() {
    this.nodes.fileButton.style.display = 'none';
  }

  getPreloader(file) {
    /**
     * @type {HTMLElement}
     */
    let preloader = make('div', this.CSS.imagePreloader);

    this.nodes.preloaderContainer.append(preloader);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      preloader.style.backgroundImage = `url(${e.target.result})`;
    };

    return preloader;
  }

  removePreloader(preloader) {
    preloader.remove();
  }

  /**
   * Shows an image
   *
   * @param {ImageGalleryDataFile} file - image file object
   * @returns {void}
   */
  appendImage(f) {
    let attributes = {}
    if (f.signed_id) {
      let url = rails_service_blob_path(f.signed_id, {filename: 'preview'})
      attributes.src = url
    } else if (f.url) {
      attributes.src = f.url
    } else if (f.blob) {
      attributes.src = f.blob
    }

    const tag = 'IMG'

    /**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
    let eventName = 'load';

    /**
     * Update attributes and eventName if source is a mp4 video
     */
    if (tag === 'VIDEO') {
      /**
       * Add attributes for playing muted mp4 as a gif
       *
       * @type {boolean}
       */
      attributes.autoplay = false;
      attributes.muted = true;
      attributes.playsinline = true;

      /**
       * Change event to be listened
       *
       * @type {string}
       */
      eventName = 'loadeddata';
    }

    /**
     * @type {Element}
     */
    let wrapper = make('div')
    let imageContainer = make('div', [this.CSS.imageContainer]);

    /**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
    let imageEl = make(tag, this.CSS.imageEl, attributes);

    /**
     * Add load event listener
     */
    imageEl.addEventListener(eventName, () => {
      this.toggleStatus(imageContainer, Ui.status.FILLED);
    });

    imageContainer.appendChild(imageEl);

    const title = this.api.i18n.t('Delete');

    /**
     * @type {Element}
     */
    let imageTrash = make('div', [this.CSS.trashButton], {
      innerHTML: trashIcon,
      title,
    });

    this.api.tooltip.onHover(imageTrash, title, {
      placement: 'top',
    });

    imageTrash.addEventListener('click', () => {
      this.api.tooltip.hide();

      let arrayChild = Array.prototype.slice.call(this.nodes.itemsContainer.children);
      let elIndex = arrayChild.indexOf(wrapper);

      if (elIndex !== -1) {
        this.nodes.itemsContainer.removeChild(wrapper);

        this.onDeleteFile(elIndex);
      }
    });

    imageContainer.appendChild(imageTrash);
    wrapper.appendChild(imageContainer)

    const progressElement = make('div')
    f.progressVnode = h(Progressbar)
    render(f.progressVnode, progressElement)
    wrapper.appendChild(progressElement)

    const captionInput = make('div', [this.CSS.input, this.CSS.caption], {
      contentEditable: !this.readOnly,
    })
    captionInput.innerHTML = f.caption
    captionInput.dataset.placeholder = "Подпись к медиа-файлу"    
    f.captionInput = captionInput
    wrapper.append(captionInput)

    this.nodes.itemsContainer.append(wrapper);
  }

  /**
   * Shows caption input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillCaption(text) {
    if (this.nodes.caption) {
      this.nodes.caption.innerHTML = text;
    }
  }

  /**
   * Changes UI status
   *
   * @param {Element} elem
   * @param {string} status - see {@link Ui.status} constants
   * @returns {void}
   */
  toggleStatus(elem, status) {
    for (const statusType in Ui.status) {
      if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
        elem.classList.toggle(`${this.CSS.imageContainer}--${Ui.status[statusType]}`, status === Ui.status[statusType]);
      }
    }
  }

  /**
   * @param {int} imageCount
   * @param {int|null} limitCounter
   * @returns {void}
   */
  updateLimitCounter(imageCount, limitCounter) {
    if (limitCounter && this.nodes.limitCounter) {
      if (imageCount === 0) {
        this.nodes.limitCounter.style.display = 'none';
      } else {
        this.nodes.limitCounter.style.display = null;
        this.nodes.limitCounter.innerText = `${imageCount} / ${limitCounter}`;
      }
    }
  }
}