import { icon } from '@fortawesome/fontawesome-svg-core'
import {
  faCircleExclamation, faExclamation, faComment, faCodeCompare
} from '@fortawesome/free-solid-svg-icons'

export default class Grades {
  constructor(api) {
    this.evLevelColors = {
      "1A": { color: "#5cb85c" },
      "1B": { color: "#5cb85c" },
      "1C": { color: "#5cb85c" },
      "2A": { color: "#f0ad4e" },
      "2B": { color: "#f0ad4e" },
      "2C": { color: "#f0ad4e" },
      "3A": { color: "#d38312" },
      "3B": { color: "#d38312" },
      "3C": { color: "#d38312" },
      "4C": { color: "#538cc6" },
      "5B": { color: "#29527a" },
      "5C": { color: "#29527a" },
      "comments": { color: "#777" },
      "nb": { color: "indigo" },
      "diff": { color: "darkkhaki" },
    }
    
    this.grades =  [null, '1A', '1B', '1C', '2A', '2B', '2C', '3B', '3C', '4C', '5C']

    this._CSS = {
    };
  }

  gradeSettings(obj) {
    const d = obj.data
    return this.grades.map(l => ({
      icon: icon(
        faExclamation, 
        {
          transform: {size: 12}, 
          styles: {'color': this.evLevelColors[l]?.color ?? 'black'}
        }
      ).html[0],
      label: (l ? 'УДД/УУР ' + l : 'Без признаков'),
      onActivate: () => obj.setGrade(l),
      onClick: () => obj.setGrade(l),
      hideIf: obj.hideIf ? () => obj.hideIf(l) : undefined,
      closeOnActivate: true,
      isActive: d.grade === l,
    })).concat([
      {
        icon: icon(
          faComment, 
          {
            transform: {size: 12}, 
            styles: {'color': this.evLevelColors["comments"].color}
          }
        ).html[0],
        label: 'Примечание',
        onActivate: () => obj.setGrade('comments'),
        closeOnActivate: true,
        isActive: d.grade === 'comments',
      },
      {
        icon: icon(
          faCircleExclamation, 
          {
            transform: {size: 12}, 
            styles: {'color': this.evLevelColors["nb"].color}
          }
        ).html[0],
        label: 'Важное (NB!)',
        onActivate: () => obj.setGrade('nb'),
        closeOnActivate: true,
        isActive: d.grade === 'nb',
      },
      {
        icon: icon(
          faCodeCompare, 
          {
            transform: {size: 12}, 
            styles: {'color': this.evLevelColors["diff"].color}
          }
        ).html[0],
        label: 'Сравнение с источником',
        onActivate: () => obj.setGrade('diff'),
        closeOnActivate: true,
        isActive: d.grade === 'diff',
      },
    ])
  }

  fixGrade(grade) {
    return grade.replace('А', 'A').replace('В', 'B').replace('С', 'C')
  }

  wrap(el, _grade) {
    const wrapper = document.createElement('DIV')
    wrapper.contentEditable = false
    if (_grade) {
      const grade = this.fixGrade(_grade)
      const content = typeof el === 'string' ? el : el.outerHTML
      if (typeof el === 'string') {
        wrapper.innerHTML = 
          `<span data-me-service="grade" class="badge text-light me-2" style="user-select: none; background-color:${this.evLevelColors[grade]?.color ?? 'black'};">${grade}</span>` +
          '<span data-me-service="content">' + content + '</span>'
      } else {
        wrapper.innerHTML = `<span data-me-service="grade" class="badge text-light me-2" style="user-select: none; background-color:${this.evLevelColors[grade]?.color ?? 'black'};">${grade}</span>`
        wrapper.appendChild(el)
      }
    } else {
      if (typeof el === 'string') {
        wrapper.innerHTML = el
      } else {
        return el
      }
    }
    return wrapper
  }
}