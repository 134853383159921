import './index.css';

import { IconText } from '@codexteam/icons'

import Grades from '../../utils/grades'

export default class Paragraph {
  /**
   * Default placeholder for Paragraph Tool
   *
   * @returns {string}
   * @class
   */
  static get DEFAULT_PLACEHOLDER() {
    return '';
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - constructor params
   * @param {ParagraphData} params.data - previously saved data
   * @param {ParagraphConfig} params.config - user config for Tool
   * @param {object} params.api - editor.js api
   * @param {boolean} readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-paragraph',
    };

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
    }

    /**
     * Placeholder for paragraph if it is first Block
     *
     * @type {string}
     */
    this._placeholder = config.placeholder ? config.placeholder : Paragraph.DEFAULT_PLACEHOLDER;
    this._data = {};
    this._element = null;
    this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

    this.data = data;

    this.grades = new Grades(api)
    if (this.data.grade) {
      this.data.grade = this.grades.fixGrade(this.data.grade)
    }
  }

  /**
   * Check if text content is empty and set empty string to inner html.
   * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
   *
   * @param {KeyboardEvent} e - key up event
   */
  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const textContent = this._element;

    if (textContent === '') {
      this._element.innerHTML = '';
    }
  }

  /**
   * Create Tool's view
   *
   * @returns {HTMLElement}
   * @private
   */
  drawView() {
    const editable = document.createElement('SPAN');

    editable.classList.add(this._CSS.wrapper);
    editable.contentEditable = false;
    editable.dataset.placeholder = this.api.i18n.t(this._placeholder) ?? this._placeholder;
    editable.dataset.meService = 'content'

    if (this._data.content) {
      editable.innerHTML = this._data.content;
    }

    if (!this.readOnly) {
      editable.contentEditable = true;
      editable.addEventListener('keyup', this.onKeyUp);
    }

    return this.grades.wrap(editable, this._data.grade);
  }

  /**
   * Return Tool's view
   *
   * @returns {HTMLDivElement}
   */
  render() {
    this._element = this.drawView();

    return this._element;
  }

  /**
   * Method that specified how to merge two Text blocks.
   * Called by Editor.js by backspace at the beginning of the Block
   *
   * @param {ParagraphData} data
   * @public
   */
  merge(data) {
    const newData = {
      grade: this.data.grade || data.grade,
      content: this.data.content + data.content,
    };

    this.data = newData;
  }

  /**
   * Validate Paragraph block data:
   * - check for emptiness
   *
   * @param {ParagraphData} savedData — data received after saving
   * @returns {boolean} false if saved data is not correct, otherwise true
   * @public
   */
  validate(savedData) {
    if (savedData.content.trim() === '' && !this._preserveBlank) {
      return false;
    }

    return true;
  }

  /**
   * Extract Tool's data from the view
   *
   * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
   * @returns {ParagraphData} - saved data
   * @public
   */
  save(toolsContent) {
    return {
      content: this.data.content,
      grade: this.data.grade ?? null
    }
  }

  /**
   * On paste callback fired from Editor.
   *
   * @param {PasteEvent} event - event with pasted data
   */
  onPaste(event) {
    const data = {
      content: event.detail.data.innerHTML,
    };

    this.data = data;
  }

  renderSettings() {
    return this.grades.gradeSettings(this)
  }

  setGrade(l) {
    this.data = {
      grade: l,
      content: this.data.content ?? this.data.text
    }
  }

  /**
   * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
   */
  static get conversionConfig() {
    return {
      export: 'content', // to convert Paragraph to other block, use 'text' property of saved data
      import: 'content', // to covert other block's exported string to Paragraph, fill 'text' property of tool data
    };
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      b: {},
      strong: {},
      i: {},
      span: {
        class: true,
      },
      a: {
        href: true,
      },
      sup: {},
      sub: {},
      s: {},
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get current Tools`s data
   *
   * @returns {ParagraphData} Current data
   * @private
   */
  get data() {
    if (this._element !== null) {
      if (this._data.grade) {
        this._data.content = this._element.querySelector('span[data-me-service="content"]').innerHTML
      } else {
        this._data.content = this._element.innerHTML
      }
    }
    return this._data;
  }

  /**
   * Store data in plugin:
   * - at the this._data property
   * - at the HTML
   *
   * @param {ParagraphData} data — data to set
   * @private
   */
  set data(data) {
    this._data = data || {};

    if (this._element !== null) {
      this.hydrate();
    }
  }

  /**
   * Fill tool's view with data
   */
  hydrate(){
    window.requestAnimationFrame(() => {
      this._element.innerHTML = this.drawView().innerHTML
      if (this._data.grade) {
        this._element.firstChild.contentEditable = false
      }
    });
  }

  /**
   * Used by Editor paste handling API.
   * Provides configuration to handle P tags.
   *
   * @returns {{tags: string[]}}
   */
  static get pasteConfig() {
    return {
      tags: [ 'P' ],
    };
  }

  /**
   * Icon and title for displaying at the Toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: IconText,
      title: 'Text',
    };
  }
}