import FileUploader from '../../../../javascripts/direct_uploads'
import ajax from '@codexteam/ajax';

export default class Uploader {
  constructor({ config }) {
    this.config = config;
  }

  uploadSelectedFiles(maxElementCount, { onPreview, onUpload, onError }) {
    ajax.selectFiles({
      accept: this.config.types,
      multiple: true
    }).then((files) => {
      return Array.from(files).map((f) => {
        let previewEl = onPreview(f)

        const obj = {
          uploadProgress: 0,
          caption: "",
          preview: previewEl
        }

        let uploader = new FileUploader(
          f, 
          (p) => {
            obj.progressVnode?.component?.ctx?.setProgress(p)
          }
        ).upload()
        .then((response) => {
          obj.progressVnode?.component?.ctx?.hide()
          delete obj.uploadProgress
          obj.signed_id = response.signed_id
          obj.content_type = response.content_type
          onUpload(obj)
        }).catch((error) => {
          obj.progressVnode?.component?.ctx?.setError(error)
          delete obj.uploadProgress
          obj.error = error
          onError(obj)
        })
      })
    })
  }
}