<template >
    <template v-for="b in content.blocks">
            <component 
                v-if="b.type === 'heading'"
                :is="`x_${b.type}`" 
                :block="b" 
                class="summary_viewer__item" 
                :style="caclMargin(b.data.level)"
                :hasNote="hasNote(b.node_id)"
                :id="b.node_id"
            >
            </component>

            <div class="summary">
                <div v-for="note in findNotes(b.node_id)" class="summary_viewer__note" :data-id="note.id">
                    <button v-if="note.anchors.length === 1 && notesWithoutNotesForTitle.includes(note.id)"  type="button" class="summary__delete_bnt" @click="openDeleteNotePopup(note)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#E84751" viewBox="0 0 384 512" width="15px" height="15px">
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                        </svg>
                    </button>

                    <div v-if="note.anchors.length === 1  && notesWithoutNotesForTitle.includes(note.id)" class="summary_viewer__note-content">
                        <component 
                            :is="`x_${b.type}`" 
                            :block="b"
                            class="dynamic-content-block-summary"
                        ></component>
                    </div>
                   
                    <div v-if="note.anchors.length > 1 && notesWithoutNotesForTitle.includes(note.id)"  class="summary_viewer__note-content">
                        <template v-for="bq in blockquoteParts(note.blockquote_html)">
                            <div v-if="bq.dataset?.grade">
                                <grade_badge :grade="bq.dataset.grade"/>
                                <p class="summary_viewer__note-part-content" v-html="bq.innerHTML ?? bq.textContent" ></p>
                            </div>

                            <template v-else >
                                <p class="summary_viewer__note-part-content" v-html="bq.innerHTML ?? bq.textContent" :id="note.anchors[0].anchor_id || note.anchors[0].block_id"></p>
                            </template>
                        </template>
                        
                        <tooltip :selector-focus-element="getSelector(note.id)">
                            <div v-if="note.note_html || note.attachments.length > 0" class="attachment__btns">
                                <button class="attachment__btn" @click="requestNoteEdit(note.id)">Редактировать заметку</button>
                                <button class="attachment__btn" @click="openDeleteNotePopup(note)">Удалить заметку</button>
                            </div> 
                            <div v-else class="attachment__btns">
                                <button class="attachment__btn" @click="requestNoteEdit(note.id)">Добавить заметку</button>
                                <button class="attachment__btn" @click="openDeleteNotePopup(note)">Удалить из конспекта</button>
                            </div> 
                        </tooltip>
                    </div>

                    <div v-if="note.note_html" class="summary_viewer__note-block">
                        <div class="summary_viewer__note-icon">
                            <img :src="imageUrl('pin.svg')"/>
                        </div>
                        
                        <div class="summary_viewer__note-comment" v-html="note.note_html"></div>
                    </div>
                </div>
            </div>
    </template>

    <div v-if="isPopupDeleteOpen"class="summary__popup">
        <div class="attachment__popup-content">
            <button type="button" class="info-popup__btn" @click="closeDetetePopup()">
                <img :src="imageUrl('close.svg')"/>
            </button>

            <template v-if="currentNote.attachments.length > 0">
                <template v-for="attach in currentNote.attachments">
                    <img v-if="attach.content_type.startsWith('image')" class="attachment__popup-image" :src="currentSrc"/>

                    <audio controls v-if="attach.content_type.startsWith('audio')" class="attachment__audio" :src="currentSrc"></audio>

                    <video controls v-if="attach.content_type.startsWith('video')" class="attachment__video" :src="currentSrc"></video>

                    <p v-else>{{ attach.filename}}</p>

                </template>
            </template>

            <div class="attachment__delete-block">
                <p>Подтвердить удаление заметки</p>
                <div class="attachment__delete-btns">
                    <button class="attachment__delete-btn attachment__btn-yes" @click="deleteNoteInSummary()">Да</button>
                    <button class="attachment__delete-btn attachment__btn-no" @click="closeDetetePopup()">Нет</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import x_paragraph from './content/paragraph.vue'
import x_heading from './content/heading.vue'
import x_table from './content/table.vue'
import x_list from './content/list.vue'
import x_media from './content/media.vue'
import ContentNotes from "../javascripts/content_notes"
import grade_badge from './content/grade_badge.vue'
import tooltip from './tooltip.vue'
import ContentNoteTooltip from './content_note_tooltip.vue'
import { getImageUrl } from '../javascripts/utils'

export default {
    data() {
        return {
          currentNotes: [],
          visibleBlocks: [],
          isPopupDeleteOpen: false,
          currentNote: null,
          notesWithoutNotesForTitle: []
        }
    },
    props: {
        content: {
            type: Object,
            required: true
        },
        meta: {
            type: Object,
            default: {}
        },
        notes: {
            type: Array, 
            required: true,
        },
        visibleNotesIds: {
            type: Array,
            required: true
        }, 
        visibleBlocksIds: {
            type: Array,
            required: true
        },
        allSecBlocksHaveNote: {
           type: Boolean,
           required: true
        }
    },
    components: {
      x_paragraph,
      x_heading,
      x_table,
      x_list,
      x_media, 
      grade_badge, 
      tooltip,
      ContentNoteTooltip
    },
    emits: ['getVisibleItems', 'update'],
    methods: {
        requestNoteEdit(id) {
            this.eventBus.emit('edit-content-note', id)
        },
        getSelector(id) {
            return `.summary_viewer__note[data-id="${id}"]`
        },
        blockquoteParts(bq) {
            return ContentNotes.blockquoteParts(bq)
        },
        getVisibleItems() { 
            let documentHeight = document.documentElement.clientHeight
            let itemsBlocks = document.getElementsByClassName('summary_viewer__item')
            let itemsNotes = Array.from(document.getElementsByClassName('summary_viewer__note-content'))
            let visibleBlocksItems = itemsNotes.filter(n => n.getBoundingClientRect().top > 0 && n.getBoundingClientRect().top < documentHeight)
            if(visibleBlocksItems.length > 0) {
                this.visibleBlocks = visibleBlocksItems
            } else {
                //visibleBlocksItems = itemsNotes.filter(n => n.getBoundingClientRect().top > 0 && n.getBoundingClientRect().top < documentHeight)
                    let j = 0
                    while(j < itemsBlocks.length) {
                        if(itemsBlocks[j].getBoundingClientRect().top > 0) {
                            visibleBlocksItems.push(itemsBlocks[j])
                            break
                        }
                        j = j + 1
                    }
        
                this.visibleBlocks = visibleBlocksItems
            }
            if(this.visibleBlocks[0]) {
                if(this.visibleBlocks[0].id) {
                    this.$emit('getVisibleItems', this.visibleBlocks[0].id, 'block')
                } else {
                    this.$emit('getVisibleItems', this.visibleBlocks[0].dataset.id, 'note')
                }
            }
        },
        caclMargin(level) {
            let margin = () => {
                if(level === 1) {
                    return 0
                } else {
                    return  level * 20
                }
            } 
            return `margin-left: ${margin()}px`
        },
        hasNote(id) {
            let hasNote = false
            for (let i = 0; i < this.notes.length; i++) {
                for(let j = 0; j < this.notes[i].anchors.length; j++) {
                    if(id === this.notes[i].anchors[j].block_id) {
                        hasNote = true
                    } else {
                        const titleId = this.notes[i].anchors[j].block_id.split('_')
                        titleId.splice(1, 1)
                        if(titleId.join('_') === id) {
                            hasNote = true
                        }
                    }
                }
            }
            return hasNote
        },
        findNotes(id) {
            let notes = []
            let notesInSummary = this.notes.filter(el => el.anchors.length === 1 && el.anchors[0].block_id === id)
            if(notesInSummary.length === 0) {
                notes = this.notes.filter(el => el.anchors[0].block_id === id)
            } else {
                notes = notesInSummary
            }
            if(notes.length > 1) {
                notes = notes.sort(function(a, b) {
                    const [orderA, orderB] = [a, b].map(
                        v => [
                            ...ContentNotes.extractBlockOrder(v.anchors[0].anchor_id ?? v.anchors[0].parent_id ?? v.anchors[0].block_id).slice(0, 4), 
                            v.offset
                        ]
                    )
                    return orderA.map((v, i) => v - orderB[i]).find(v => v !== 0) ?? 0
                })
            }
            return notes
        },
        deleteNoteInSummary() {
            ContentNotes.deleteNote(this.currentNote)
                .then(() => {
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    let currentNoteEl = document.querySelector(`.summary_viewer__note[data-id="${this.currentNote.id}"]`)
                    currentNoteEl.remove()
                    this.isPopupDeleteOpen = false
                    this.$emit("update")
                })
        },
        openDeleteNotePopup(note) {
            this.currentNote = note
            this.isPopupDeleteOpen = true
        },
        closeDetetePopup() {
            this.isPopupDeleteOpen = false
        },
        handleClickOutside(event) {
            if(event.target.classList[0] === 'summary__popup') {
                this.isPopupDeleteOpen = false
            }
        },
        imageUrl(path) {
            return getImageUrl(path)
        }
    },
    mounted() {
        let notesForTitle = []
        let titles = this.content.blocks.filter(c => c.type === 'heading')
        for(let i=0; i < this.notes.length; i++) {
            let note_html = document.createElement('DIV')
            note_html.innerHTML = this.notes[i].blockquote_html
            let childrenNote = note_html.querySelector('.selection-blockquote-part').childNodes
            for(let j=0; j < titles.length; j++) {
                if(childrenNote.length === 1 && note_html.textContent === titles[j].data.content) {
                    notesForTitle.push(this.notes[i].id)
                }
            }
        }

        this.notes.forEach(n => { 
            if(!notesForTitle.includes(n.id)) {
                this.notesWithoutNotesForTitle.push(n.id)
            }
        })

        document.addEventListener("click", this.handleClickOutside)
        document.addEventListener("scroll", this.getVisibleItems)

        this.$nextTick(() => {
            let notesForMarkup = []
            let notesInSummary = this.notes.filter(note => note.anchors.length === 1 && this.notesWithoutNotesForTitle.includes(note.id))
            let notesOnOneBlock = this.notes.filter(note => note.anchors.length > 1 && note.anchors[0].block_id === note.anchors[1].block_id)
            for(let i = 0; i < notesInSummary.length; i++) {
                if(notesOnOneBlock.filter(note => note.anchors[0].block_id === notesInSummary[i].anchors[0].block_id).length > 0) {
                    notesForMarkup.push(notesOnOneBlock.filter(note => note.anchors[0].block_id === notesInSummary[i].anchors[0].block_id))
                }
            }
            notesInSummary.forEach((note) => {ContentNotes.markupNote(note, this.notes)})
            notesForMarkup.forEach((notes) => {
            notes.forEach(note => ContentNotes.markupNote(note, this.notes))
            })

            let scrollEl = null
            let visibleNote = null
            if(this.visibleNotesIds.length > 0) {
                if(this.allSecBlocksHaveNote) {
                    let idNote = this.visibleNotesIds[0].dataset.id
                    let idNode = this.notes.find(n => n.id === idNote).anchors[0].block_id
                    // let idTitle = idNode.split('_')
                    // idTitle.splice(1, 1)
                    // let nodeIdTitle = idTitle.join('_')
                    // visibleNote = document.getElementById(nodeIdTitle)
                    visibleNote = document.getElementById(idNode)
                } else {
                    let currentId = this.visibleNotesIds[0].dataset.id
                    visibleNote = document.querySelector(`.summary_viewer__note[data-id="${currentId}"]`)
                }
                scrollEl = visibleNote
            }
            
            if(scrollEl) {
                scrollEl.scrollIntoView(
                    {behavior: "smooth", block: "start"}
                )
            } else {
                const items = document.getElementsByClassName('summary_viewer__item')
                let j = 0
                while(j < items.length) {
                    if(items[j].id === this.visibleBlocksIds[0]) {
                        scrollEl = items[j]
                        break
                        }
                        j = j + 1
                }
                
                if(scrollEl) {
                    scrollEl.scrollIntoView(
                        {behavior: "smooth", block: "start"}
                    )
                } else {
                    const sec =  this.visibleBlocksIds[0].split("_")[0]
                    const length = this.visibleBlocksIds[0].split("_").length
                    const block = this.visibleBlocksIds[0].split("_")[length - 1].replace('strong','').split("-")
                    const titleNumber = block.filter( el => parseInt(el)).join('-')
                    const start = sec + '_' + titleNumber
                
                    let i = 0
                    while(i < items.length) {
                        if(items[i].id.startsWith(start)) {
                            scrollEl = items[i]
                            break
                        }
                        i = i + 1
                    }

                    if(scrollEl) {
                        scrollEl.scrollIntoView(
                            {behavior: "smooth", block: "start"}
                        )
                    }
                }
            }
        })
    }
}
</script>

<style lang="scss">
@import "../stylesheets/application.scss";

.dynamic-content-block-summary {
    margin-bottom: 15px;
}

.hidden-note {
    height: 1px;
    overflow: hidden;
    padding: 0px;
    margin-bottom: 0px;
}

.summary {
    position: relative;
    width: fit-content;

    .mark-content-note {
        background-color: white;
        color: $secondary-color-1;
    }

    &__delete_bnt {
        display: none;
        position: absolute;
        right: -25px;
        top: 15px;
        border: none;
        background-color: transparent;
    }
    
    &:hover {
        .summary__delete_bnt {
            display: flex;
        }
    }
    
    &__popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(205, 214, 219, 0.8);
        z-index: 10;
    }

    &__popup-content {
        position: fixed;
        top: 30%;
        left: 50%;
        background: #fff;
        z-index: 2;
        padding: 40px;
        transform: translate(-50%, -25%);
    }

    &__btn {
        border: none;
        background-color: transparent;
        color: #fff;
        position: absolute;
        right: 3%;
        top: 7%;
    }

    &__btns {
        display: flex;
    }

    &__delete-btns {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__delete-btn {
        border: none;
        border-radius: 8px;
        margin: 10px;
        width: 100px;
        height: 40px;
        background: rgba(205, 214, 219, 0.8);

        &:hover {
            background: #8d8d8d;
            color: #fff;
        } 
    }

    &__delete-block{
        margin-top: 20px;
    }

    .paragraph {
        margin-bottom: 0px;
    }

}

.summary_viewer {
    &__item  {
        margin-top: 24px;

        & .heading {
            color: gray;
            margin-bottom: 0px;
        }

        & .has-note {
            color: black;
        }

        & h1, h2, h3, h4, h5, h6 {
            font-size: 16px;
        } 

        &__attachements {
            background-color: #8d8d8d;
        }
    }

    &__note-content {
        margin-top: 24px;
    }


    &__note-part-content {
            display: inline;
        }

    .has-note {
        color: black;
    } 

    &__note {
        display: flex;
        flex-direction: column;
    }

    &__note-block {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    &__note-comment {
        display: flex;
        align-items: center;
        & p {
            padding: 0;
            margin: 0;
            margin-left: 20px;
            color: $primary-light-color-5;
        }
    }

    &__note-icon {
        color: #A8234C;
    }
}

.hidden {
    display: none;
}
</style>