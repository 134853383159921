<template>
  <div class="mb-2">
    <GradeBadge :grade="block.data.grade" v-if="block.data.grade"/>

    <div :id="block.node_id" :data-hash="block.hash" :data-id="orderId" class="me-content">
      <table class="content-table">
        <caption 
          :id="block.data.caption.node_id"
          v-if="block.data.caption"
          v-html="block.data.caption.content"
        ></caption>
        <thead v-if="heading" class="content-table__thead">
          <tr v-for="row in heading">
            <th 
              v-for="cell in row"
              :id="cell.node_id" 
              v-html="cell.content"
              :rowspan="cell.rowspan || 1"
              :colspan="cell.colspan || 1"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows">
            <td 
              v-for="cell in row" 
              :id="cell.node_id"
              v-html="cell.content"
              :rowspan="cell.rowspan || 1"
              :colspan="cell.colspan || 1"
            ></td>
          </tr>
        </tbody>
        <tfoot v-if="footer">
          <tr v-for="row in footer">
            <th 
              v-for="cell in row" 
              :id="cell.node_id"
              v-html="cell.content"
              :rowspan="cell.rowspan || 1"
              :colspan="cell.colspan || 1"
            ></th>
          </tr>
        </tfoot>

      </table>
    </div>
  </div>
</template>

<script>
  import ContentNotes from '../../javascripts/content_notes'
  import GradeBadge from './grade_badge.vue'
  
  export default {
    data() {
      return {
      }
    },
    components: {
      GradeBadge
    },
    props: {
      block: {
        type: Object,
        required: true
      }
    },
    computed: {
      heading() {
        if (this.block.data.thead) {
          return this.block.data.content.slice(0, this.block.data.thead)
        } else {
          return null
        }
      },
      footer() {
        if (this.block.data.tfoot) {
          return this.block.data.content.slice(this.block.data.content.length - this.block.data.tfoot)
        } else {
          return null
        }
      },
      rows() {
        return this.block.data.content.slice(this.block.data.thead ?? 0, this.block.data.content.length - this.block.data.tfoot ?? 0 ) || []
      },
      orderId() {
        return this.block.node_id ? ContentNotes.orderId(this.block.node_id) : ''
      },
    }
  }
</script>

<style scss>
table {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px solid #CFECEE;
}



</style>
