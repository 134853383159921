<template>
  <div class="row mt-3 justify-content-center content-block-preview">
    <div class="col-auto">
      <div class="spinner-border" role="status" v-if="loading">
        <span class="visually-hidden">Loading...</span>
      </div>
      <dynamic_content :hasContextMenu=false :content="content" v-if="!loading && content"></dynamic_content>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { part_content_chunk_path } from "../../javascript/routes"
  import dynamic_content from '../components/dynamic_content.vue'
  
  export default {
    data() {
      return {
        content: undefined,
        loading: false,
      }
    },
    props: {
      searchContext: {
        type: [String, null]
      },
      contentChunkId: {
        type: String,
        required: true
      },
      partId: {
        type: String,
        required: true
      }
    },
    components: {
      dynamic_content
    },
    methods: {
      getContent() {
        if(this.content) {
          this.loading = false
        } else {
          this.loading = true
          
          axios.get(
            part_content_chunk_path(this.$props.contentChunkId, this.$props.partId, {format: 'json'}),
            {
              params: {
                  context: this.searchContext
              },
              headers: {
                  'Accept': 'application/json'
              }
            }
          ).then((resp) => {
              this.content = resp.data.content_chunk.content
          }).catch((error) => {
              console.log(error)
          }).finally(() => {
              this.loading = false
          })
        }
      }
    }
  }
</script>

<style>
.content-block-preview {
  background-color: #fff;
}
</style>