<template>
  <b-card no-body>
    <b-tabs card>
      <b-tab v-for="t in term.locales" :key="t.locale" :title="t.locale.toUpperCase()">
        <span class="h4">
          {{ t.denotation }}
          <template v-if="t.denotation_abbreviations?.length">
            (аббр.: {{ t.denotation_abbreviations.join(", ") }})
          </template>
        </span>
        <div v-if="t.synonyms.length > 0" class="mt-2">
          <h5>Синонимы:</h5>
          <template v-for="s in t.synonyms">
            {{ s.term }}
            <template v-if="s.abbreviations?.length">
              (аббр.: {{ s.abbreviations.join(", ") }})
            </template>
            <br/>
          </template>
        </div>
        
        <div class="mt-2">
          <h5>Определение понятия</h5>
          <p v-if="term.definitions[t.locale]" class="mt-2">
            <dynamic-content :content="term.definitions[t.locale]" :has-context-menu="false">
            </dynamic-content>
          </p>
          <p v-else><span class="text-muted">У понятия нет определения</span></p>
        </div>

        <div class="mt-2" v-if="term.links && term.links.length > 0">
          <h5>Ссылки</h5>
          <p class="mt-3" v-for="l in term.links">

            <b-link :href="l.uri" target="_blank" rel="noopener">{{ !l.description || l.description === '' ? l.uri : l.description }}</b-link>
          </p>
        </div>

        <b-link :href="edit_term_path" target="_blank" class="mt-3"><small>Редактировать понятие</small></b-link>
      </b-tab>
    </b-tabs>
    
    <b-card class="mt-3" title="Связанные понятия" v-if="showRelated && term.related_terms && term.related_terms.length > 0">
      <b-row class="mt-1" v-for="rt in term.related_terms">
        <b-col>
          <Term :term="rt" :show-related="false" />
        </b-col>
      </b-row>
    </b-card>
  </b-card>
</template>

<script>
  import { edit_term_path } from '../../../javascript/routes'

  export default {
    props: {
      term: {
        type: Object,
        required: true
      },
      showRelated: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      edit_term_path() {
        return edit_term_path(this.term.id)
      }
    }
  }
</script>