import { DirectUpload } from "@rails/activestorage"
import { rails_direct_uploads_path } from '../../javascript/routes'

export default class Uploader {
  constructor(file, progressCallback) {
    this.uploader = new DirectUpload(file, rails_direct_uploads_path(), this)
    this.progress = 0
    this.progressCallback = progressCallback
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.uploader.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          this.progressCallback(100)
          resolve(blob)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    let new_progress = Math.round(event.loaded * 100 / event.total)
    if (new_progress != this.progress) {
      this.progress = new_progress
      this.progressCallback(this.progress)
    }
  }
}