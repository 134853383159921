<template>
  <div class="container">
    <div ref="editor">
    </div>
    <div ref="reference_tool_settings"></div>
  </div>
</template>

<script>
  import EditorJS from '@editorjs/editorjs'
  import Header from './content_editor/block_tools/heading/index'
  import Marker from '@editorjs/marker'
  import Paragraph from './content_editor/block_tools/paragraph/index'
  import Table from './content_editor/block_tools/table/index'
  import ChangeCase from 'editorjs-change-case'
  import Undo from 'editorjs-undo'
  import Delimiter from '@editorjs/delimiter'
  import List from './content_editor/block_tools/list/index'
  import Tooltip from './content_editor/block_tools/tooltip/index'
  import Reference from './content_editor/block_tools/reference/index'
  import Strikethrough from '@sotaproject/strikethrough'
  //import ColorPlugin from 'editorjs-text-color-plugin'
  import createGenericInlineTool from 'editorjs-inline-tool'
  import MediaGallery from './content_editor/block_tools/images/index'

  import { icon } from '@fortawesome/fontawesome-svg-core'

  import { getCurrentInstance } from 'vue/dist/vue.esm-bundler'

  export default {
    data() {
      return {
        editor: null,
        undo: null,
        error: null,
        appContext: null,
        saveInProgress: false
      }
    },
    props: {
      content: Object,
      modelValue: Object,
    },
    methods: {
      getContent() {
        return this.editor.save()
      },
      setContent(content) {
        this.editor.render(content)
      },
      onContentSnapshot() {
        if (this.modelValue) {
          this.modelValue.canRedo = this.undo?.canRedo()
          this.modelValue.canUndo = this.undo?.canUndo()
        }
      },
      doUndo(event) {
        console.log('undo')
        this.undo.undo()
      },
      doRedo(event) {
        this.undo.redo()
      }
    },
    mounted() {
      this.appContext = getCurrentInstance().appContext
      const inlineToolbar = [
        'link', 'bold', 'italic', 'strikethrough', 
        'sup', 'sub', 
        'tooltip', 'reference', 'marker', 
        'changeCase'
      ]

      const editor = new EditorJS({
        holder: this.$refs.editor,
        placeholder: 'Начните ввод контента',
        defaultBlock: 'paragraph',
        onReady: () => {
          this.undo = new Undo({ editor, maxLength: 100, debounceTimer: 300, onUpdate: () => this.onContentSnapshot() })
          if (this.content) {
            this.undo.initialize(JSON.parse(JSON.stringify(this.content)))
          }
        },
        data: this.content ? JSON.parse(JSON.stringify(this.content)) : null,
        tools: {
          marker: Marker,
          table: {
            class: Table,
            inlineToolbar,
            config: {
              withHeadings: true,
            }
          },
          list: {
            class: List,
            inlineToolbar,
          },
          changeCase: ChangeCase,
          strikethrough: Strikethrough,
          reference: {
            class: Reference,
            config: {
              holder: this.$refs.editor,
              modal_element: this.$refs.reference_tool_settings,
            }
          },
          tooltip: {
            class: Tooltip,
            config: {
              location: 'left',
              underline: true,
              placeholder: 'Enter a tooltip',
              highlightColor: '#FFEFD5',
              backgroundColor: '#154360',
              textColor: '#FDFEFE',
              holder: this.$refs.editor,
            }
          },
          /*color: {
            class: ColorPlugin,
            config: {
              colorCollections: ['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
              defaultColor: '#FF1300',
              type: 'text', 
              customPicker: true
            }     
          },*/
          //spoiler: TextSpolier,
          paragraph: {
            class: Paragraph,
            inlineToolbar,
            config: {
              placeholder: 'Введите текст абзаца'
            }
          },
          heading: {
            class: Header,
            inlineToolbar: false,
            config: {
              placeholder: 'Напечатайте заголовок'
            }
          },
          delimiter: Delimiter,
          sup: {
            class: createGenericInlineTool({
              sanitize: {
                sup: {},
              },
              tagName: 'SUP',
              toolboxIcon: icon({ prefix: 'fas', iconName: 'superscript' }, {transform: {size: 10}}).html[0],
            }),
          },
          sub: {
            class: createGenericInlineTool({
              sanitize: {
                sub: {},
              },
              tagName: 'SUB',
              toolboxIcon: icon({ prefix: 'fas', iconName: 'subscript' }, {transform: {size: 10}}).html[0],
            }),
          },
          media: {
            class: MediaGallery,
            inlineToolbar: ['link', 'bold', 'italic', 'strikethrough', 'sup', 'sub', 'tooltip', 'reference', 'changeCase'],
          }
        },
        i18n: {
          /**
           * @type {I18nDictionary}
           */
          messages: {
            /**
             * Other below: translation of different UI components of the editor.js core
             */
            "ui": {
              "blockTunes": {
                "toggler": {
                  "Click to tune": "Нажмите, чтобы настроить",
                  "or drag to move": "или перетащите"
                },
              },
              "inlineToolbar": {
                "converter": {
                  "Convert to": "Конвертировать в"
                }
              },
              "toolbar": {
                "toolbox": {
                  "Add": "Добавить",
                }
              },
              "popover": {
                "Filter": "Поиск",
                "Nothing found": "Ничего не найдено"
              }
            },

            /**
             * Section for translation Tool Names: both block and inline tools
             */
            "toolNames": {
              "Text": "Параграф",
              "Heading": "Заголовок",
              "List": "Список",
              "Warning": "Примечание",
              "Checklist": "Чеклист",
              "Quote": "Цитата",
              "Code": "Код",
              "Delimiter": "Разделитель",
              "Raw HTML": "HTML-фрагмент",
              "Table": "Таблица",
              "Gallery": "Медиа-файлы",
              "Link": "Ссылка",
              "Marker": "Маркер",
              "Bold": "Полужирный",
              "Italic": "Курсив",
              "InlineCode": "Моноширинный",
              "Image": "Картинка"
            },

            /**
             * Section for passing translations to the external tools classes
             */
            "tools": {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
               * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
               */
              "warning": { // <-- 'Warning' tool will accept this dictionary section
                "Title": "Название",
                "Message": "Сообщение",
              },

              /**
               * Link is the internal Inline Tool
               */
              "link": {
                "Add a link": "Вставьте ссылку"
              },
              /**
               * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
               */
              "stub": {
                'The block can not be displayed correctly.': 'Блок не может быть отображен'
              },
              "media": {
                "Caption": "Подпись",
                "Select an Image": "Выберите файл",
                "With border": "Добавить рамку",
                "Stretch image": "Растянуть",
                "With background": "Добавить подложку",
              },
              "code": {
                "Enter a code": "Код",
              },
              "linkTool": {
                "Link": "Ссылка",
                "Couldn't fetch the link data": "Не удалось получить данные",
                "Couldn't get this link data, try the other one": "Не удалось получить данные по ссылке, попробуйте другую",
                "Wrong response format from the server": "Неполадки на сервере",
              },
              "header": {
                "Header": "Заголовок",
              },
              "paragraph": {
                "Enter something": "Введите текст"
              },
              "list": {
                "Ordered": "Нумерованный",
                "Unordered": "Маркированный",
                "Has Explanation": "С пояснением",
              }
            },

            /**
             * Section allows to translate Block Tunes
             */
            "blockTunes": {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
               * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
               *
               * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
               */
              "delete": {
                "Delete": "Удалить"
              },
              "moveUp": {
                "Move up": "Переместить вверх"
              },
              "moveDown": {
                "Move down": "Переместить вниз"
              }
            },
          }
        }
      })
      this.editor = editor
    }
  }
</script>

<style>
.editor-menu {
  margin-top: -1rem;
  position: sticky;
}

.ce-block__content, .ce-toolbar__content { 
  max-width:calc(100% - 180px) !important; 
} 

.cdx-block { 
  max-width: 100% !important; 
}

.ce-block {
  margin-bottom: 1rem;
}

@media (min-width: 651px) {
.ce-block__content {
max-width: calc(100% - 120px) !important;
margin: 0 60px;
}
}

@media (min-width: 651px) {
.ce-toolbar__content {
width: 0px !important;
margin: 0 50px;
}
}

.cdx-block {
max-width: 100% !important;
}

@media (min-width: 651px) {
.codex-editor--narrow .ce-toolbox .ce-popover {
left: 0;
right: 0;
}
}

@media (min-width: 651px) {
.codex-editor--narrow .ce-settings .ce-popover {
right: 0;
left: 0;
}
}
</style>