<template>
  <Toast 
    v-for="t in toastMessages"
    :key="t"
    :severity="t.severity" :message="t.message" :delay="t.delay"
  />
</template>

<script>
  import Toast from './toast.vue'

  export default {
    data() {
      return {
        toastMessages: JSON.parse(JSON.stringify(this.initial_messages)),
      }
    },
    components: {
      Toast
    },
    props: {
      initial_messages: {
        type: Array,
        default: []
      }
    },
    mounted() {
      this.eventBus.on('toast-show', (msg) => this.addToast(msg))
    },
    methods: {
      addToast(data) {
        this.toastMessages.push(data)
      },
    }
  }
</script>