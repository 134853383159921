<template>
  <div class="modal" tabindex="-1" ref="modal" data-bs-focus="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Создание связи</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <blockquote class="blockquote mb-3">
            <p>{{ range }}</p>
          </blockquote>
          
          <template v-if="entityClasses">
            <div class="mb-3">
              <select ref="entity_class_select" v-model="entityClass">
                <option data-placeholder="true"></option>
                <option :value="k" v-for="(v, k) in entityClasses">{{ v }}</option>
              </select>
            </div>
          </template>
          <div class="mb-3" v-else>
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Загрузка данных справочников...</span>
              </div>
            </div>
          </div>

          <select ref="entity_record_select" v-model="entityRecordId">
            <!--option data-placeholder="true"></option-->
            <!--option :value="el[0]" v-for="el in entityDict[entityClass]">{{ el[1] }}</option-->
          </select>
          <div v-if="!entityDict[entityClass]">
            <div class="text-center" v-if="entityClasses && entityClass">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Загрузка данных справочника {{ entityClasses[entityClass] }}...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
          <button type="button" class="btn btn-danger" v-if="referencePersisted" @click="destroyReference">Удалить связь</button>
          <button type="button" class="btn btn-primary" :disabled="!(entityClass && entityRecordId)" @click="createReference">
            Создать связь
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Modal } from 'bootstrap'
  import SlimSelect from 'slim-select'
  import 'slim-select/styles'
  import axios from 'axios'
  import { 
    api_internal_ontologies_path,
    api_internal_ontology_path
  } from '../../../../../javascript/routes'

  export default {
    data() {
      return {
        range: null,
        bsModal: null,
        entityClasses: null,
        entityClass: null,
        entityRecordId: null,
        entityClassesSelect: null,
        entityRecordsSelect: null,
        entityDict: {},
        referencePersisted: false,
        entityRecordTooltip: null
      }
    },
    props: {
      tool: {
        type: Object,
        required: true
      }
    },
    async mounted() {
      this.entityClasses = (await this.fetchEntityClasses()).available
      this.bsModal = Modal.getOrCreateInstance(this.$refs.modal, {focus: false})
      this.$nextTick(() => {
        this.entityClassesSelect = new SlimSelect({
          select: this.$refs.entity_class_select,
          settings: {
            placeholderText: 'Выберите класс контента',
          },
          events: {
            afterChange: (newVal) => {
              if (newVal[0].value !== '') {
                this[`setEntityClass_${newVal[0].value}`]()
              }
            }
          }
        })

        this.entityRecordsSelect = new SlimSelect({
          select: this.$refs.entity_record_select,
          settings: {
            placeholderText: 'Выберите запись',
          },
          events: {
            afterChange: (newVal) => {
              this.entityRecordTooltip = (newVal[0]?.html && newVal[0]?.html !== '') ? newVal[0]?.html : newVal[0]?.text
            }
          }
        })
        this.entityRecordsSelect.disable()
      })
    },
    methods: {
      async fetchEntityClasses() {
        let resp = await axios
          .get(api_internal_ontologies_path())
        return resp.data
      },
      show(range, state) {
        this.bsModal.show()
        this.range = range
        if (state?.entityClass && state?.entityRecordId) {
          this.referencePersisted = true
        } else {
          this.referencePersisted = false
        }

        if (this.entityClass !== state?.entityClass) {
          this.entityClass = state?.entityClass ?? null
          this.entityClassesSelect.setSelected(this.entityClass)
          this.entityRecordsSelect.setData([])
          this.entityRecordsSelect.disable()

          if (this.entityClass) {
            this[`setEntityClass_${this.entityClass}`]()
          }
        }
        
        this.entityRecordId = state?.entityRecordId ?? null
      },
      hide() {
        this.bsModal.hide()
      },
      setEntityClass_inn() {
        if (!this.entityDict[this.entityClass]) {
          axios
            .get(api_internal_ontology_path(this.entityClass))
            .then((resp) => {
              this.entityDict[this.entityClass] = resp.data
              this.initEntityRecordIdSelect()
            })
        }
      },
      setEntityClass_icd_10() {
        this.entityRecordsSelect.disable()
        if (!this.entityDict[this.entityClass]) {
          axios
            .get(api_internal_ontology_path(this.entityClass))
            .then((resp) => {
              this.entityDict[this.entityClass] = resp.data
              this.initEntityRecordIdSelect()
            })
        } else {
          this.initEntityRecordIdSelect()
        }
      },
      initEntityRecordIdSelect() {
        let data = [{'placeholder': true, text: ''}].concat(this.entityDict[this.entityClass])
        this.entityRecordsSelect.setData(data)
        this.entityRecordsSelect.setSelected(this.entityRecordId)
        this.entityRecordsSelect.enable()
      },
      createReference() {
        this.hide()
        this.tool.wrap(this.range, {
          entityClass: this.entityClass,
          entityRecordId: this.entityRecordId,
          entityRecordTooltip: this.entityRecordTooltip
        })
      },
      destroyReference() {
        this.hide()
        this.tool.unwrap(this.range)
      }
    }
  }
</script>

<style>
blockquote {
 font-size: 1.1em;
 line-height: 1.6em;
 padding: 20px 20px 20px 0px;
 border-left: none;
 position: relative;
 background: #f5f5f5;
}

blockquote:before,
blockquote:after {
 font-family: Arial, serif;
 font-size: 2.5em;
 vertical-align: middle;
 line-height: 0
}

blockquote:before {
 content: open-quote;
 margin-right: 4px
}

blockquote:after {
 content: close-quote;
 margin-left: 3px
}

blockquote.blockquote,
blockquote.style2 {
 background: #fff;
 font-style: italic
}

blockquote.blockquote p,
blockquote.style2 p {
 display: inline
}

blockquote.blockquote {
 border-left: 3px solid #f0715f;
  position: relative;
}

blockquote.blockquote:before,
blockquote.blockquote:after {
    color: #f0715f;
}

blockquote.blockquote.border-color-blue {
 border-color: #01b7f2
}

blockquote.blockquote.border-color-yellow {
 border-color: #fdb714
}
</style>