<template>
  <div>
    <b-row>
      <b-breadcrumb>
        <b-breadcrumb-item 
          v-for="i in breadcrumbItems" 
          @click="showIndexFor(i.prefix)"
          :active="i.prefix === currentPrefix">
          {{ i.text }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </b-row>
    <b-row v-if="currentIndex">
      <b-col cols="auto" class="m-4" v-for="e in currentIndex">
        <b-button v-if="e.full || e.count === 1" variant="success" class="position-relative" @click="showContentFor(e.prefix)">
          <span class="display-5">{{ e.prefix }}</span><b-badge variant="info" placement="top-end">{{ e.count }}</b-badge>
        </b-button>
        <b-button-group v-else-if="e.count < 50">
          <b-dropdown placement="right" variant="outline-success" @click="showContentFor(e.prefix)">
            <template #button-content>
              <span class="display-5">{{ e.prefix }}</span>
            </template>
            <b-dropdown-item @click="showIndexFor(e.prefix)">Раскрыть глубже</b-dropdown-item>
            <b-dropdown-item @click="showContentFor(e.prefix)">Показать {{ e.count }} {{ pluralize(e.count, 'понятие', 'понятия', 'понятий') }}</b-dropdown-item>
          </b-dropdown>
          <b-badge variant="info" placement="top-end">{{ e.count }}</b-badge>
        </b-button-group>
        <b-button v-else variant="outline-secondary" class="position-relative" @click="showIndexFor(e.prefix)">
          <span class="display-5">{{ e.prefix }}</span><b-badge variant="info" placement="top-end">{{ e.count }}</b-badge>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="terms">
      <b-col>
        <Term v-for="term in terms" :term="term" :key="term" class="mt-3"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import axios from 'axios'
  import { terms_path, by_prefix_terms_path, index_by_prefix_terms_path } from '../../../javascript/routes'
  import plural from 'plural-ru'
  import Term from './term.vue'
  
  export default {
    props: {
      index: {
        type: Array,
        required: true
      }
    },
    components: {
      Term
    },
    data() {
      return {
        breadcrumbItems: [
          { text: 'Всё', prefix: null }
        ],
        currentPrefix: null,
        currentIndex: JSON.parse(JSON.stringify(this.index)),
        terms: null
      }
    },
    methods: {
      pluralize(count, ...words) {
        return plural(count, ...words)
      },
      showIndexFor(prefix) {
        this.terms = null
        if (prefix) {
          axios
            .get(index_by_prefix_terms_path(prefix, { format: 'json' }))
            .then(resp => {
              this.currentIndex = resp.data
              this.breadcrumbItems.push({ text: prefix, prefix })
              this.currentPrefix = prefix
            })
        } else {
          axios
            .get(terms_path({ format: 'json' }))
            .then(resp => {
              this.currentIndex = resp.data
              this.breadcrumbItems.splice(1, this.breadcrumbItems.length - 1)
              this.currentPrefix = null
            })
        }
      },
      showContentFor(prefix) {
        this.currentIndex = null
        axios
          .get(by_prefix_terms_path(prefix, { format: 'json' }))
          .then(resp => {
            this.terms = resp.data
            this.breadcrumbItems.push({ text: prefix, prefix })
            this.currentPrefix = prefix
          })
      }
    }
  }
</script>