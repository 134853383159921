<template>
  <div>
    <div v-if="modelValue.signed_id">
      <FilePreview
        :src="src"
        :type="type"
        :name="modelValue.filename"
        :readonly="readonly"
        @drop-attachment="dropAttachment"
      />
    </div>
    <div class="attachment-thumbnail" v-else>
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated" 
          :class="{'bg-danger': modelValue.error}"
          role="progressbar" 
          :style="`width: ${modelValue.error ? 100 : modelValue.progress}%;`"
        >{{ modelValue.error ? 'Ошибка' : null }}</div>
      </div>
    </div>

  </div>
</template>

<script>
  import { rails_service_blob_path } from '../../javascript/routes'

  const FilePreview = {
    computed: {
      sanitizedSrc: function() {
        return this.src.replace(/\s/g, '%20')
      },
    },
    props: {
      type: String,
      name: String,
      src: String,
      readonly: {
        type: Boolean,
        default: false
      }
    },
    emits: ['dropAttachment'],
    methods: {
      drop() {
        this.$emit('dropAttachment')
      }
    },
    // Use the template property instead of a template block
    template: `
      <div v-if="type === 'image'">
        <div
          v-if="sanitizedSrc"
          class="attachment-thumbnail"
          v-bind:style="{ 'background-image': 'url(' + sanitizedSrc + ')' }"
        >
          <div class="close-wrapper" v-if="!readonly">
            <a @click="drop">
              <fa-icon :icon="['fas', 'trash-can']"></fa-icon>
            </a>
          </div>
        </div>
        <div class="text-truncate"><small><a :href="src">{{ name }}</a></small></div>
      </div>
      <div v-else-if="type === 'video'">
        <div class="attachment-thumbnail centered-content">
          <svg width="50" height="56" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
              <path opacity="0.3" d="M16.7596 5.9085C16.1035 5.90787 15.4746 5.64698 15.0107 5.1831C14.5468 4.71923 14.2859 4.09025 14.2853 3.43423V2.22974e-07H3.23024C2.8061 -0.000157393 2.38607 0.083248 1.99417 0.245452C1.60226 0.407656 1.24617 0.645481 0.946198 0.945341C0.646227 1.2452 0.40827 1.60122 0.24592 1.99306C0.0835709 2.38491 -2.92858e-08 2.80489 0 3.22904V22.6945C0.00127199 23.5502 0.342198 24.3705 0.947882 24.9751C1.55357 25.5796 2.37447 25.919 3.23024 25.9187H16.5016C16.9256 25.9187 17.3455 25.8352 17.7373 25.6729C18.129 25.5106 18.485 25.2728 18.7849 24.9729C19.0847 24.6731 19.3225 24.3171 19.4848 23.9254C19.6471 23.5336 19.7306 23.1137 19.7306 22.6897V5.9085H16.7596Z" fill="#44B5BB"/>
            </g>
            <path d="M19.7306 5.9085H16.7595C16.1035 5.90787 15.4745 5.64698 15.0106 5.1831C14.5468 4.71923 14.2859 4.09025 14.2852 3.43423V0L19.7306 5.9085Z" fill="#44B5BB"/>
            <path d="M14.3333 15.8284V10.1035C14.3333 10.0758 14.3309 10.048 14.326 10.0207C14.3256 10.0127 14.3256 10.0047 14.326 9.99672C14.326 9.97752 14.3152 9.95952 14.3092 9.94152L14.2985 9.91753C14.2902 9.90021 14.281 9.88338 14.2709 9.86712C14.2676 9.8603 14.2636 9.85387 14.2589 9.84793C14.2442 9.82622 14.2277 9.80575 14.2097 9.78673L14.2001 9.77953C14.1843 9.76355 14.167 9.74909 14.1485 9.73633L14.1281 9.72314C14.1125 9.71354 14.0957 9.70513 14.0789 9.69673L14.0549 9.68593C14.0357 9.67868 14.016 9.67267 13.9961 9.66794H13.9769C13.9497 9.66206 13.9219 9.65924 13.8941 9.65954H5.80648C5.69539 9.6697 5.59211 9.72098 5.51686 9.80333C5.44162 9.88568 5.39983 9.99317 5.3997 10.1047C5.39912 10.1115 5.39912 10.1183 5.3997 10.1251V15.8464C5.3968 15.9201 5.4202 15.9924 5.4657 16.0504C5.50515 16.1168 5.56116 16.1719 5.62826 16.2101C5.69535 16.2484 5.77123 16.2686 5.84847 16.2688H13.8881C14.0049 16.2676 14.1167 16.2209 14.1998 16.1388C14.2829 16.0566 14.3308 15.9453 14.3333 15.8284ZM5.87488 10.7371V9.86832H6.58165V10.7371H5.87488ZM7.06762 10.7371V9.86832H7.77438V10.7371H7.06762ZM8.26036 10.7371V9.86832H8.96711V10.7371H8.26036ZM9.4531 10.7371V9.86832H10.1599V10.7371H9.4531ZM10.6458 10.7371V9.86832H11.3526V10.7371H10.6458ZM11.8374 10.7371V9.86832H12.5441V10.7371H11.8374ZM13.0301 10.7371V9.86832H13.7369V10.7371H13.0301ZM5.71049 14.9501V10.9723H14.0165V14.9501H5.71049ZM5.87248 16.0528V15.1841H6.57925V16.0528H5.87248ZM7.06521 16.0528V15.1841H7.77198V16.0528H7.06521ZM8.25796 16.0528V15.1841H8.96473V16.0528H8.25796ZM9.45069 16.0528V15.1841H10.1575V16.0528H9.45069ZM10.6434 16.0528V15.1841H11.3502V16.0528H10.6434ZM11.835 16.0528V15.1841H12.5417V16.0528H11.835ZM13.0277 16.0528V15.1841H13.7345V16.0528H13.0277Z" fill="#44B5BB"/>
            <path d="M10.8271 12.6666L9.67872 12.003C9.61928 11.9687 9.55187 11.9506 9.48323 11.9506C9.4146 11.9506 9.34717 11.9686 9.28772 12.0029C9.22826 12.0372 9.17887 12.0865 9.14451 12.1459C9.11014 12.2053 9.09202 12.2727 9.09195 12.3414V13.6685C9.09202 13.7371 9.11014 13.8046 9.14451 13.864C9.17887 13.9234 9.22826 13.9727 9.28772 14.007C9.34717 14.0413 9.4146 14.0593 9.48323 14.0593C9.55187 14.0593 9.61928 14.0412 9.67872 14.0069L10.8271 13.3433C10.8863 13.3089 10.9356 13.2596 10.9698 13.2002C11.004 13.1408 11.022 13.0735 11.022 13.0049C11.022 12.9364 11.004 12.8691 10.9698 12.8097C10.9356 12.7503 10.8863 12.7009 10.8271 12.6666Z" fill="#44B5BB"/>
          </svg>
          <div class="close-wrapper" v-if="!readonly">
            <a @click="drop">
              <fa-icon :icon="['fas', 'trash-can']"></fa-icon>
            </a>
          </div>
        </div>
        <div class="text-truncate"><small><a :href="src">{{ name }}</a></small></div>
      </div>
      <div v-else-if="type === 'audio'">
        <div class="attachment-thumbnail centered-content">
          <svg width="50" height="56" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path opacity="0.3" d="M16.7604 5.9088C16.1043 5.90816 15.4753 5.64727 15.0114 5.18336C14.5475 4.71946 14.2866 4.09046 14.286 3.4344V0H3.2292C2.37276 0 1.55141 0.340217 0.945813 0.945809C0.34022 1.5514 0 2.37276 0 3.2292V22.6956C0.0012718 23.5512 0.34205 24.3713 0.947505 24.9759C1.55296 25.5804 2.37359 25.92 3.2292 25.92H16.5024C17.3582 25.92 18.179 25.5803 18.7845 24.9755C19.39 24.3706 19.7306 23.5502 19.7316 22.6944V5.9088H16.7604Z" fill="#44B5BB"/>
          </g>
          <path d="M19.7316 5.9088H16.7604C16.1043 5.90816 15.4753 5.64727 15.0114 5.18336C14.5475 4.71946 14.2866 4.09046 14.286 3.4344V0L19.7316 5.9088Z" fill="#44B5BB"/>
          <path d="M14.4276 15.8304H13.1136V10.5192C13.1136 10.3413 13.0429 10.1707 12.9171 10.0449C12.7913 9.91909 12.6207 9.84841 12.4428 9.84841H9.08281C8.99472 9.84841 8.90749 9.86576 8.82611 9.89947C8.74472 9.93318 8.67077 9.9826 8.60848 10.0449C8.54619 10.1072 8.49679 10.1811 8.46307 10.2625C8.42936 10.3439 8.41201 10.4311 8.41201 10.5192V15.8304H7.09201V10.0704C7.09201 9.86829 7.13185 9.66814 7.20923 9.48142C7.28662 9.29469 7.40004 9.12504 7.54302 8.98217C7.686 8.83931 7.85574 8.72601 8.04253 8.64877C8.22931 8.57153 8.42949 8.53186 8.63161 8.53201H12.8916C13.2996 8.53201 13.6909 8.69409 13.9794 8.9826C14.2679 9.27111 14.43 9.66241 14.43 10.0704L14.4276 15.8304Z" fill="#44B5BB"/>
          <path d="M12.87 17.388C13.7302 17.388 14.4276 16.6906 14.4276 15.8304C14.4276 14.9702 13.7302 14.2728 12.87 14.2728C12.0098 14.2728 11.3124 14.9702 11.3124 15.8304C11.3124 16.6906 12.0098 17.388 12.87 17.388Z" fill="#44B5BB"/>
          <path d="M6.86162 17.388C7.72186 17.388 8.41922 16.6906 8.41922 15.8304C8.41922 14.9702 7.72186 14.2728 6.86162 14.2728C6.00138 14.2728 5.30402 14.9702 5.30402 15.8304C5.30402 16.6906 6.00138 17.388 6.86162 17.388Z" fill="#44B5BB"/>
          </svg>
          <div class="close-wrapper" v-if="!readonly">
            <a @click="drop">
              <fa-icon :icon="['fas', 'trash-can']"></fa-icon>
            </a>
          </div>
        </div>
        <div class="text-truncate"><small><a :href="src">{{ name }}</a></small></div>
      </div>
      <div v-else class="attachment-thumbnail">
        <div class="attachment-thumbnail centered-content">
          <svg width="50" height="56" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path opacity="0.3" d="M16.7596 5.9097C16.1035 5.90907 15.4746 5.64817 15.0107 5.18429C14.5468 4.72041 14.2859 4.09144 14.2853 3.43541V2.23013e-07H3.23024C2.8061 -0.000157393 2.38607 0.0832334 1.99417 0.245437C1.60226 0.407641 1.24615 0.645474 0.946183 0.945334C0.646212 1.24519 0.408255 1.60121 0.245906 1.99305C0.0835563 2.3849 -2.92858e-08 2.80488 0 3.22903V22.6945C-2.92858e-08 23.1186 0.0835563 23.5386 0.245906 23.9305C0.408255 24.3223 0.646212 24.6783 0.946183 24.9782C1.24615 25.278 1.60226 25.5158 1.99417 25.678C2.38607 25.8402 2.8061 25.9237 3.23024 25.9235H16.5016C17.358 25.9235 18.1793 25.5833 18.7848 24.9777C19.3904 24.3722 19.7306 23.5508 19.7306 22.6945V5.9097H16.7596Z" fill="#44B5BB"/>
          </g>
          <path d="M19.7306 5.9097H16.7595C16.1035 5.90907 15.4745 5.64817 15.0107 5.18429C14.5468 4.72041 14.2859 4.09144 14.2852 3.43541V0L19.7306 5.9097Z" fill="#44B5BB"/>
          <path d="M15.6712 10.0471H3.28304C3.16401 10.0471 3.04987 9.99981 2.96571 9.91565C2.88155 9.83149 2.83426 9.71735 2.83426 9.59832C2.8341 9.53929 2.8456 9.4808 2.86808 9.42621C2.89056 9.37163 2.9236 9.322 2.96528 9.2802C3.00697 9.2384 3.05649 9.20525 3.11101 9.18262C3.16554 9.15999 3.224 9.14835 3.28304 9.14835H15.6712C15.7906 9.14835 15.905 9.19576 15.9894 9.28015C16.0738 9.36453 16.1212 9.47898 16.1212 9.59832C16.1209 9.71746 16.0733 9.83161 15.989 9.91574C15.9046 9.99987 15.7904 10.0471 15.6712 10.0471Z" fill="#44B5BB"/>
          <path d="M15.6712 12.7314H3.28304C3.16401 12.7314 3.04987 12.6841 2.96571 12.5999C2.88155 12.5158 2.83426 12.4016 2.83426 12.2826C2.8341 12.2236 2.8456 12.1651 2.86808 12.1105C2.89056 12.0559 2.9236 12.0063 2.96528 11.9645C3.00697 11.9227 3.05649 11.8895 3.11101 11.8669C3.16554 11.8443 3.224 11.8326 3.28304 11.8326H15.6712C15.7906 11.8326 15.905 11.88 15.9894 11.9644C16.0738 12.0488 16.1212 12.1633 16.1212 12.2826C16.1209 12.4017 16.0733 12.5159 15.989 12.6C15.9046 12.6841 15.7904 12.7314 15.6712 12.7314Z" fill="#44B5BB"/>
          <path d="M15.6712 15.4156H3.28304C3.224 15.4156 3.16554 15.404 3.11101 15.3814C3.05649 15.3587 3.00697 15.3256 2.96528 15.2838C2.9236 15.242 2.89056 15.1923 2.86808 15.1378C2.8456 15.0832 2.8341 15.0247 2.83426 14.9657C2.83426 14.8466 2.88155 14.7325 2.96571 14.6483C3.04987 14.5642 3.16401 14.5169 3.28304 14.5169H15.6712C15.7904 14.5169 15.9046 14.5641 15.989 14.6482C16.0733 14.7324 16.1209 14.8465 16.1212 14.9657C16.1212 15.085 16.0738 15.1994 15.9894 15.2838C15.905 15.3682 15.7906 15.4156 15.6712 15.4156Z" fill="#44B5BB"/>
          <path d="M10.7827 18.0999H3.28304C3.224 18.0999 3.16554 18.0883 3.11101 18.0656C3.05649 18.043 3.00697 18.0098 2.96528 17.968C2.9236 17.9262 2.89056 17.8766 2.86808 17.822C2.8456 17.7675 2.8341 17.709 2.83426 17.6499C2.83426 17.5309 2.88155 17.4167 2.96571 17.3326C3.04987 17.2484 3.16401 17.2012 3.28304 17.2012H10.7827C10.9017 17.2012 11.0158 17.2484 11.1 17.3326C11.1842 17.4167 11.2314 17.5309 11.2314 17.6499C11.2316 17.709 11.2201 17.7675 11.1976 17.822C11.1751 17.8766 11.1421 17.9262 11.1004 17.968C11.0587 18.0098 11.0092 18.043 10.9547 18.0656C10.9002 18.0883 10.8417 18.0999 10.7827 18.0999Z" fill="#44B5BB"/>
          </svg>
          <div class="close-wrapper" v-if="!readonly">
            <a @click="drop">
              <fa-icon :icon="['fas', 'trash-can']"></fa-icon>
            </a>
          </div>
        </div>
        <div class="text-truncate"><small><a :href="src">{{ name }}</a></small></div>
      </div>
    `
  }

  export default {
    data() {
      return {}
    },
    components: {
      FilePreview
    },
    computed: {
      type: function () {
        const contentType = this.modelValue.content_type
        return (contentType || '').split('/')[0]
      },
      src: function() {
        const { signed_id, filename } = this.modelValue
        return rails_service_blob_path(signed_id, { filename })
      }
    },
    props: {
      modelValue: {
        type: Object,
        required: true
      },
      idx: {
        type: Number,
        required: true
      },
      parent_id: {
        type: String,
        default: null
      },
      parent_type: {
        type: String,
        required: true
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      dropAttachment() {
        if (!this.readonly && this.modelValue.signed_id) {
          this.$emit('removeAttachment', this.modelValue.signed_id, this.parent_type)
        }
      }
    },
    emits: ['removeAttachment']
  }
</script>
<style>
.attachment-thumbnail {
  border: 1px solid lightgray;
  height: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 5px;
  position: relative;
  margin-bottom: 24px;
}
.attachment-thumbnail.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.attachment-thumbnail .close-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  border-radius: 15px;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-icon {
  font-size: 2em;
}
.attachment-thumbnail .text-truncate {
  margin-top: -10px;
}
</style>