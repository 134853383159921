<template>
  <div
    ref="toastElement"
    class="toast align-items-center text-white bg-opacity-75"
    :class="`${bsBackgound()}`"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="d-flex">
      <div class="toast-body">
        {{message}}
      </div>
      <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap'

const bsSeverityMap = {
  alert: 'danger',
  danger: 'danger',
  error: 'danger',
  success: 'success',
  info: 'secondary',
  warning: 'warning',
  warn: 'warning'
}

export default {
  mounted() {
    const toastEl = this.$refs.toastElement
    const toast = Toast.getOrCreateInstance(toastEl, { 
      autohide: (this.severity !== 'alert' && this.severity !== 'danger') || !!this.delay,
      delay: this.delay
    })
    toast.show()
  },
  props: {
    message: String,
    severity: String,
    delay: {
      type: Number,
      default: 5000
    }
  },
  methods: {
    bsBackgound() {
      return bsSeverityMap[this.severity] || 'secondary'
    }
  }
}

</script>

<style lang="scss">
  @import "../../stylesheets/application.scss";

  .success {
    background: rgb(68,181,187,.8);
  }

  .danger {
    background: rgb(232,71,81,.8);
  }

  .warning {
    background: rgb(255,192,100,.8);
  }

  .secondary {
    background: rgb(68,127,196,.8);
  }
</style>