<template>
    <div class="my-tooltip me-nocontent" ref="base">
        <slot>
        </slot>
        
        <div v-if="positionArrow === 'bottom'" class="my-tooltip__arrow-bottom"></div>
        
        <div v-if="positionArrow === 'top'" class="my-tooltip__arrow-top"></div>
    </div>
    
</template>

<script>
import { computePosition, flip, shift } from '@floating-ui/dom'

export default { 
    data() {
        return {
            positionArrow: 'bottom',
            contentTooltip: null,
            hasData: false
        }
    },
    props: {
        selectorFocusElement: {
            type: String
        },
    },
    emits: ['shown'],
    methods: {
        showTip(el) {
            const toolTip = this.$refs.base
            toolTip.style.display = 'block'
            computePosition(el, toolTip, {
                placement: 'top',
                middleware: [flip(), shift()],
            }).then(({x, y}) => {
                if ( y >= el.offsetTop ) {
                    this.positionArrow = 'top'
                } else {
                    this.positionArrow = 'bottom'
                }
                Object.assign(toolTip.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                })
                this.$forceUpdate()
            })

            this.$emit('shown')
        },
        hideTip(el) {
            const toolTip = this.$refs.base
            toolTip.style.display = 'none'
        },
    },
    mounted() {
        const items = document.querySelectorAll(this.selectorFocusElement)
        items.forEach(i => {
            i.addEventListener("mouseover", () => this.showTip(i))
            i.addEventListener("mouseout", () => this.hideTip(i))
        })
    }
}

</script>

<style lang="scss">
.my-tooltip {
    display: none;
    background-color: #fff;
    position: absolute;
    width: fit-content;
    border-radius: 5px;
    z-index: 10;
    box-shadow: 0px 12px 16px -4px rgba(23, 114, 119, 0.08), 0px 4px 6px -2px rgba(23, 114, 119, 0.03), 0px -15px 16px -4px rgba(23, 114, 119, 0.03);
    
    &__arrow-bottom {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color:  #fff transparent transparent transparent;
    }

    &__arrow-top {
        position: absolute;
        bottom: 100%; 
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
    }
}

.search__wrap {
   .my-tooltip {
        width: 90%;
        background-color: #fff;
        border: 2px solid #8d8d8d;
        padding: 15px;

        &__arrow-bottom  {
            margin-left: -10px;
            border-width: 10px; 
        }

        &__arrow-top { 
            margin-left: -5px;
            border-width: 5px;
        }
   } 
}

</style>