<template>
  <div class="row justify-content-center">
    <form @submit.prevent="doSearch" novalidate ref="search_form">
      <div class="row mt-4 justify-content-center">
        <div class="col">
          <div class="input-group mb-2">
            <input type="text" class="form-control" id="queryInput" v-model="search.criterias[0].query" placeholder="Поисковый запрос" required :autofocus="true"/>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="state.searching"
            >
              <fa-icon
                icon="fa-solid fa-magnifying-glass"
              >
              </fa-icon>
            </button>
          </div>
          <div>
            <b-form-group>
              <b-form-checkbox inline v-model="search.criterias[0].match_logic.phrase">фраза полностью</b-form-checkbox>
              <b-form-checkbox inline v-model="search.criterias[0].match_logic.case_sensitive">точное совпадение написания слов</b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row mt-3 justify-content-center">
    <div class="col">
      <div class="spinner-border" role="status" v-if="state.searching">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="alert alert-danger" show v-if="state.error" variant="danger">{{ state.error }}</div>
    </div>
  </div>

  <div class="row mt-3 justify-content-center" v-if="nlp_result">
    <div class="col">
      <div class="alert alert-info" variant="info" v-for="cat in nlp_result">
        Класс запроса: 
        <b-badge variant="success" v-if="cat.query?.primary">{{ cat.query?.primary }}</b-badge>
        <b-badge variant="warning" v-else>не определён или запрос общего характера</b-badge>
        <template v-if="cat.query?.secondary">
          <b-badge variant="secondary" class="ms-2" v-for="e in cat.query?.secondary">{{ e }}</b-badge>
        </template>
        <br/>
        Модификатор: 
        <b-badge variant="success" v-if="cat.state_modifier?.primary">{{ cat.state_modifier?.primary }}</b-badge>
        <b-badge variant="warning" v-else>не определён или отсутствует</b-badge>
        <template v-if="cat.query.operands.length > 0">
          <br/>
          Операнд<template v-if="cat.query.operands.length > 1">ы</template>:
          <b-badge variant="info" class="ms-2" v-for="o in cat.query.operands">
            <!--template>{{ o.provided }} => </template-->{{ o.lexeme }}
            <template v-if="o.description">
              <i> &mdash; {{o.description}}</i>
            </template>
          </b-badge>
        </template>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <b-tabs pills>
        <b-tab v-if="fts_result?.terms && fts_result.terms.length > 0" title="Тезаурус" class="mt-3">
          <b-card>
            <template #header>
              <b-badge variant="info">Тезаурус</b-badge>
            </template>
            <b-card-body>
              <b-row>
                <b-col>
                  <Term v-for="t in fts_result?.terms" :term="t" :key="t" class="mb-3"/>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab v-if="fts_result?.icd10?.length > 0" title="МКБ-10" class="mt-3">
          <b-card>
            <template #header>
              <b-badge variant="info">МКБ-10</b-badge>
            </template>
            <b-card-body>
              <OntologyView
                :initial-data="fts_result.icd10"
                id="icd10"
                :allow-search="false"
              />
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab v-if="fts_result?.icd11?.length > 0" title="МКБ-11" class="mt-3">
          <b-card>
            <template #header>
              <b-badge variant="info">МКБ-11</b-badge>
            </template>
            <b-card-body>
              <OntologyView
                :initial-data="fts_result.icd11"
                id="icd11"
                :allow-search="false"
              />
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab v-if="fts_result?.atc?.length > 0" title="АТХ" class="mt-3">
          <b-card>
            <template #header>
              <b-badge variant="info">АТХ</b-badge>
            </template>
            <b-card-body>
              <OntologyView
                :initial-data="fts_result.atc"
                id="atc"
                :allow-search="false"
              />
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab v-if="fts_result?.federal_directory_lab_tests?.length > 0" title="ФСЛИ" class="mt-3">
          <b-card>
            <template #header>
              <b-badge variant="info">АТХ</b-badge>
            </template>
            <b-card-body>
              <OntologyView
                :initial-data="fts_result.federal_directory_lab_tests"
                id="federal_directory_lab_tests"
                :allow-search="false"
              />
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab v-if="fts_result?.nomenclature_medical_services?.length > 0" title="Номенклатура медуслуг" class="mt-3">
          <b-card>
            <template #header>
              <b-badge variant="info">Номенклатура медуслуг</b-badge>
            </template>
            <b-card-body>
              <OntologyView
                :initial-data="fts_result.nomenclature_medical_services"
                id="nomenclature_medical_services"
                :allow-search="false"
              />
            </b-card-body>
          </b-card>
        </b-tab>
        
        <b-tab title="Клинические рекомендации" v-if="fts_result?.cgs && Object.keys(fts_result.cgs).length > 0">
          <template v-if="fts_result.cgs.best">
            <b-card v-for="e in fts_result.cgs.best" :key="e.clinical_guideline.id" class="mt-3">
              <template #header>
                <b-badge variant="info">Клинические рекомендации</b-badge>
              </template>
              <b-card-title>"
                <b-link :href="e.clinical_guideline.hfurl + '?context=current_search'" target="_blank">{{ e.clinical_guideline.title }}"</b-link>
              </b-card-title>
              <b-card-subtitle>
                {{ e.clinical_guideline.year }}, 
                <span v-for="a in e.clinical_guideline.ages" class="ms-1" variant="secondary">{{ a }}</span>
              </b-card-subtitle>
              <b-card-body>
                <h6>Подходящие под запрос разделы</h6>
                <template v-for="p in e.index">
                  <div>
                    <span class="me-3" v-for="x in (p.data.level - 1)"></span>
                    <b-link v-html="p.data.content" target="_blank" :variant="p.has_match ? 'primary' : 'secondary'" :href="p.url"></b-link>
                  </div>
                  <div v-for="c in (p.has_child_match ?? [])" class="search__wrap">
                    <span class="me-3" v-for="x in p.data.level"></span>
                    <a 
                      class="search__reference" 
                      target="_blank" 
                      :href="`${e.clinical_guideline.hfurl}?context=current_search#${c}`" 
                      :data-id="c" 
                    >
                      Упоминание в тексте раздела
                    </a>
                    <tooltip :selector-focus-element="getSelector(c, 'best')" @shown="triggerContentBlockPreview(c, 'best')">
                      <ContentBlockPreview 
                        :content-chunk-id="e.clinical_guideline.content_chunk_id" 
                        :part-id="c"
                        search-context="current_search"
                        :ref="c"
                      />
                    </tooltip>
                  </div>
                </template>
              </b-card-body>
            </b-card>
          </template>
          <div class="alert alert-danger" v-else>
            Релевантных клинических рекомендаций не найдено
          </div>

          <div class="row justify-content-center mt-3" v-if="fts_result.cgs.worst">
            <div class="col-auto mb-3" v-if="!showLessRelevant">
              <b-button variant="secondary" @click="toggleShowLessRelevant">Показать менее релевантные результаты</b-button>
            </div>
            <div class="col-auto mb-3" v-else>
              <b-button variant="secondary" @click="toggleShowLessRelevant">Не показывать менее релевантные результаты</b-button>
            </div>
          </div>

          <b-card v-for="e in fts_result.cgs.worst" :ref="e.clinical_guideline.id" v-if="showLessRelevant" class="mt-3">
            <template #header>
              <b-badge variant="info">Клинические рекомендации</b-badge>
            </template>
            <b-card-title>"
              <b-link :href="e.clinical_guideline.hfurl + '?context=current_search'" target="_blank">{{ e.clinical_guideline.title }}"</b-link>
            </b-card-title>
            <b-card-subtitle>
              {{ e.clinical_guideline.year }}, 
              <span v-for="a in e.clinical_guideline.ages" class="ms-1" variant="secondary">{{ a }}</span>
            </b-card-subtitle>
            <b-card-body>
              <h6>Подходящие под запрос разделы</h6>
              <template v-for="p in e.index">
                <div>
                  <span class="me-3" v-for="x in (p.data.level - 1)"></span>
                  <b-link v-html="p.data.content" target="_blank" variant="secondary" :href="p.url"></b-link>
                </div>
                <div v-for="c in (p.has_child_match ?? [])" class="search__wrap">
                  <span class="me-3" v-for="x in p.data.level"></span>
                  <b-link target="_blank" :href="`${e.clinical_guideline.hfurl}?context=current_search#${c}`" class="search__reference" :data-worst-id="c" >
                    Упоминание в тексте раздела
                  </b-link>
                  <tooltip :selector-focus-element="getSelector(c, 'worst')" @shown="triggerContentBlockPreview(c,'worst')">
                    <ContentBlockPreview 
                      :content-chunk-id="e.clinical_guideline.content_chunk_id" 
                      :part-id="c"
                      search-context="current_search"
                      :ref="'worst_' + c"
                    />
                  </tooltip>
                </div>
              </template>
            </b-card-body>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>


<script>
  import axios from 'axios'
  import tooltip from '../../components/tooltip.vue'
  import ContentBlockPreview from '../../components/content_block_preview.vue'
  import OntologyView from '../ontologies/ontology.vue'
  import Term from '../terms/term.vue'
  
  export default {
    data() {
      return {
        search: {
          criterias: [
            {query: '', match_logic: {case_sensitive: false, phrase: false}},
          ]
        },
        fts_result: null,
        nlp_result: null,
        showLessRelevant: false,
        state: {
          error: null,
          searching: false,
        },
        paramSearch: 'current_search',
      }
    },
    components: {
      tooltip,
      ContentBlockPreview,
      OntologyView,
      Term
    },
    methods: {
      getSelector(id, type) {
        if(type === 'best') {
          return `.search__reference[data-id="${id}"]`
        } else {
          return `.search__reference[data-${type}-id="${id}"]`
        }
      },
      doSearch(event) {
        if (event) {
          event.target.classList.remove('was-validated')
          if (! event.target.checkValidity()) {
            event.target.classList.add('was-validated')
            event.stopPropagation()
            return
          }
        }
        
        this.fts_result = null
        this.nlp_result = null
        this.state.error = null
        this.state.searching = true
        this.showLessRelevant = false
        
        const csrfToken = document.getElementsByName("csrf-token")[0].content
        
        axios.post('/searches', {
          search: this.search
        }, { 
          responseType: 'json',
          headers: {
            "X-CSRF-Token": csrfToken
          }
        }).then(resp => {
          this.fts_result = resp.data.fts
          this.nlp_result = resp.data.nlp
        }).catch(error => {
          if (error.response.status === 429) {
            this.state.error = "Too many requests"
          } else if (error.response.status === 404) {
            this.state.error = "Ничего не найдено"
          } else {
            this.state.error = "Не удалось выполнить запрос: " + error.response.status
          }
        }).then(() => {
          this.state.searching = false
        })
      },
      toggleShowLessRelevant() {
        this.showLessRelevant = !this.showLessRelevant
        if (this.showLessRelevant) {
          this.$nextTick(() => {
            this.$refs[this.result.worst[0].clinical_guideline.id][0].scrollIntoView()
          })
        }
      },
      icd11BadgeVeriant(e) {
        if (e.rank) {
          if (e.match_areas.includes('title')) {
            return 'info'
          } else {
            return 'primary'
          }
        } else {
          return 'secondary'
        }
      },
      icd11Id(e) {
        return 'icd11-' + (e.code ?? e.code_range ?? e.id)
      },
      triggerContentBlockPreview(partId, type) {
        if(type === 'best') {
          this.$refs[partId][0].getContent()
        } else {
          this.$refs[type + '_' + partId][0].getContent()
        }
        
      }
    }
  }
</script>

<style>
  .tab-content > .tab-pane.active {
    background: white;
  }

  .icd11-definition-link {
    text-decoration: underline;
    text-decoration-color: blue;
    text-decoration-style: dashed;
  }
</style>