<template>
  <div>
    <div class="mb-2 entry">
      <b-badge :variant="badgeVariant" v-if="publicCode" class="me-2">{{ publicCode }}</b-badge>
      <template v-if="entry.children || entry.has_children">
        <span>{{ entry.title.ru ?? entry.title.en ?? entry.title }}</span>
        <a href="#" role="button" @click="fetchSubtree" class="ms-2 link-dark entry__btn">
           <img v-if="!this.entryData" :src="imageUrl('plus.svg')"/>
           <img v-if="this.entryData" :src="imageUrl('minus.svg')"/>
        </a>
      </template>
      <template v-else>
        {{ entry.title.ru ?? entry.title.en ?? entry.title }}
      </template>
      {{  }}
      <fa-icon 
        v-if="Object.keys(entry.definition ?? {}).length > 0"
        :icon="['fas', 'circle-info']" 
        size="xl" 
        color="#44B5BB" 
        @click="openPopup(entry)"
      ></fa-icon>
    </div>
    
    <div class="spinner-border" role="status" v-if="loading">
      <span class="visually-hidden">Loading...</span>
    </div>
    
    <template v-if="entryData">
      <div class="mb-2 ms-3" v-for="e in entryData.children">
        <OntologyEntry 
          :ontology-id="ontologyId" 
          :entry="e" 
          :key="e" 
          @openPopup="openPopupChildren(e)"
        >
          <template #entry="{ entry }">
            <slot name="entry" :entry="entry">
            </slot>
          </template>
        </OntologyEntry>
      </div>
    </template>
    <div class="mb-2 ms-3" v-if="entryData?.has_more_children">
      <small @click="forceFetchSubtree" class="link-async text-muted">показать элементы категории, не попадающие под критерии фильтра</small>
    </div>
  </div>

  <Popup :is-popup-open="isPopupOpen" @closePopup="closePopup">
    <h4><b-badge variant="primary">{{ publicCode }}</b-badge></h4>
    <div class="mt-2">
      <b-card no-body>
        <b-tabs card>
          <b-tab v-for="(title, locale) in entry.title" :title="locale">
            <h5>{{ title }}</h5>
            <p>{{ entry.definition[locale] }}</p>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </Popup>
</template>

<script>
  import { ontology_entry_path } from '../../../javascript/routes'
  import axios from 'axios'
  import Popup from '../../components/popup.vue'
  import { getImageUrl } from '../../javascripts/utils'
  
  export default {
    props: {
      ontologyId: {
        type: String,
        required: true,
      },
      entry: {
        type: Object,
        required: true,
      },
    },
    name: 'OntologyEntry',
    data() {
      return {
        entryData: (this.entry?.children || this.entry?.has_more_children) ? JSON.parse(JSON.stringify(this.entry)) : null,
        loading: false,
        ranked: this.entry?.rank,
        isPopupOpen: false,
        currentEntry: {}
      }
    },
    components: {
      Popup
    },
    emits: ['openPopup', 'openPopupChildren'], 
    methods: {
      fetchSubtree(event) {
        event.preventDefault()
        event.stopPropagation()
        if (this.entryData) {
          this.entryData = null
          return
        }
        this.forceFetchSubtree()
      },
      forceFetchSubtree() {
        this.loading = true
        axios
          .get(ontology_entry_path(this.ontologyId, this.code, { format: 'json' }))
          .then((resp) => {
            this.entryData = resp.data.entry
            this.entryData?.children?.forEach(c => {
              const knownChild = this.entry?.children?.find(cc => cc.id === c.id)
              Object.assign(c, knownChild)
              if (knownChild) {
                Object.assign(c, knownChild)
              }
            })
          }).catch((error) => {
            console.error(error)
          }).finally(() => {
            this.loading = false
          })
      },
      openPopup(e) {
        //this.$emit("openPopup", e)
        this.isPopupOpen = true
      },
      openPopupChildren(e) {
        this.isPopupOpen = true
        this.currentEntry = e
      },
      closePopup() {
        this.isPopupOpen = false
      },
      imageUrl(path) {
        return getImageUrl(path)
      }
    },
    computed: {
      publicCode() {
        return this.entry.display_code ?? this.entry.code ?? this.entry.code_range
      },
      code() {
        return this.publicCode ?? this.pseudoCode
      },
      pseudoCode() {
        return `_${this.entry.id}`
      },
      badgeVariant() {
        return this.ranked ? 'info' : ''
      },
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside)
    }
  }
</script>

<style>
  .link-async {
    text-decoration-style: dashed;
    text-decoration: underline;
    text-decoration-color: blue;
    cursor: pointer;
  }

  .badge {
    background-color: #44B5BB;
    margin-left: 10px;
  }

  .entry {
    position: relative;
    padding-left: 30px;
  }

  .entry__btn {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
</style>